export interface ShortUser {
  id?: number;
  email: string;
  name?: string;
}
export interface User extends ShortUser {
  first_name?: string;
  last_name?: string;
  username?: string;
  last_login?: string;
  date_joined?: string;
  is_active?: boolean;
  profile?: Profile;
  password: string;
  confirm_password: string;
  // groups?: Groups[];
}

export interface UserRoleSerializer {
  id?: number;
  name?: string;
}
export interface Profile {
  id?: number;
  role: UserRoleSerializer;
  state: ProfileState;
}

export enum ProfileState {
  BLOCKED = 'BLOCKED',
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
}

// export enum Groups {
//   ADMIN = 1,
//   ARTICLES = 2,
//   TARIM = 3,
// }

export interface InviteUser {
  email: string;
  user: ShortUser;
  user_id?: number;
  first_name?: string;
  last_name?: string;
  role: UserRoleSerializer;
  role_id?: number;
  redirect: string;
}
