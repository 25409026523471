import React, { FC } from 'react';
import { ResetPasswordForm, ForgotPassowrdForm } from 'components/RecoveryPassowrd';
import { useLocation } from 'react-router-dom';

const Recovery: FC = () => {
  const query = useQuery();

  const token = query.get('token');

  if (token) {
    return <ResetPasswordForm token={token} />;
  }

  return <ForgotPassowrdForm />;
};

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export default Recovery;
