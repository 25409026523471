import React, { FC } from 'react';
import { UsersTable } from 'components/Users';
import { Roles } from 'lib/api/users';
import withRouteGuard from 'lib/router/withRouteGuard';

const Users: FC = () => {
  return <UsersTable />;
};

export default withRouteGuard([Roles.ADMINISTRATOR])(Users) as FC;
