import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChromePicker } from 'react-color';
import {
  Collapse,
  Button,
  Form,
  Affix,
  Row,
  Col,
  Input,
  Radio,
  Tabs,
  Modal,
  Tag as Label,
  notification,
  DatePicker,
  message,
  Tooltip,
  Select,
  Checkbox,
} from 'antd';
import { ArrowRightOutlined, DeleteOutlined, MacCommandOutlined } from '@ant-design/icons';
import moment from 'moment';
import api, { Article, ArticleState, LayoutVariant } from 'lib/api/pages';
import { ArticlesTree } from 'components/Articles';
import { languages, Languages, isLanguage } from 'lib/utils';
import tagsApi, { ArticleTags, ArticleTagsCode, defaultTags, Tag } from 'lib/api/tags';
import UploadImage from 'components/Backgrounds/UploadImage';
import preview from 'resources/preview';
import { isIOS } from 'utils';

import Builder from './Builder';
import { IconSelect } from './Builder/components/Custom';
import './style.css';

const { Panel } = Collapse;
const { TabPane } = Tabs;

//const fields = ['title', 'content', 'preview'];

const customPanelStyle = {
  border: 0,
  borderRadius: 4,
  marginBottom: 5,
  overflow: 'hidden',
};

// const hasPreview = [ArticleTags.INFORMATION];

const formItemStyle = {
  marginBottom: 0,
  lineHeight: 'initial',
};

interface ArticleFormProps {
  /**
   * Article to edit, this is used only on first render.
   */
  initialArticle?: Article;

  /**
   * Called after succesful save.
   */
  onSaved: (data?: Article) => void;
  defaultTag?: ArticleTagsCode;
  tagList?: Tag[];
  type?: string;
  noTags?: boolean;
  AdditionalModules?: React.FunctionComponent<any>;
  dynamicSlug?: boolean;
}

const ArticleForm: React.FC<ArticleFormProps> = ({
  onSaved,
  initialArticle,
  defaultTag = ArticleTagsCode.PAGE,
  tagList = defaultTags,
  noTags = false,
  type = 'pages',
  AdditionalModules,
}) => {
  const { search } = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [pageType, setPageType] = React.useState<ArticleTagsCode | null>(null);
  const [isBanner, setIsBanner] = React.useState(false);
  const [showTitle, setShowTitle] = React.useState(true);
  const [background, setBackground] = React.useState<string>('');
  const [activeTabKey, setActiveTabKey] = React.useState<Languages>(
    (localStorage.getItem('key') || 'ro') as Languages,
  );

  React.useEffect(() => {
    if (initialArticle?.tags?.some((tag) => tag.code === ArticleTagsCode.BUSINESS)) {
      setPageType(ArticleTagsCode.BUSINESS);
    }

    if (initialArticle?.tags?.some((tag) => tag.code === ArticleTagsCode.DISCLOSURE_INFORMATION)) {
      setPageType(ArticleTagsCode.DISCLOSURE_INFORMATION);
    }

    form.setFieldsValue({
      ...initialArticle,
      publish_date: initialArticle?.publish_date ? moment(initialArticle?.publish_date) : undefined,
      tags: initialArticle?.tags?.length
        ? Array.isArray(initialArticle?.tags) &&
          (initialArticle?.tags as Tag[])
            .filter((tag) =>
              typeof tag === 'number'
                ? ![15, 16].includes(tag)
                : ![ArticleTagsCode.BUSINESS, ArticleTagsCode.DISCLOSURE_INFORMATION].includes(
                    tag.code,
                  ),
            )
            .map((item) => (typeof item === 'number' ? item : item?.id))[0]
        : tagList.find((i) => i.code === defaultTag)?.id,
    });
    if (typeof initialArticle?.background === 'string') {
      setBackground(initialArticle.background);
    }

    if (initialArticle?.data?.layout) {
      setIsBanner(
        [LayoutVariant.BANNER, LayoutVariant.BANNER_INDENT].includes(initialArticle?.data?.layout),
      );
    }
    if (initialArticle?.data?.showTitle !== undefined) {
      setShowTitle(initialArticle?.data?.showTitle);
    }
  }, [initialArticle, tagList]);

  const onChange = (key: string): void => {
    if (isLanguage(key)) {
      setActiveTabKey(key);
      localStorage.setItem('key', key);
    }
  };

  React.useEffect(() => {
    const lang = new URLSearchParams(search).get('lang');

    if (lang) {
      setActiveTabKey(lang as Languages);
    }
  }, []);

  React.useEffect(() => {
    const handleKeyDown = (e) => {
      const charCode = String.fromCharCode(e.which).toLowerCase();

      if (e.ctrlKey || e.metaKey) {
        const code = languages.find((_, i) => (i + 1).toString() === charCode);

        if (code) {
          e.preventDefault();
          onChange(code);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const verifyTreeInclude = (_rule: any, value: any, callback: any): void => {
  //   if (Array.isArray(value) && value.includes(`${initialArticle?.id}`)) {
  //     callback("Parent can't be self-referenced");

  //     form.setFieldsValue({
  //       parents: value.filter((id) => id !== `${initialArticle?.id}`),
  //     });

  //     Modal.error({
  //       title: 'Eroare.',
  //       content: 'Pagina aleasă nu este compatibilă pentru a fi referință.',
  //     });
  //   } else {
  //     callback();
  //   }
  // };

  const handleSubmit = async (values: any): Promise<void> => {
    setLoading(true);
    try {
      const i18n = { ...values.i18n };
      const data: Article = { ...values };
      const tags = [
        ...(data.tags ? [data.tags] : [tagList.find((i) => i.code === defaultTag)?.id]),
      ].filter(
        (id) => ![ArticleTags.BUSINESS, ArticleTags.DISCLOSURE_INFORMATION].includes(id as number),
      ) as ArticleTags[];

      Object.keys(i18n).forEach((lang) => {
        if (initialArticle?.i18n[lang].slug === i18n[lang].slug) {
          delete i18n[lang].slug;
        }
      });

      if (pageType === ArticleTagsCode.BUSINESS) {
        tags.push(ArticleTags.BUSINESS);
      }

      if (pageType === ArticleTagsCode.DISCLOSURE_INFORMATION) {
        tags.push(ArticleTags.DISCLOSURE_INFORMATION);
      }

      api
        .upsert({
          ...(initialArticle && { id: initialArticle.id }),
          ...data,
          i18n,
          tags,
          background: background?.length ? background : null,
        })
        .then(onSaved);
    } catch (e) {
      const { message: err = 'Error. The article could not be saved!' } = e;

      message.error(err);
    }
    setLoading(false);
    // languages.forEach(({ code }) => {
    //   let valid = true;

    //   fields.forEach((field) => {
    //     if (!values['i18n'][code][field] || !values['i18n'][code][field].length) {
    //       valid = false;
    //     }
    //   });

    //   if (!valid) {
    //     setActiveTabKey(code);
    //   }
    // });
  };

  const resetFilters = React.useCallback(() => {
    Modal.confirm({
      title: 'This action will reset the name to all tags.',
      content:
        'This action should only be executed when translations for tags are missing in the CMS application.',
      onOk: async () => {
        try {
          await tagsApi.resetAll();

          notification.success({ message: 'Operation was executed.' });
        } catch (e) {
          notification.error({ message: 'Operation failed.' });
        }
      },
      okText: 'Ok',
      cancelText: 'Cancel',
    });
  }, []);

  const onValuesChange = React.useCallback((values) => {
    if (values.background) {
      setBackground(values.background[0]);
    }
    if (values?.data?.layout) {
      setIsBanner(
        [LayoutVariant.BANNER, LayoutVariant.BANNER_INDENT].includes(values?.data?.layout),
      );
    }
    if (values?.data?.showTitle !== undefined) {
      setShowTitle(values?.data?.showTitle);
    }
  }, []);

  // const withPreview = React.useMemo(() => hasPreview.some((type) => tags.includes(type)), [tags]);

  return (
    <Form form={form} onValuesChange={onValuesChange} onFinish={handleSubmit}>
      <Row justify="space-between">
        <Col span={24} xl={18}>
          <Tabs
            className="custom-tabs static-pages"
            activeKey={activeTabKey}
            onChange={onChange}
            animated={false}
          >
            <TabPane tab={isIOS() ? <MacCommandOutlined /> : 'CTRL'} disabled />
            {languages.map((code, i) => {
              const source = initialArticle?.i18n[code];

              const { title, preview } = source || {};

              return (
                <TabPane
                  forceRender
                  tab={
                    <>
                      {code.toUpperCase()}
                      <Label style={{ marginLeft: 10 }}>{i + 1}</Label>
                    </>
                  }
                  key={code}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        name={['i18n', code, 'title']}
                        label="Titlu"
                        initialValue={title}
                        style={formItemStyle}
                      >
                        <Input placeholder={`Enter article title [${code}]`} />
                      </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                      <Form.Item
                        name={['i18n', code, 'slug']}
                        initialValue={slug}
                        style={formItemStyle}
                      >
                        <Input
                          size="small"
                          addonAfter="page slug"
                          placeholder={`Insert page slug [${code}]`}
                        />
                      </Form.Item>
                    </Col> */}

                    <Col span={24}>
                      <Form.Item
                        name={['i18n', code, 'preview']}
                        label="Preview"
                        initialValue={preview}
                        style={formItemStyle}
                      >
                        <Input.TextArea placeholder={`Enter page preview [${code}]`} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name={['data']}
                        label=""
                        initialValue={initialArticle?.data.components}
                        style={formItemStyle}
                      >
                        {/* <Form.Item name={['data', 'justifyContent']} initialValue="start">
                          <Select>
                            <Option value="flex-start">Start</Option>
                            <Option value="center-center">Center</Option>
                            <Option value="flex-end">End</Option>
                            <Option value="space-between">Between</Option>
                          </Select>
                        </Form.Item> */}

                        <Builder activeTabKey={activeTabKey} loading={loading} />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              );
            })}
          </Tabs>
          <Select
            className="business-switch"
            placeholder="Type"
            value={pageType}
            style={{ width: 170 }}
            onChange={setPageType}
          >
            <Select.Option value={null}>Persoane fizice</Select.Option>
            <Select.Option value={ArticleTagsCode.BUSINESS}>Business</Select.Option>
            <Select.Option value={ArticleTagsCode.DISCLOSURE_INFORMATION}>
              Dezvăluirea informaţiei
            </Select.Option>
          </Select>
          {/* <Switch
            className="business-switch"
            checkedChildren="Business"
            unCheckedChildren="Persoane fizice"
            checked={isBusiness}
            onChange={setIsBusiness}
          /> */}
          {/* <Form.Item name={`gallery`} initialValue={initialArticle?.gallery} style={formItemStyle}>
            <GalleryWall />
          </Form.Item> */}
        </Col>
        <Col span={24} xl={5}>
          <Affix offsetTop={80}>
            <>
              <Collapse
                className="article-menu"
                bordered={false}
                defaultActiveKey={['2', '3', '4', '5']}
                expandIcon={({ isActive }) => <ArrowRightOutlined rotate={isActive ? 90 : 0} />}
              >
                <Panel forceRender header="Pagini părinte" key="1" style={customPanelStyle}>
                  <Form.Item label="" name="parents" initialValue={initialArticle?.parents}>
                    <ArticlesTree activeTabKey={activeTabKey} />
                  </Form.Item>
                </Panel>

                {!noTags && (
                  <Panel forceRender header="Tag" key="2" style={customPanelStyle}>
                    <Form.Item label="" name="tags">
                      <Radio.Group className="checkbox-group-tags">
                        {tagList.map((tag) => (
                          <Radio key={tag.id} className={tag.pid && 'pid'} value={tag.id}>
                            {tag.i18n.ro}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Button size="small" onClick={resetFilters} style={{ display: 'none' }}>
                      Resetează tag-urile
                    </Button>
                  </Panel>
                )}

                <Panel
                  forceRender
                  header={
                    <>
                      Imagine de fundal<sub>(meniu)</sub>
                    </>
                  }
                  key="3"
                  style={customPanelStyle}
                >
                  {!!background?.length && (
                    <img
                      src={`${background}`}
                      alt="background"
                      className="background-preview"
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Form.Item
                        label=""
                        name="background"
                        initialValue={initialArticle?.background}
                      >
                        <UploadImage />
                      </Form.Item>
                    </Col>
                    {!!background?.length && (
                      <Col>
                        <Button
                          type="primary"
                          icon={<DeleteOutlined />}
                          onClick={() => setBackground('')}
                        />
                      </Col>
                    )}
                  </Row>
                </Panel>
                {AdditionalModules && (
                  <Panel
                    forceRender
                    header="Alege oficiu postal"
                    key="AdditionalModules"
                    style={customPanelStyle}
                  >
                    {<AdditionalModules form={form} initialArticle={initialArticle} />}
                  </Panel>
                )}

                <Panel forceRender header="Status" key="4" style={customPanelStyle}>
                  <Form.Item
                    label=""
                    name="state"
                    initialValue={initialArticle?.state || ArticleState.DRAFT}
                  >
                    <Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
                      <Radio value={ArticleState.PUBLIC}>Publicat</Radio>
                      <Radio value={ArticleState.DRAFT}>Schiță</Radio>
                      <Radio value={ArticleState.INACTIVE}>Inactiv</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    labelCol={{ span: 24 }}
                    name="publish_date"
                    label={
                      <>
                        Data publicării<sub>(opțional)</sub>
                      </>
                    }
                    className="date-picker-form-item"
                  >
                    <DatePicker showTime />
                  </Form.Item>
                </Panel>

                <Panel forceRender header="Setări layout" key="5" style={customPanelStyle}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Bara de sus"
                    name={['data', 'topbar']}
                    initialValue={initialArticle?.data?.topbar || 'black'}
                  >
                    <Radio.Group style={{ display: 'flex' }}>
                      <Radio value="white">Alb</Radio>
                      <Radio value="black">Întuneric</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Titlul paginii"
                    name={['data', 'showTitle']}
                    valuePropName="checked"
                    initialValue={
                      initialArticle?.data?.showTitle !== undefined
                        ? initialArticle?.data?.showTitle
                        : true
                    }
                  >
                    <Checkbox>{showTitle ? 'Arată' : 'Ascunde'}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Iconiţa"
                    name="icon"
                    initialValue={initialArticle?.icon}
                  >
                    <IconSelect />
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Tip layout"
                    name={['data', 'layout']}
                    initialValue={initialArticle?.data?.layout || LayoutVariant.DEFAULT}
                  >
                    <Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
                      {Object.keys(LayoutVariant).map((key) => (
                        <Radio key={key} value={LayoutVariant[key as keyof typeof LayoutVariant]}>
                          <Tooltip title={<img src={preview[key]} />}>
                            {key[0]}
                            {key.toLowerCase().slice(1, key.length).replace('_', ' ')}
                          </Tooltip>
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  {isBanner && (
                    <Form.Item
                      name={['data', 'color']}
                      valuePropName="color"
                      initialValue={initialArticle?.data?.color || '#FFFADA'}
                    >
                      <ChromePicker />
                    </Form.Item>
                  )}
                </Panel>
              </Collapse>

              <Row justify="space-between">
                <Link to={`/${type}`}>
                  <Button>Anulează</Button>
                </Link>

                <Button type="primary" htmlType="submit" loading={loading}>
                  Salvează
                </Button>
              </Row>
            </>
          </Affix>
        </Col>
      </Row>
    </Form>
  );
};

export default ArticleForm;
