import api, { ArrayResult } from '.';
import { stringifyUrl } from 'utils';
import { Log } from 'types';

export interface Ii18n {
  id?: number;
  text_id: string;
  parent: number;
  i18n: any;
}

export default {
  /**
   * Get all dictionaries
   */
  getAll: async () => {
    const { data } = await api.request.get(stringifyUrl(`dictionary/?page_size=100`, {}, {}), {
      headers: {
        'Accept-Language': 'no',
      },
    });

    return data;
  },
  /**
   * Update an dictionary object
   */
  update: async (id: string, i18n: any): Promise<Ii18n> => {
    const { data } = await api.request.patch(
      `/v1/dictionary/${id}/`,
      {
        i18n,
      },
      {
        headers: {
          'Accept-Language': 'no',
        },
      },
    );

    return data as Ii18n;
  },
  /**
   * Get dictionary by id
   */
  getById: async (id: string): Promise<Ii18n> => {
    const { data } = await api.request.get(stringifyUrl(`dictionary/${id}/`, {}, {}), {
      headers: {
        'Accept-Language': 'no',
      },
    });

    return data as Ii18n;
  },
  getLogs: async (params: any = {}): Promise<ArrayResult<Log>> => {
    const query = new URLSearchParams({ page_size: 10, ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(`/v2/dictionary/logs/?${query.toString()}`);

    return data;
  },
  getLog: async (id: number): Promise<Log> => {
    const { data } = await api.request.get(`/v2/dictionary/logs/${id}/`);

    return data;
  },
};
