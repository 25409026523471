import L from 'leaflet';
import iconUrl from 'resources/images/pin.png';

const iconPin = new L.Icon({
  iconUrl,
  iconSize: new L.Point(30, 30),
  className: 'leaflet-div-icon',
});

export { iconPin };
