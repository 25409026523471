import React from 'react';
import { Row, Col } from 'antd';
import {
  FormOutlined,
  EditOutlined,
  SelectOutlined,
  SwitcherOutlined,
  NumberOutlined,
  PhoneOutlined,
  MailOutlined,
  CalendarOutlined,
} from '@ant-design/icons';

import titles from './titles.json';

export const Fields = {
  text: (
    <Row gutter={[8, 8]}>
      <Col>
        <EditOutlined />
      </Col>
      <Col>{titles.text}</Col>
    </Row>
  ),
  email: (
    <Row gutter={[8, 8]}>
      <Col>
        <MailOutlined />
      </Col>
      <Col>{titles.email}</Col>
    </Row>
  ),
  tel: (
    <Row gutter={[8, 8]}>
      <Col>
        <PhoneOutlined />
      </Col>
      <Col>{titles.tel}</Col>
    </Row>
  ),
  number: (
    <Row gutter={[8, 8]}>
      <Col>
        <NumberOutlined />
      </Col>
      <Col>{titles.number}</Col>
    </Row>
  ),
  checkbox: (
    <Row gutter={[8, 8]}>
      <Col>
        <SwitcherOutlined />
      </Col>
      <Col>{titles.checkbox}</Col>
    </Row>
  ),
  select: (
    <Row gutter={[8, 8]}>
      <Col>
        <SelectOutlined />
      </Col>
      <Col>{titles.select}</Col>
    </Row>
  ),
  date: (
    <Row gutter={[8, 8]}>
      <Col>
        <CalendarOutlined />
      </Col>
      <Col>{titles.date}</Col>
    </Row>
  ),
};

export default [
  {
    type: 'fields',
    treeTitle: (
      <Row gutter={[8, 8]}>
        <Col>
          <FormOutlined />
        </Col>
        <Col>{titles.fields}</Col>
      </Row>
    ),
    children: [
      {
        type: 'input',
        treeTitle: 'Input',
        children: [
          { type: 'text', treeTitle: Fields.text, isMultiple: true },
          { type: 'email', treeTitle: Fields.email, isMultiple: true },
          { type: 'tel', treeTitle: Fields.tel, isMultiple: true },
          { type: 'number', treeTitle: Fields.number, isMultiple: true },
          { type: 'date', treeTitle: Fields.date, isMultiple: true },
        ],
      },
      { type: 'checkbox', treeTitle: Fields.checkbox, isMultiple: true },
      { type: 'select', treeTitle: Fields.select, isMultiple: true },
    ],
  },
];
