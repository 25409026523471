import React from 'react';
import { Form, Checkbox } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';

export default ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <Form.Item
      name={[item.id, lang, 'is_contact']}
      valuePropName="checked"
      label="Cu informatie de contact"
      style={formItemStyle}
    >
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();

          setFieldsValue({
            [item.id]: {
              [lang]: {
                is_contact: !item.is_contact,
              },
            },
          });
        }}
      />
    </Form.Item>
  );
};
