import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Card, Modal, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from 'lib/api';
import { Properties } from 'types';
import { ValidationError } from 'errors';

/**
 * This is Authentication component that is mounted
 * on `/authentication/login`. It consists on a form with
 * two fields that handles login action.
 *
 * After successfully login, user will be automatically redirected
 * to users page aka the first page in dashboard.
 *
 */
const Authentication: FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const handleSubmit = async (values: Properties): Promise<void> => {
    const { username, password } = values;
    const [tokens, error] = await api.auth.login(username, password);

    if (tokens) {
      api.setSessionTokens(tokens);
    } else if (error) {
      if (error instanceof TypeError) {
        Modal.error({
          title: 'Sign in failed',
        });
      } else if (error instanceof ValidationError) {
        form.setFields(error.fields);
      } else {
        Modal.error({
          title: error.detail,
        });
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <Card title="Energbank - Panou de administrare">
        <Form onFinish={handleSubmit} form={form} style={{ width: 300, height: 'fit-content' }}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Introduceți numele de utilizator!' }]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Introduceți parola' }]}>
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Row justify="space-between">
            <Col>
              <Button type="primary" htmlType="submit" className="login-form__button">
                Autentificare
              </Button>
            </Col>

            <Col>
              <Button
                type="link"
                onClick={() => {
                  history.push('/recovery-password');
                }}
              >
                Recuperează parola
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default Authentication;
