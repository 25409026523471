import React from 'react';
import { Form, Select } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';

export default ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, 'from']}
        initialValue={!item?.until ? 'small-desktop' : undefined}
        label="De la"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="mobile">Mobil</Select.Option>
          <Select.Option value="small-tablet">Comprimat mic</Select.Option>
          <Select.Option value="tablet">Comprimat</Select.Option>
          <Select.Option value="small-desktop">Desktop mic</Select.Option>
          <Select.Option value="desktop">Desktop</Select.Option>
          <Select.Option value="wide">Larg</Select.Option>
          <Select.Option value="extra-wide">Extra lat</Select.Option>
          <Select.Option value="extra-extra-wide">Expansiv</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={[item.id, lang, 'until']} label="Până la" style={formItemStyle}>
        <Select>
          <Select.Option value="mobile">Mobil</Select.Option>
          <Select.Option value="small-tablet">Comprimat mic</Select.Option>
          <Select.Option value="tablet">Comprimat</Select.Option>
          <Select.Option value="small-desktop">Desktop mic</Select.Option>
          <Select.Option value="desktop">Desktop</Select.Option>
          <Select.Option value="wide">Larg</Select.Option>
          <Select.Option value="extra-wide">Extra lat</Select.Option>
          <Select.Option value="extra-extra-wide">Expansiv</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};
