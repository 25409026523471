import api from '.';

export interface SettingsFrontStrings {
  id?: number;
  value: string;
  keyword: string;
}

export default {
  getSettings: async (params?: any) => {
    const { data } = await api.request.get('/v1/json-string/', { params });
    return data;
  },

  addSettings: async (params?: any) => {
    const { data } = await api.request.post('/v1/json-string/', params);
    return data;
  },

  getSettingById: async (params: any, id: string) => {
    const { data } = await api.request.get(`/v1/json-string/${id}/`, {
      params,
    });

    return data;
  },

  editSetting: async (params: any, id: string | number) => {
    const { data } = await api.request.patch(`/v1/json-string/${id}/`, params);
    return data;
  },

  deleteSettings: async (id: string | number) => {
    const { data } = await api.request.delete(`/v1/json-string/${id}/`);
    return data;
  },
};
