import React from 'react';
import { Form, Select, Checkbox, Input } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';
import { IconSelect } from '../';

export default ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, 'dataType']}
        initialValue="regular"
        label="Tip"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="regular">Regulat</Select.Option>
          <Select.Option value="fill">Umplut</Select.Option>
          <Select.Option value="ghost">Frontieră</Select.Option>
          <Select.Option value="primary">Galben</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={[item.id, lang, 'status']} label="Status" style={formItemStyle}>
        <Select>
          <Select.Option value="success">Verde</Select.Option>
          <Select.Option value="warning">Portocaliu</Select.Option>
          <Select.Option value="danger">Roșu</Select.Option>
          <Select.Option value="info">Albastru</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={[item.id, lang, 'text']} label="Text" style={formItemStyle}>
        <Input />
      </Form.Item>
      <Form.Item name={[item.id, lang, 'icon']} label="Icon" style={formItemStyle}>
        <IconSelect />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'circle']}
        valuePropName="checked"
        label="Circle"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  circle: !item.circle,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'disabled']}
        valuePropName="checked"
        label="Disabled"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  disabled: !item.disabled,
                },
              },
            });
          }}
        />
      </Form.Item>
    </>
  );
};
