import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import invite from 'lib/api/invite';
import { InviteUser } from 'types';

const AddUser: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const handleOpen = (): void => setVisible(true);
  const handleClose = (): void => setVisible(false);
  const handleInvite = async (values: InviteUser): Promise<void> => {
    await invite.inviteUser(values);
    handleClose();
  };

  return (
    <>
      <Button type="primary" size="large" onClick={handleOpen}>
        Adaugă utilizator
      </Button>

      <Modal
        width={400}
        title="Adaugă utilizator"
        visible={visible}
        onCancel={handleClose}
        footer={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" form="invite-user">
              Save
            </Button>
          </>
        }
        destroyOnClose
      >
        <Form onFinish={handleInvite} id="invite-user" autoComplete="off" layout="vertical">
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              { required: true, message: 'E-mailul este obligatoriu!' },
              {
                type: 'email',
                message: 'Vă rugăm să introduceți un email valid',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddUser;
