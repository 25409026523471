import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, Modal, Row, Col, Table, notification } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { ColumnProps } from 'antd/lib/table';
import { defaultLocale } from 'lib/utils';
import jobs, { Vacancy } from 'lib/api/jobs';
import VacanciesModal from 'components/Vacancies/VacanciesModal';
import { StateT } from 'lib/api/pages';
import { ArrayResult } from 'types';

const Vacancies: FC = () => {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [selectedId, setSelectedId] = useState<number>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<ArrayResult<Vacancy>>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;

  const fetch = useCallback(async () => {
    try {
      setData(await jobs.all({ name_content_icontains: search, page }));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [search, page]);

  const onRemove = async ({ id }: Vacancy): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest articol?',
      onOk: async () => {
        try {
          await jobs.remove(id);
          fetch();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const handleOnSuccess = (): void => {
    fetch();
    setOpenModal(false);
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const handleOpenModal = (id?: number): void => {
    setOpenModal(true);
    setSelectedId(id);
  };

  const onSearchHandler = (): void => {
    history.push({
      pathname: '/vacancies',
      search: '?' + new URLSearchParams({ search: searchValue }).toString(),
    });
  };

  const columns: ColumnProps<Vacancy>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      render: ({ i18n }) => {
        const { title = '-' } = i18n[defaultLocale];

        return title;
      },
    },
    {
      title: 'State',
      render: ({ state }) => {
        return StateT[state as keyof typeof StateT];
      },
    },
    {
      title: 'Numarul de ordine',
      dataIndex: 'order',
    },
    {
      width: 175,
      title: 'Data publicarii',
      render: ({ publish_date }) => `${moment(publish_date).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Acțiuni',
      width: 400,
      align: 'center',
      render: (vacancy) => {
        return (
          <Row justify="end" style={{ display: 'flex' }}>
            <Button
              icon={<EditOutlined />}
              type="primary"
              onClick={() => {
                setSelectedId(vacancy.id);
                setOpenModal(true);
              }}
            >
              Editează
            </Button>

            <Button
              icon={<DeleteOutlined />}
              onClick={() => onRemove(vacancy)}
              // type="danger"
              style={{ marginLeft: 20 }}
            >
              Șterge
            </Button>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Row
        type="flex"
        gutter={10}
        justify="space-between"
        align="middle"
        style={{ marginBottom: 10 }}
      >
        <Col>
          <Search
            enterButton="Caută"
            placeholder="Caută post vacant după titlu"
            size="large"
            value={searchValue}
            onSearch={onSearchHandler}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Col>
        <Col>
          <Button type="primary" size="large" onClick={() => handleOpenModal()}>
            Creează posturi vacante
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />

      {openModal && (
        <VacanciesModal
          id={selectedId}
          onSuccess={handleOnSuccess}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default Vacancies;
