import * as React from 'react';
import { useDebounce } from 'react-use';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import { Input, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import map, { OpenStreet } from 'lib/api/map';
import { iconPin } from './Pin';
import 'leaflet/dist/leaflet.css';
import './style.css';

const center = {
  lat: 47.00556,
  lng: 28.8575,
};

interface DraggableMarkerProps {
  position: [number, number];
  setPosition: (position: [number, number]) => void;
  setSearchValue: (search: string) => void;
}

const ChangeView = ({
  center,
  zoom,
  onChange,
}: {
  center: [number, number];
  zoom: number;
  onChange: (value: number) => void;
}) => {
  const map = useMap();
  map.setView(center, zoom);
  onChange(zoom);
  return null;
};

const DraggableMarker: React.FC<DraggableMarkerProps> = ({
  position,
  setPosition,
  setSearchValue,
}) => {
  const markerRef = React.useRef<any>(null);
  const eventHandlers = React.useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const { lat, lng } = marker.getLatLng();
          setPosition([lat, lng]);
          map
            .reverse([lat, lng])
            .then((response) => response?.display_name && setSearchValue(response.display_name));
        }
      },
    }),
    [],
  );

  return (
    <Marker
      draggable
      icon={iconPin}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    />
  );
};

interface MapPickerProps {
  onSet: (coordinates: [number, number]) => void;
  initialMarker?: {
    lat: number;
    lng: number;
  };
}

const MapPicker: React.FC<MapPickerProps> = ({ onSet, initialMarker }) => {
  const [zoom, setZoom] = React.useState(17);
  const [data, setData] = React.useState<OpenStreet[]>();
  const [chosenPosition, setChosenPosition] = React.useState(initialMarker || center);
  const [searchValue, setSearchValue] = React.useState('');

  const handleSearch = React.useCallback(async () => {
    if (!searchValue) {
      setData(undefined);
      return;
    }
    try {
      setData(await map.get(searchValue));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [searchValue]);

  const [, cancel] = useDebounce(
    () => {
      handleSearch();
    },
    350,
    [searchValue],
  );

  const handleChooseLocation = (location: OpenStreet) => {
    onSet([parseFloat(location.lat), parseFloat(location.lon)]);
    setChosenPosition({ lat: parseFloat(location.lat), lng: parseFloat(location.lon) });
    if (location?.display_name) {
      setSearchValue(location.display_name);
    }
    setData(undefined);
  };

  const handleSetPosition = ([lat, lng]: [number, number]) => {
    onSet([lat, lng]);
    setChosenPosition({ lat, lng });
  };

  return (
    <div className="wrapper-map-picker">
      <Input
        placeholder="Căutați după adresă"
        size="large"
        value={searchValue}
        suffix={<SearchOutlined />}
        onChange={({ target: { value } }) => {
          cancel();
          setSearchValue(value);
        }}
      />
      {data && (
        <div className="map-search-results">
          {data.map((item, key) => (
            <div
              key={key}
              className="map-search-results-item"
              onClick={() => handleChooseLocation(item)}
            >
              {item.display_name}
            </div>
          ))}
          {!data.length && (
            <div className="map-search-results-item" onClick={() => setData(undefined)}>
              Nu sa găsit nimic
            </div>
          )}
        </div>
      )}
      <MapContainer
        center={[chosenPosition.lat, chosenPosition.lng]}
        zoom={zoom}
        scrollWheelZoom={false}
      >
        <ChangeView
          center={[chosenPosition.lat, chosenPosition.lng]}
          zoom={zoom}
          onChange={setZoom}
        />
        <TileLayer
          className="title-layer"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker
          position={[chosenPosition.lat, chosenPosition.lng]}
          setPosition={handleSetPosition}
          setSearchValue={setSearchValue}
        />
      </MapContainer>
    </div>
  );
};
export default MapPicker;
