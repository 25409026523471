import api from '.';
import { UploadFile } from 'antd';
import { stringifyUrl } from 'utils/query';
import { ValidationError } from 'errors';
import { EnvApi } from 'types';

export const ONLINE_BANKING = 'online_banking';

export interface Attachment extends UploadFile {
  id?: number;
  public: boolean;
  location?: string;
  url: string;
  uid: string;
  file: string;
  title: string;
  filename: string;
  extension: string;
}

export default {
  getAttachments: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(
        'attachments',
        { type: ONLINE_BANKING, ...params },
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  postAttachments: async (file: File) => {
    let error, data;
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', ONLINE_BANKING);
    formData.append('public', 'true');

    try {
      ({ data } = await api.request.post(
        stringifyUrl('attachments', {}, { api: EnvApi.API_MAIN_URL }),
        formData,
        {
          headers: {
            'Accept-Language': 'ro',
          },
        },
      ));
    } catch (e: any) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;

        const body =
          typeof data === 'string'
            ? JSON.parse(data) // this will fail if string is not valid JSON.
            : data;

        error = new ValidationError(body, data, data.non_field_errors);
      } else {
        error = message;
      }
    }

    return [error, data];
  },

  getAttachment: async (id: number) => {
    const { data } = await api.request.get(
      stringifyUrl(
        `attachments/${id}`,
        {},
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  removeAttachment: async (id: number) =>
    await api.request.delete(stringifyUrl(`attachments/${id}`, {}, { api: EnvApi.API_MAIN_URL })),
};
