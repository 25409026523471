import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { notification, Spin, message } from 'antd';
import { ArticleForm } from 'components/Articles';
import api, { Article } from 'lib/api/pages';

enum Operation {
  CREATE = 'create',
  EDIT = 'edit',
}

const EditPage: React.FC = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: Operation }>();
  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    if (id && tab === Operation.EDIT) {
      try {
        api.bySlug(id).then((data) => setArticle(data));
      } catch (e) {
        history.push('/pages');

        return notification.error({ message: 'Articol inexistent' });
      }
    }
  }, [id, tab]);

  switch (tab) {
    case Operation.CREATE:
      return (
        <ArticleForm
          onSaved={() => {
            message.success('Pagina a fost creată cu succes');
            history.push('/pages');
          }}
        />
      );
    case Operation.EDIT:
      return (
        <Spin spinning={!article} delay={300}>
          {article && (
            <ArticleForm
              initialArticle={article}
              onSaved={(data) => {
                setArticle(data);
                message.success('Pagina a fost salvată cu succes');
              }}
            />
          )}
        </Spin>
      );

    default:
      return <Redirect to="/pages" />;
  }
};

export default EditPage;
