import React from 'react';
import { Row, Col } from 'antd';
import {
  InsertRowBelowOutlined,
  IdcardOutlined,
  LinkOutlined,
  UserOutlined,
  ColumnHeightOutlined,
  InteractionOutlined,
  EyeInvisibleOutlined,
  SplitCellsOutlined,
  FileTextOutlined,
  FileImageOutlined,
  HighlightOutlined,
  BorderOutlined,
  CalculatorOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
  MailOutlined,
  NotificationOutlined,
  EnvironmentOutlined,
  ControlOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  ProfileOutlined,
  LayoutOutlined,
  BookOutlined,
  TransactionOutlined,
  SwapOutlined,
  StarOutlined,
  BranchesOutlined,
  DatabaseOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons';

import titles from './titles.json';

export const Fields = {
  content: (
    <Row gutter={[8, 8]}>
      <Col>
        <ProfileOutlined />
      </Col>
      <Col>{titles.content}</Col>
    </Row>
  ),
  componentAvatar: (
    <Row gutter={[8, 8]}>
      <Col>
        <UserOutlined />
      </Col>
      <Col>{titles.componentAvatar}</Col>
    </Row>
  ),
  componentButton: (
    <Row gutter={[8, 8]}>
      <Col>
        <InteractionOutlined />
      </Col>
      <Col>{titles.componentButton}</Col>
    </Row>
  ),
  componentCard: (
    <Row gutter={[8, 8]}>
      <Col>
        <IdcardOutlined />
      </Col>
      <Col>{titles.componentCard}</Col>
    </Row>
  ),
  componentCollapse: (
    <Row gutter={[8, 8]}>
      <Col>
        <ColumnHeightOutlined />
      </Col>
      <Col>{titles.componentCollapse}</Col>
    </Row>
  ),
  componentContainer: (
    <Row gutter={[8, 8]}>
      <Col>
        <BorderOutlined />
      </Col>
      <Col>{titles.componentContainer}</Col>
    </Row>
  ),
  componentImage: (
    <Row gutter={[8, 8]}>
      <Col>
        <FileImageOutlined />
      </Col>
      <Col>{titles.componentImage}</Col>
    </Row>
  ),
  componentLabel: (
    <Row gutter={[8, 8]}>
      <Col>
        <HighlightOutlined />
      </Col>
      <Col>{titles.componentLabel}</Col>
    </Row>
  ),
  componentLink: (
    <Row gutter={[8, 8]}>
      <Col>
        <LinkOutlined />
      </Col>
      <Col>{titles.componentLink}</Col>
    </Row>
  ),
  componentVideo: (
    <Row gutter={[8, 8]}>
      <Col>
        <VideoCameraAddOutlined />
      </Col>
      <Col>{titles.componentVideo}</Col>
    </Row>
  ),
  componentResponsive: (
    <Row gutter={[8, 8]}>
      <Col>
        <EyeInvisibleOutlined />
      </Col>
      <Col>{titles.componentResponsive}</Col>
    </Row>
  ),
  componentSpace: (
    <Row gutter={[8, 8]}>
      <Col>
        <SplitCellsOutlined />
      </Col>
      <Col>{titles.componentSpace}</Col>
    </Row>
  ),
  componentText: (
    <Row gutter={[8, 8]}>
      <Col>
        <FileTextOutlined />
      </Col>
      <Col>{titles.componentText}</Col>
    </Row>
  ),
  moduleMap: (
    <Row gutter={[8, 8]}>
      <Col>
        <EnvironmentOutlined />
      </Col>
      <Col>{titles.moduleMap}</Col>
    </Row>
  ),
  sectionRow: (
    <Row gutter={[8, 8]}>
      <Col>
        <InsertRowBelowOutlined />
      </Col>
      <Col>{titles.sectionRow}</Col>
    </Row>
  ),
  calculator: (
    <Row gutter={[8, 8]}>
      <Col>
        <CalculatorOutlined />
      </Col>
      <Col>{titles.calculator}</Col>
    </Row>
  ),

  subsidiariesATM: (
    <Row gutter={[8, 8]}>
      <Col>
        <DollarOutlined />
      </Col>
      <Col>{titles.subsidiariesATM}</Col>
    </Row>
  ),
  news: (
    <Row gutter={[8, 8]}>
      <Col>
        <NotificationOutlined />
      </Col>
      <Col>{titles.news}</Col>
    </Row>
  ),
  faq: (
    <Row gutter={[8, 8]}>
      <Col>
        <QuestionCircleOutlined />
      </Col>
      <Col>{titles.faq}</Col>
    </Row>
  ),
  contactUs: (
    <Row gutter={[8, 8]}>
      <Col>
        <MailOutlined />
      </Col>
      <Col>{titles.contactUs}</Col>
    </Row>
  ),
  complaintForm: (
    <Row gutter={[8, 8]}>
      <Col>
        <MailOutlined />
      </Col>
      <Col>{titles.complaintForm}</Col>
    </Row>
  ),
  banners: (
    <Row gutter={[8, 8]}>
      <Col>
        <BookOutlined />
      </Col>
      <Col>{titles.banners}</Col>
    </Row>
  ),
  exchange: (
    <Row gutter={[8, 8]}>
      <Col>
        <SwapOutlined />
      </Col>
      <Col>{titles.exchange}</Col>
    </Row>
  ),
  converter: (
    <Row gutter={[8, 8]}>
      <Col>
        <TransactionOutlined />
      </Col>
      <Col>{titles.converter}</Col>
    </Row>
  ),
  useful: (
    <Row gutter={[8, 8]}>
      <Col>
        <StarOutlined />
      </Col>
      <Col>{titles.useful}</Col>
    </Row>
  ),
  sitemap: (
    <Row gutter={[8, 8]}>
      <Col>
        <BranchesOutlined />
      </Col>
      <Col>{titles.sitemap}</Col>
    </Row>
  ),
  form: (
    <Row gutter={[8, 8]}>
      <Col>
        <DatabaseOutlined />
      </Col>
      <Col>{titles.form}</Col>
    </Row>
  ),
};

export default [
  {
    type: 'components',
    title: (
      <Row gutter={[8, 8]}>
        <Col>
          <ControlOutlined />
        </Col>
        <Col>{titles.components}</Col>
      </Row>
    ),
    children: [
      {
        type: 'componentContainer',
        title: Fields.componentContainer,
        isMultiple: true,
        canNesting: true,
      },
      {
        type: 'section',
        title: (
          <Row gutter={[8, 8]}>
            <Col>
              <LayoutOutlined />
            </Col>
            <Col>{titles.section}</Col>
          </Row>
        ),
        isMultiple: true,
        canNesting: true,
      },
      {
        type: 'componentSpace',
        title: Fields.componentSpace,
        isMultiple: true,
        canNesting: true,
      },
      {
        type: 'componentText',
        title: Fields.componentText,
        isMultiple: true,
      },
      {
        type: 'componentLink',
        title: Fields.componentLink,
        isMultiple: true,
      },
      {
        type: 'componentVideo',
        title: Fields.componentVideo,
        isMultiple: true,
      },
      {
        type: 'componentButton',
        title: Fields.componentButton,
        isMultiple: true,
      },
      {
        type: 'componentLabel',
        title: Fields.componentLabel,
        isMultiple: true,
      },
      {
        type: 'componentImage',
        title: Fields.componentImage,
        isMultiple: true,
      },
      {
        type: 'componentCard',
        title: Fields.componentCard,
        canDrag: true,
        isMultiple: true,
        canNesting: true,
      },
      {
        type: 'componentCollapse',
        title: Fields.componentCollapse,
        canDrag: true,
        isMultiple: true,
        canNesting: true,
      },
    ],
  },
  {
    type: 'modules',
    title: (
      <Row gutter={[8, 8]}>
        <Col>
          <AppstoreOutlined />
        </Col>
        <Col>{titles.modules}</Col>
      </Row>
    ),
    children: [
      {
        type: 'banners',
        title: Fields.banners,
      },
      {
        type: 'calculator',
        title: Fields.calculator,
      },
      {
        type: 'contactUs',
        title: Fields.contactUs,
      },
      {
        type: 'complaintForm',
        title: Fields.complaintForm,
      },
      {
        type: 'converter',
        title: Fields.converter,
      },
      {
        type: 'exchange',
        title: Fields.exchange,
      },
      {
        type: 'faq',
        title: Fields.faq,
      },
      {
        type: 'form',
        title: Fields.form,
      },
      {
        type: 'moduleMap',
        title: Fields.moduleMap,
      },
      {
        type: 'news',
        title: Fields.news,
        isMultiple: true,
      },
      {
        type: 'subsidiariesATM',
        title: Fields.subsidiariesATM,
      },
      // {
      //   type: 'useful',
      //   title: Fields.useful,
      // },
      {
        type: 'sitemap',
        title: Fields.sitemap,
      },
    ],
  },
  {
    type: 'cards',
    title: (
      <Row gutter={[8, 8]}>
        <Col>
          <ContainerOutlined />
        </Col>
        <Col>{titles.cards}</Col>
      </Row>
    ),
    children: [
      { type: 'download', title: titles.download, isMultiple: true },
      { type: 'deposit', title: titles.deposit, isMultiple: true },
      { type: 'credit', title: titles.credit, isMultiple: true },
      { type: 'special', title: titles.special, isMultiple: true },
      { type: 'paymentCard', title: titles.paymentCard, isMultiple: true },
      { type: 'map', title: titles.map, isMultiple: true },
      { type: 'slider', title: titles.slider, isMultiple: true },
      { type: 'contact', title: titles.contact, isMultiple: true },
      { type: 'service', title: titles.service, isMultiple: true },
      { type: 'information', title: titles.information, isMultiple: true },
      { type: 'paymentMethods', title: titles.paymentMethods, isMultiple: true },
      { type: 'security', title: titles.security, isMultiple: true },
    ],
  },
];
