import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, Modal, Row, Col, Table, Dropdown, Menu, notification } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { defaultLocale } from 'lib/utils';
import faq, { QandA } from 'lib/api/faq';
import FrequentlyAskedQuestionModal from 'components/FAQ/FrequentlyAskedQuestionModal';
import { ArrayResult } from 'types';

const FrequentlyAskedQuestion: FC = () => {
  const history = useHistory();

  const [selectedId, setSelectedId] = useState<number>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<ArrayResult<QandA>>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const ordering = params.ordering || '-timestamp';

  const fetch = useCallback(async () => {
    try {
      setData(await faq.getAll({ ...params, page, ordering: ordering || '-timestamp' }));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [page]);

  const onRemove = async ({ id }: QandA): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest articol?',
      onOk: async () => {
        try {
          await faq.remove(id);
          fetch();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const handleOnSuccess = (): void => {
    fetch();
    setOpenModal(false);
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleOpenModal = (id?: number): void => {
    setOpenModal(true);
    setSelectedId(id);
  };

  const columns: ColumnProps<QandA>[] = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Întrebare',
        render: ({ i18n }) => {
          const { question = '-' } = i18n[defaultLocale];

          return question;
        },
      },

      // {
      //   title: 'Voturi pozitive',
      //   dataIndex: 'upvote',
      // },
      // {
      //   title: 'Voturi negative',
      //   dataIndex: 'downvote',
      // },

      {
        width: 175,
        title: 'Data publicarii',
        render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
      },
      {
        width: 175,
        title: 'Data editarii',
        render: ({ edited_timestamp }) => `${moment(edited_timestamp).format('DD/MM/YYYY, HH:mm')}`,
      },
      {
        title: 'Acțiuni',
        width: 100,
        align: 'right',
        render: (vacancy) => {
          return (
            <Dropdown.Button
              overlay={
                <Menu>
                  <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(vacancy)}>
                    Șterge
                  </Menu.Item>
                </Menu>
              }
              onClick={() => {
                setSelectedId(vacancy.id);
                setOpenModal(true);
              }}
            >
              <EditOutlined /> Editează
            </Dropdown.Button>
          );
        },
      },
    ],
    [data],
  );

  return (
    <>
      <Row gutter={10} justify="end" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Button type="primary" size="large" onClick={() => handleOpenModal()}>
            Creează întrebari frecvente
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />

      {openModal && (
        <FrequentlyAskedQuestionModal
          id={selectedId}
          onSuccess={handleOnSuccess}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default FrequentlyAskedQuestion;
