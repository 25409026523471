import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { Tree, Spin, Row, Col, Input } from 'antd';
import {
  DragOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import pages from 'lib/api/pages';

import DraggableItem from './DraggableItem';
import { loop, normalizeTree } from '../utils';

const TreeNode = Tree.TreeNode;
const Drag = (props) => {
  const [search, setSearch] = useState('');
  const [state, setState] = useState({
    search: '',
    selectedKey: undefined,
    expandedKeys: [],
    loading: true,
    treeContent: [],
  });

  const getPages = () => {
    try {
      pages
        .all({
          ordering: 'id',
          name_content_icontains: state.search,
          page: 1,
          page_size: 1000,
          state: 1,
        })
        .then(({ results: treeContent }) =>
          setState({ treeContent: normalizeTree(treeContent), loading: false }),
        );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setState({ loading: true });
    getPages();
  }, [state.search]);

  const onMouseDown = (dragItem) => {
    props.handler({ dragItem });
    props.onDrag();
  };

  const getTreeNode = (treeContent) => {
    if (!treeContent || treeContent.length === 0) {
      return null;
    }

    const treeNode = treeContent.map((value, i) => {
      let disabled = false;

      loop(props.state.treeContent, value.id, () => {
        disabled = true;
      });

      return (
        <TreeNode
          key={i}
          title={
            <DraggableItem
              lang={props.lang}
              value={value}
              onMouseDown={() => onMouseDown(value)}
              onMouseOut={() => props.onDrop()}
              disabled={disabled}
            />
          }
          icon={
            <Row gutter={8}>
              {value?.children?.length ? (
                <Col>
                  {state.selectedKey?.includes(value.type) ? (
                    <FolderOpenOutlined />
                  ) : (
                    <FolderOutlined />
                  )}
                </Col>
              ) : null}
              {value.canDrag ||
              (typeof value.canDrag === 'undefined' && !value?.children?.length) ? (
                <Col>
                  <DragOutlined />
                </Col>
              ) : null}
            </Row>
          }
          showIcon={false}
          className="drag-tree__node"
          disabled={disabled}
        >
          {getTreeNode(value?.children || [])}
        </TreeNode>
      );
    });

    return treeNode;
  };

  const [, cancel] = useDebounce(
    () => {
      setState({ search });
    },
    350,
    [search],
  );

  const onChangeSearch = (e) => {
    cancel();
    setSearch(e.target.value);
  };

  return (
    <div className="drag-tree">
      <label style={{ marginBottom: 8 }}>Static pages</label>
      <Input
        value={search}
        suffix={<SearchOutlined />}
        onChange={onChangeSearch}
        style={{ marginBottom: 8 }}
      />
      <Tree
        showLine
        blockNode
        icon={<FolderOutlined />}
        showIcon
        expandedKeys={state.expandedKeys}
        selectedKeys={state.expandedKeys}
        onExpand={(expandedKeys) => setState({ expandedKeys })}
        onSelect={(expandedKeys) => setState({ expandedKeys })}
      >
        {getTreeNode(state.treeContent)}
      </Tree>
      {state.loading && <Spin loading={state.loading} className="full-width" />}
    </div>
  );
};

export default Drag;
