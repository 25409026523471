import * as React from 'react';
import { Button, Col, Modal, notification, Row, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { CustomOptionsForm, CustomActions } from 'components/CustomOptionsForm';
import classifiers, { NomenclaturesClassifiers } from 'lib/api/classifiers';
import { ArrayResult } from 'types';

const CustomOptions: React.FC = () => {
  const history = useHistory();
  const [dataClassifiers, setDataClassifiers] =
    React.useState<ArrayResult<NomenclaturesClassifiers>>();
  const [selectedItem, setSelectedItem] = React.useState<NomenclaturesClassifiers>();
  const [openForm, setOpenForm] = React.useState(false);

  const fetchClassifiers = React.useCallback(async () => {
    try {
      setDataClassifiers(await classifiers.getClassifiers());
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, []);

  React.useEffect(() => {
    fetchClassifiers();
  }, [fetchClassifiers]);

  const handleRemove = (code: string) => {
    Modal.confirm({
      title: 'Doriți să ștergeți acestă optiune?',
      onOk: async () => {
        try {
          await classifiers.removeClassifiers(code);
          fetchClassifiers();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const handleCreate = () => {
    setOpenForm(true);
  };

  const handleEdit = (code: string) => {
    setSelectedItem(dataClassifiers?.results.find((item) => item.code === code));
    setOpenForm(true);
  };

  const columns: ColumnProps<NomenclaturesClassifiers>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Titlu',
      dataIndex: 'title',
    },
    {
      title: 'Acțiuni',
      width: 400,
      align: 'right',
      render: (item) => {
        return <CustomActions code={item.code} onEdit={handleEdit} onRemove={handleRemove} />;
      },
    },
  ];

  const handleOnSave = () => {
    handleCloseForm();
    fetchClassifiers();
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedItem(undefined);
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={24} style={{ marginBottom: 10 }}>
          <Button icon={<PlusOutlined />} type="primary" size="large" onClick={handleCreate}>
            Creează custom optiune
          </Button>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataClassifiers ? dataClassifiers.results : []}
            rowKey="id"
            childrenColumnName=""
            pagination={{
              hideOnSinglePage: true,
              // current: page,
              pageSize: 10,
              // total: data ? data.count : 0,
            }}
            onChange={({ current }, filters: any) => {
              history.push(
                `?${new URLSearchParams({
                  // ...params,
                  ...filters,
                  page: String(current),
                }).toString()}`,
              );
            }}
          />
        </Col>
        {openForm && (
          <Modal
            title="Modal"
            visible={openForm}
            width="30%"
            okButtonProps={{ htmlType: 'submit', form: 'CustomOptionsForm' }}
            onCancel={handleCloseForm}
          >
            <CustomOptionsForm onSaved={handleOnSave} initialValue={selectedItem} />
          </Modal>
        )}
      </Row>
    </>
  );
};

export default CustomOptions;
