import * as React from 'react';
import { Form, FormInstance, Input } from 'antd';

import './style.css';

type Props = {
  formId: string;
  form: FormInstance<unknown>;
  handleSubmit: (values) => void;
};

const ShortUrlForm = ({ formId, form, handleSubmit }: Props) => (
  <Form id={formId} layout="vertical" form={form} onFinish={handleSubmit}>
    <Form.Item
      name="slug"
      label="Redirecționează de la"
      rules={[{ required: true, message: 'Slug-ul este necesar!' }]}
    >
      <Input className="short-url-slug" prefix="https://www.energbank.com/" />
    </Form.Item>
    <Form.Item
      name="redirect_to"
      label="Redirecționează către"
      rules={[{ required: true, message: 'Redirect url este necesar!' }]}
    >
      <Input />
    </Form.Item>
  </Form>
);

export default ShortUrlForm;
