import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { ColumnWidthOutlined } from '@ant-design/icons';

const size = {
  12: '100',
  8: '75',
  6: '50',
  4: '33',
  3: '25',
};

class RowSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [12, 8, 6, 4, 3],
      value: this.props.value || 12,
    };

    this.ref = React.createRef();
  }

  onChange = (value) => {
    this.setState({ value });

    this.props.onChange(value);
  };

  render() {
    return (
      <Dropdown
        overlayClassName="col-select"
        overlay={
          <Menu>
            {this.state.data.map((item) => (
              <Menu.Item
                key={item}
                className={this.state.value === item ? 'selected' : undefined}
                onClick={() => this.onChange(item)}
              >
                {size[item]} %
              </Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomLeft"
      >
        <Button size="small">
          <ColumnWidthOutlined /> {size[this.state.value]} %
        </Button>
      </Dropdown>
    );
  }
}

export default RowSelect;
