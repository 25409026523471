import React from 'react';
import { Form, Select, Checkbox, Input } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';

export default ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        label="Titlu"
        name={[item.id, lang, 'header']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți titlul`} />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'size']}
        initialValue="medium"
        label="Dimensiune"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="small">Mic</Select.Option>
          <Select.Option value="medium">Mediu</Select.Option>
          <Select.Option value="large">Mare</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'collapsible']}
        valuePropName="checked"
        label="Dezvăluitor"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  collapsible: !item.collapsible,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'collapsed']}
        valuePropName="checked"
        label="Închis"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  collapsed: !item.collapsed,
                },
              },
            });
          }}
        />
      </Form.Item>
    </>
  );
};
