import React, { useEffect } from 'react';
import { useSetState } from 'react-use';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import env from '@beam-australia/react-env';
import { Button, Row, Col, Tooltip, Select } from 'antd';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  CopyOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MergeCellsOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  PicCenterOutlined,
} from '@ant-design/icons';
import { Languages } from 'lib/utils';
import { BuilderProvider } from 'context/Builder';
import { Properties } from 'types';

import {
  copy,
  Drag,
  Drop,
  loopAll,
  loopObjects,
  normalizeTreeContent,
  read,
  titles,
} from './components';
const { Option } = Select;
export interface BuilderType {
  activeTabKey: Languages;
  value?: Properties;
  loading?: boolean;
  onChange?: (values: Properties) => void;
}

export type StateType = {
  dragType?: string;
  expandedKeys: string[];
  selectedKey: string[];
  treeContent: Properties;
  showPreview: boolean;
  isFullScreen: boolean;
  isDrag: boolean;
};

const Builder: React.FC<BuilderType> = (props) => {
  const [state, setState] = useSetState<StateType>({
    dragType: undefined,
    expandedKeys: [],
    selectedKey: Object.keys(props.value?.components || []).map((_, i) => i.toString()),
    treeContent: Object.keys(props.value?.components || [])
      .reduce((obj: Properties[], id) => {
        let params;
        const activeValue =
          props.value?.components && props.value?.components[id][props.activeTabKey];

        loopObjects(
          props?.value?.components || [],
          id,
          (item) => (params = item),
          props.activeTabKey,
        );

        return [
          ...obj,
          {
            id,
            type: activeValue?.type,
            title: titles[activeValue?.type],
            ...params,
          },
        ];
      }, [])
      .sort(({ id: prevId }, { id: currentId }) =>
        (props.value?.components || [])[prevId][props.activeTabKey]?.order >
        (props.value?.components || [])[currentId][props.activeTabKey]?.order
          ? 1
          : (props.value?.components || [])[currentId][props.activeTabKey]?.order >
            (props.value?.components || [])[prevId][props.activeTabKey]?.order
          ? -1
          : 0,
      ),
    showPreview: false,
    isFullScreen: false,
    isDrag: false,
  });

  const escFunction = (event) => {
    if (event.key === 'Escape') {
      setState({ isFullScreen: false });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  });

  useEffect(() => {
    if (props.value?.components) {
      setState({
        treeContent: Object.keys(props.value?.components)
          .reduce((obj: Properties[], id) => {
            let params;
            const activeValue =
              props.value?.components && props.value?.components[id][props.activeTabKey];

            loopObjects(
              props.value?.components || [],
              id,
              (item) => (params = item),
              props.activeTabKey,
            );

            return [
              ...obj,
              {
                id,
                type: activeValue?.type,
                title: titles[activeValue?.type],
                ...params,
              },
            ];
          }, [])
          .sort(({ id: prevId }, { id: currentId }) =>
            (props.value?.components || [])[prevId][props.activeTabKey]?.order >
            (props.value?.components || [])[currentId][props.activeTabKey]?.order
              ? 1
              : (props.value?.components || [])[currentId][props.activeTabKey]?.order >
                (props.value?.components || [])[prevId][props.activeTabKey]?.order
              ? -1
              : 0,
          ),
      });
      //onExpandAll();
    }
  }, [props.value]);

  const handler = (state) => {
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const onExpandAll = () => {
    const expandedKeys: string[] = [];

    loopAll(state.treeContent, ({ id }) => expandedKeys.push(id));

    setState({ expandedKeys });
  };

  const onSubmit = (values) => {
    if (props.onChange) {
      props.onChange({ ...props.value, components: values });
    }
  };

  let data;

  if (props.value?.components) {
    try {
      data = new URLSearchParams(
        Object.keys(props.value?.components).reduce(
          (obj, id) => ({
            ...obj,
            ...(props.value?.components && { [id]: JSON.stringify(props.value?.components[id]) }),
          }),
          {},
        ),
      );
    } catch {
      //
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Row
        gutter={[16, 16]}
        className={`full-width${state.isFullScreen ? ' full-screen' : ''}`}
        style={{ width: 'calc(100% + 16px)' }}
      >
        <Col className="drag-tree__components" xs={24} md={5}>
          <Drag
            name="components"
            state={state}
            handler={handler}
            onDrag={() => setState({ isDrag: true })}
            onDrop={() => setState({ dragType: undefined, isDrag: false })}
          />
        </Col>
        <Col xs={24} md={19}>
          <Row
            className="drag-tree__bar"
            gutter={[8, 8]}
            justify="end"
            style={{ paddingBottom: 8 }}
          >
            <Col>
              <Row gutter={[4, 4]} align="middle">
                <Col>
                  <Select
                    suffixIcon={
                      !props.value?.justifyContent ||
                      props.value?.justifyContent === 'flex-start' ? (
                        <AlignLeftOutlined />
                      ) : props.value?.justifyContent === 'flex-end' ? (
                        <AlignRightOutlined />
                      ) : props.value?.justifyContent === 'center' ? (
                        <AlignCenterOutlined />
                      ) : props.value?.justifyContent === 'space-between' ? (
                        <PicCenterOutlined />
                      ) : null
                    }
                    onChange={(value) => {
                      if (props.onChange) {
                        props.onChange({ ...props.value, justifyContent: value });
                      }
                    }}
                    value={props.value?.justifyContent || 'flex-start'}
                  >
                    <Option value="flex-start">Stânga</Option>
                    <Option value="center">Centru</Option>
                    <Option value="flex-end">Dreaptă</Option>
                    <Option value="space-between">Între ele</Option>
                  </Select>
                </Col>
                <Col>
                  <Button
                    type="ghost"
                    onClick={() =>
                      copy(
                        JSON.stringify({ state: state, initialValue: props.value?.justifyContent }),
                      )
                    }
                  >
                    <CopyOutlined /> Copiază
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="ghost"
                    onClick={() =>
                      read().then((val) => {
                        try {
                          const { state, initialValue } = JSON.parse(val as string);
                          if (state && initialValue) {
                            setState(state);

                            onSubmit(normalizeTreeContent(state.treeContent, initialValue));
                          }
                        } catch {
                          console.log('Failed to paste!');
                        }
                      })
                    }
                  >
                    <MergeCellsOutlined /> Pastă
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[4, 4]} align="middle">
                <Col>
                  <Tooltip placement="topLeft" title="Ascunde componentele interne">
                    <Button onClick={() => setState({ expandedKeys: [] })}>
                      <NodeCollapseOutlined /> Ascunde
                    </Button>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip placement="topLeft" title="Descoperă componentele interne">
                    <Button onClick={onExpandAll}>
                      <NodeExpandOutlined /> Descoperă
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            {/* <Col>
                <Button
                  type={state.isFullScreen ? 'showPreview' : 'default'}
                  onClick={() => setState({ showPreview: !state.showPreview })}
                >
                  <RocketOutlined /> {state.showPreview ? 'Hide' : 'Preview'}
                </Button>
              </Col> */}
            <Col>
              <Button
                type={state.isFullScreen ? 'primary' : 'default'}
                onClick={() => setState({ isFullScreen: !state.isFullScreen })}
              >
                {state.isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ height: '100%' }}>
            <Col span={state.showPreview ? 12 : 24}>
              <Drop
                lang={props.activeTabKey}
                initialValue={props.value?.components}
                loading={props.loading}
                state={state}
                isDrag={state.isDrag}
                handler={handler}
                onDrop={() => setState({ dragType: undefined, isDrag: false })}
                onSubmit={onSubmit}
              />
            </Col>

            {state.showPreview && data && (
              <Col span={12}>
                <iframe
                  src={`${
                    window.location.origin.includes('localhost')
                      ? `http://localhost:3000`
                      : env('WEB_APP_URL')
                  }/en/page/preview?${data.toString()}`}
                  style={{
                    border: 0,
                    transform: 'scale(0.5, 0.5) translate3d(-50%, -50%, 0)',
                    width: '200%',
                    height: '200%',
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </DndProvider>
  );
};

export default (props) => (
  <BuilderProvider>
    <Builder {...props} />
  </BuilderProvider>
);
