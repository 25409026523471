import React, { useState, useEffect } from 'react';
import env from '@beam-australia/react-env';
import { Form, Select, Input, Upload, Button, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import api from 'lib/api';
import { Properties } from 'types';
import { getBase64 } from 'utils';
import { formItemStyle } from '../helpers';

enum VideoType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

const REACT_APP_API_BASE_URL = env('API_BASE_URL');

export default ({ item, lang, setFieldsValue }: Properties) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!item?.dataType) {
      setFieldsValue({
        [item.id]: {
          [lang]: {
            dataType: VideoType.EXTERNAL,
          },
        },
      });
    }
  }, []);

  const handleChange = (info: Properties) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
  };

  const handleUpload = async (file: any): Promise<void> => {
    setLoading(true);

    try {
      const data = await api.pages.uploadFile(file);
      const { id: uid, upload, uploaded } = data;

      const newFile: UploadFile<any> = {
        uid,
        size: 1,
        name: upload,
        status: uploaded ? 'done' : 'error',
        url: `${REACT_APP_API_BASE_URL}/v1${upload}`,
        type: 'video',
      };

      setFieldsValue({
        [item.id]: {
          [lang]: {
            video: { fileList: [newFile], url: newFile.url },
          },
        },
      });
    } catch (e) {
      message.error('Unsuccessfull image upload!');
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file: any): boolean => {
    const isVideo =
      file.type === 'video/mp4' ||
      file.type === 'video/webm' ||
      file.type === 'video/ogv' ||
      file.type === 'video/m3u8';

    if (!isVideo) {
      message.error('Puteți încărca doar video în formatul mp4/webm/ogv/m3u8!');
      return false;
    }

    handleUpload(file);

    return false;
  };

  return (
    <>
      <Form.Item
        name={[item.id, lang, 'dataType']}
        initialValue={item.dataType || VideoType.EXTERNAL}
        label="Tip"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value={VideoType.EXTERNAL}>Extern</Select.Option>
          <Select.Option value={VideoType.INTERNAL}>Intern</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Link"
        name={[item.id, lang, 'link']}
        initialValue={item?.link}
        style={formItemStyle}
        extra="Pentru a adauga linkul de pe youtube trebue de apasat butonul 'share' apoi butonul 'embed' si de copiat codul."
      >
        <Input.TextArea
          disabled={VideoType.EXTERNAL !== item?.dataType}
          placeholder='<iframe width="560" height="315" src="https://www.youtube.com/embed/rEFqZK4E5fI?si=u5Mj7FdQhJa91ood" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
          rows={6}
        />
      </Form.Item>
      <Form.Item label="Video" style={formItemStyle}>
        <Upload
          multiple={false}
          accept="video/*"
          defaultFileList={item?.video?.fileList ? [item?.video?.fileList?.[0]] : []}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
        >
          <Button
            disabled={VideoType.INTERNAL !== item?.dataType}
            icon={loading ? <LoadingOutlined /> : <PlusOutlined />}
          >
            Faceți clic pentru a încărca
          </Button>
        </Upload>
      </Form.Item>
    </>
  );
};
