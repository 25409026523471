import React from 'react';
import { Layout as AntdLayout, Row, Col } from 'antd';
import { LogoDesktop } from 'resources';

import SiderMenu from './SiderMenu';

const { Header, Sider, Content } = AntdLayout;

const Layout: React.FC = ({ children }) => (
  <AntdLayout className="cms" style={{ minHeight: '100vh' }}>
    <Header>
      <Row justify="space-between" align="middle">
        <Col className="logo">
          <img src={LogoDesktop} />
        </Col>

        <Col></Col>
      </Row>
    </Header>

    <AntdLayout hasSider>
      <Sider collapsible>
        <SiderMenu />
      </Sider>

      <Content style={{ padding: 10 }}>{children}</Content>
    </AntdLayout>
  </AntdLayout>
);

export default Layout;
