import React, { useState } from 'react';
import { Form, Input, Select, Upload, message } from 'antd';
import env from '@beam-australia/react-env';
import { UploadFile } from 'antd/lib/upload/interface';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import api from 'lib/api';
import { getBase64 } from 'utils';
import { Properties } from 'types';

import { maxImageSizeInMb, formItemStyle } from '../helpers';
import { IconSelect } from '../';

const REACT_APP_API_BASE_URL = env('API_BASE_URL');

export default ({ item, lang, setFieldsValue }: Properties) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (info: Properties) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
  };

  const handleUpload = async (file: any): Promise<void> => {
    setLoading(true);

    try {
      const data = await api.pages.uploadFile(file);
      const { id: uid, upload, uploaded } = data;

      const newFile: UploadFile<any> = {
        uid,
        size: 1,
        name: upload,
        status: uploaded ? 'done' : 'error',
        url: `${REACT_APP_API_BASE_URL}/v1${upload}`,
        type: 'image',
      };

      setFieldsValue({
        [item.id]: {
          [lang]: {
            img: { fileList: [newFile], url: newFile.url },
          },
        },
      });
    } catch (e) {
      message.error('Unsuccessfull image upload!');
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file: any): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Puteți încărca doar imagini în formatul JPG/PNG!');
      return false;
    }

    const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
    if (!isMbLt) {
      message.error(`Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`);
      return false;
    }
    handleUpload(file);

    return false;
  };

  return (
    <>
      <Form.Item
        name={[item.id, lang, 'size']}
        initialValue="small"
        label="Dimensiune"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="small">Mic</Select.Option>
          <Select.Option value="big">Big</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'dataType']}
        initialValue="regular"
        label="Tip"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="regular">Regulat</Select.Option>
          <Select.Option value="primary">Galben</Select.Option>
          <Select.Option value="light">Alb</Select.Option>
          <Select.Option value="dynamic">Dinamic</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={[item.id, lang, 'icon']} label="Icon" style={formItemStyle}>
        <IconSelect />
      </Form.Item>
      <Form.Item label=" ">sau</Form.Item>
      <Form.Item label="Avatar" style={formItemStyle}>
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          multiple={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
        >
          {item?.img?.url ? (
            <img src={item.img.url} alt="img" style={{ width: '100%' }} />
          ) : (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Încărca</div>
            </div>
          )}
        </Upload>
      </Form.Item>
      <Form.Item name={[item.id, lang, 'alt']} label="Titlu" style={formItemStyle}>
        <Input placeholder="Mark Zuckerberg" />
      </Form.Item>
      <Form.Item name={[item.id, lang, 'description']} label="Descriere" style={formItemStyle}>
        <Input placeholder="Administrator" />
      </Form.Item>
    </>
  );
};
