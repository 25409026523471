import React, { FC, useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { Button, Modal, Table, notification } from 'antd';
import { RetweetOutlined, MenuOutlined } from '@ant-design/icons';
import api, { ChildrenObjects } from 'lib/api/pages';
import { defaultLocale } from 'lib/utils';
import { ColumnProps } from 'antd/lib/table';

const DragHandle = SortableHandle(() => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  </div>
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

interface ReOrderProps {
  id: string;
  onSaved?: () => void;
}

const ReOrder: FC<ReOrderProps> = ({ id, onSaved }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<ChildrenObjects[]>();

  useEffect(() => {
    if (id && open && !data) {
      try {
        api
          .bySlug(id)
          .then((data) =>
            setData(
              data?.children_objects.sort((prev, curr) =>
                prev.order > curr.order ? 1 : curr.order > prev.order ? -1 : 0,
              ),
            ),
          );
      } catch (e) {
        setOpen(false);

        return notification.error({ message: 'Articol inexistent' });
      }
    }
  }, [id, open]);

  const columns: ColumnProps<ChildrenObjects>[] = [
    {
      title: '',
      dataIndex: 'order',
      width: 50,
      render: () => <DragHandle />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      render: ({ i18n }) => {
        const { title = '-' } = i18n[defaultLocale];

        return title;
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat((data || []) as any),
        oldIndex,
        newIndex,
      ) as ChildrenObjects[];

      setData(
        newData.map((item, i) => ({
          ...item,
          order: i,
        })),
      );
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = (restProps) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data?.findIndex(({ id }) => id === restProps['data-row-key']);

    return <SortableItem index={index} {...restProps} />;
  };

  const onSave = () => {
    data?.forEach(({ id, order }) => {
      api.upsert({
        id,
        order,
      });
    });

    setOpen(false);

    notification.success({ message: 'Order a fost modificat' });

    if (onSaved) {
      onSaved();
    }
  };

  return (
    <>
      <Button icon={<RetweetOutlined />} size="small" onClick={() => setOpen((i) => !i)} />
      <Modal
        visible={open}
        bodyStyle={{ padding: 0 }}
        onOk={onSave}
        onCancel={() => setOpen(false)}
      >
        <Table
          columns={columns}
          dataSource={data || []}
          rowKey="id"
          childrenColumnName=""
          pagination={false}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </Modal>
    </>
  );
};

export default ReOrder;
