import { RouteProps } from 'react-router-dom';
import { Roles } from 'lib/api/users';
import Recovery from 'pages/Recovery';
import Users from 'pages/Users';
import ArticlePage from 'pages/Article';
import EditArticle from 'pages/Article/Edit';
import Complaints from 'pages/Complaints';
import i18n from 'pages/i18n';
import Backgrounds from 'pages/Backgrounds';
import Banners from 'pages/Banners';
import Vacancies from 'pages/Vacancies';
import Log from 'pages/Log';
import Support from 'pages/Support';
import Settings from 'pages/Settings';
import Profile from 'pages/Profile';
import FrequentlyAskedQuestion from 'pages/FrequentlyAskedQuestion';
import Authentication from 'pages/Authentication';
import SignIn from 'pages/SignIn';
import { ATMPage, GroupsPage } from 'pages/ATM';
import MenuPage from 'pages/Menu';
import EditMenu from 'pages/Menu/Edit';
import FormsPage from 'pages/Forms';
import FormResults from 'pages/FormResults';
import EditFormsPage from 'pages/Forms/EditFormsPage';
import CustomOptions from 'pages/CustomOptions';
import CustomOptionList from 'pages/CustomOptions/CustomOptionList';
import { CreditsList, CreditForm, CreditTypesList } from 'pages/Credits';
import { DepositList, DepositForm } from 'pages/Deposit';
import OnlineBanking from 'pages/OnlineBanking';
import ShortUrl from 'pages/ShortUrl';

interface AppRoute extends RouteProps {
  name: string;
  path: string | string[];
  component: any;
  isPublic?: boolean;
}

const routes: AppRoute[] = [
  {
    name: 'Authentication',
    path: '/authentication/login',
    component: Authentication,
    isPublic: true,
  },
  {
    name: 'SignIn',
    path: '/authentication/register',
    component: SignIn,
    isPublic: true,
  },
  {
    name: 'Recovery',
    path: '/recovery-password',
    component: Recovery,
    isPublic: true,
  },
  { name: 'Users', path: '/users', component: Users, exact: true },
  { name: 'Profile', path: '/profile', component: Profile, exact: true },
  { name: 'Log', path: '/logs/:route/:id', component: Log },
  { name: 'EditPages', path: '/pages/:tab/:id?', component: EditArticle },
  { name: 'Pages', path: '/pages', component: ArticlePage },
  { name: 'EditMenu', path: '/menu/:tab/:id?', component: EditMenu },
  { name: 'Menu', path: '/menu', component: MenuPage },
  { name: 'EditFormsPage', path: '/forms/:tab/:id?', component: EditFormsPage },
  { name: 'Forms', path: '/forms-list', component: FormsPage },
  { name: 'FormResults', path: '/form-results', component: FormResults },
  {
    name: 'ATM',
    path: '/atm',
    component: ATMPage,
  },
  {
    name: 'Groups',
    path: '/atm/groups',
    component: GroupsPage,
  },

  { name: 'i18n', path: '/i18n', component: i18n },
  { name: 'Vacancies', path: '/vacancies', component: Vacancies },
  { name: 'Backgrounds', path: '/backgrounds', component: Backgrounds },
  { name: 'Banners', path: '/banners', component: Banners },
  { name: 'Banners', path: '/complaints', component: Complaints },
  { name: 'Contact', path: '/support', component: Support },
  { name: 'Settings', path: '/settings', component: Settings },
  { name: 'FrequentlyAskedQuestion', path: '/faq', component: FrequentlyAskedQuestion },
  { name: 'CustomOptions', path: '/custom-options', component: CustomOptions },
  { name: 'CustomOptionList', path: '/custom-options/:code', component: CustomOptionList },
  { name: 'CreditsList', path: '/credits', component: CreditsList },
  { name: 'CreditTypesList', path: '/credits/types/:code', component: CreditTypesList },
  { name: 'CreditForm', path: '/credits/:tab/:id?', component: CreditForm },
  { name: 'DepositList', path: '/deposit', component: DepositList },
  { name: 'DepositForm', path: '/deposit/:tab/:id?', component: DepositForm },
  { name: 'OnlineBanking', path: '/online-banking', component: OnlineBanking },
  { name: 'ShortUrl', path: '/short-url', component: ShortUrl },
];

export const defaultPath: { [key in Roles]: any } = {
  [Roles.ADMINISTRATOR]: '/users',
  [Roles.CONTENT_MANAGER]: '/pages',
};

export default routes;
