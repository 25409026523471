import React, { useEffect, useState } from 'react';
import { Form, notification, Select } from 'antd';
import api from 'lib/api';
import { FormsProps } from 'lib/api/forms';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';

export default ({ item, lang }: Properties) => {
  const [isLoading, setIsLoading] = useState(false);
  const [forms, setForms] = useState<FormsProps[]>([]);

  useEffect(() => {
    setIsLoading(true);
    try {
      api.forms
        .getForms({ name_content_icontains: '', page: 1, limit: 1000 })
        .then(({ results }) => {
          setForms(results);
          setIsLoading(false);
        });
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, []);

  return (
    <>
      <Form.Item
        name={[item.id, lang, 'formId']}
        initialValue={item?.action?.link}
        label="Alege"
        style={formItemStyle}
      >
        <Select loading={isLoading} placeholder="Select">
          {forms.map((page) => (
            <Select.Option key={page.id} value={page.id}>
              {page.i18n[lang]?.title || '-'}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
