import React from 'react';
import { Form, Select, Checkbox } from 'antd';
import { Properties } from 'types';

import { Link } from '../Custom';
import { formItemStyle } from '../helpers';

export enum NewsType {
  SLIDER = 'slider',
  STATIC = 'static',
}

export enum NewsCardType {
  DARK = 'dark',
  LIGHT = 'light',
  TRANSPARENT = 'transparent',
}

export default ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        label="Tip"
        name={[item.id, lang, 'dataType']}
        rules={[
          {
            required: true,
            message: 'Tipul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Select>
          <Select.Option value={NewsType.STATIC}>Static</Select.Option>
          <Select.Option value={NewsType.SLIDER}>Slider</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Tip de card" name={[item.id, lang, 'cardType']} style={formItemStyle}>
        <Select>
          <Select.Option value={NewsCardType.DARK}>Întuneric</Select.Option>
          <Select.Option value={NewsCardType.LIGHT}>Alb</Select.Option>
          <Select.Option value={NewsCardType.TRANSPARENT}>Transparent</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'showDate']}
        initialValue={true}
        valuePropName="checked"
        label="Arată data"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  showDate: !item.showDate,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'showDateBeforeTitle']}
        initialValue={true}
        valuePropName="checked"
        label="Arată data înainte de titlu"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  showDateBeforeTitle: !item.showDateBeforeTitle,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'showDescription']}
        initialValue={true}
        valuePropName="checked"
        label="Arată descrierea"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  showDescription: !item.showDescription,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'showMoreBtn']}
        valuePropName="checked"
        initialValue={true}
        label="Arată mai mult"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  showMoreBtn: !item.showMoreBtn,
                },
              },
            });
          }}
        />
      </Form.Item>
      {[NewsType.SLIDER].includes(item.dataType) && (
        <Form.Item
          name={[item.id, lang, 'action']}
          initialValue={item?.action}
          label="Acțiune"
          style={formItemStyle}
        >
          <Link lang={lang} />
        </Form.Item>
      )}
    </>
  );
};
