const fillEmpty = (arr) => {
  let hasEmpty = false;
  const value = Array.from(arr, (_, i) => {
    if (!(i in arr)) {
      hasEmpty = true;
      return null;
    } else return arr[i];
  });

  return {
    hasEmpty,
    value,
  };
};

export function merge(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      const fillTarget = fillEmpty(targetValue);

      target[key] =
        targetValue.length > sourceValue.length
          ? targetValue
          : (fillTarget.hasEmpty ? sourceValue : fillTarget.value).map((item, i) => ({
              ...(fillTarget.hasEmpty ? targetValue[i] : sourceValue[i]),
              ...item,
            }));
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = merge(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}
