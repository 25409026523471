import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Article } from 'lib/api/pages';
import env from '@beam-australia/react-env';
import { defaultLocale } from 'lib/utils';

const REACT_APP_WEB_APP_URL = env('WEB_APP_URL');
interface PageActionsProps {
  type?: string;
  article: Article;
  onRemove: (user: Article) => void;
  vizualization?: boolean;
}

/**
 * `ArticleActions` are used in `Article table`.
 * Containing 3 buttons: edit, remove and view.
 *
 * `Edit` button will redirect user to `/pages/edit/id`
 * `Delete` button will remove data and trigger refetch table content.
 * `View` button is a link to the article for preview.
 */
const ArticleActions: React.FC<PageActionsProps> = ({ article, onRemove, type = 'pages' }) => {
  const history = useHistory();

  return (
    <Dropdown.Button
      overlay={
        <Menu>
          <a
            target="_blank"
            rel="noopener noreferrer"
            type="dashed"
            href={`${REACT_APP_WEB_APP_URL}/${defaultLocale}/page/${article.i18n[defaultLocale]?.slug}`}
          >
            <Menu.Item key="1" icon={<EyeOutlined />}>
              Vizualizează
            </Menu.Item>
          </a>
          <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(article)}>
            Șterge
          </Menu.Item>
        </Menu>
      }
      onClick={() => history.push(`/${type}/edit/${article.id}`)}
    >
      <EditOutlined /> Editează
    </Dropdown.Button>
  );
};

export default ArticleActions;
