import { Languages } from 'lib/utils';
import api from '.';

export enum ArticleTags {
  PAGE = 4,
  NEWS = 5,
  // MENU = 6,
  // MENU_FOOTER = 7,
  // TEAM = 9,
  // TEAM_MEMBER = 10,
  // SCHEMA = 8,
  // MAP = 11,
  SECURITY = 13,
  HOME = 14,
  BUSINESS = 15,
  DISCLOSURE_INFORMATION = 16,
  ANNOUNCER = 17,
  PROMOTIONS = 18,
}

export enum ArticleTagsCode {
  PAGE = 'page',
  NEWS = 'news',
  // MENU = 'menu',
  // MENU_FOOTER = 'menu-footer',
  // TEAM = 'team',
  // TEAM_MEMBER = 'team-member',
  // SCHEMA = 'schema',
  // MAP = 'map',
  SECURITY = 'security',
  HOME = 'home',
  BUSINESS = 'business',
  DISCLOSURE_INFORMATION = 'info',
  ANNOUNCER = 'announces',
  PROMOTIONS = 'promotions',
}

export interface Tag {
  id: ArticleTags;
  code: ArticleTagsCode;
  i18n: Record<Languages, string>;
  pid?: ArticleTags;
}

export const defaultTags: Tag[] = [
  {
    id: ArticleTags.PAGE,
    code: ArticleTagsCode.PAGE,
    i18n: {
      en: 'Page',
      ro: 'Pagina',
      ru: 'Страница',
    },
    pid: ArticleTags.PAGE,
  },
  {
    id: ArticleTags.NEWS,
    code: ArticleTagsCode.NEWS,
    i18n: {
      en: 'News',
      ro: 'Noutăți',
      ru: 'Новости',
    },
    pid: ArticleTags.NEWS,
  },
  {
    id: ArticleTags.ANNOUNCER,
    code: ArticleTagsCode.ANNOUNCER,
    i18n: {
      en: 'Announces',
      ro: 'Anunțuri',
      ru: 'Объявления',
    },
    pid: ArticleTags.ANNOUNCER,
  },
  {
    id: ArticleTags.PROMOTIONS,
    code: ArticleTagsCode.PROMOTIONS,
    i18n: {
      en: 'Promotions',
      ro: 'Promoții',
      ru: 'Акции',
    },
    pid: ArticleTags.PROMOTIONS,
  },
  // {
  //   id: ArticleTags.MENU,
  //   code: ArticleTagsCode.MENU,
  //   i18n: {
  //     en: 'Menu',
  //     ro: 'Meniu',
  //     ru: 'Меню',
  //   },
  // },
  // {
  //   id: ArticleTags.MENU_FOOTER,
  //   code: ArticleTagsCode.MENU_FOOTER,
  //   i18n: {
  //     en: 'Footer Menu',
  //     ro: 'Meniu subsol',
  //     ru: 'Нижнее меню',
  //   },
  //   pid: ArticleTags.MENU,
  // },
  // {
  //   id: ArticleTags.SCHEMA,
  //   code: ArticleTagsCode.SCHEMA,
  //   i18n: {
  //     en: 'Organizational Chart',
  //     ro: 'Organigrama',
  //     ru: 'Организационная структура',
  //   },
  // },
  // {
  //   id: ArticleTags.TEAM,
  //   code: ArticleTagsCode.TEAM,
  //   i18n: {
  //     en: 'Leadership',
  //     ro: 'Conducerea',
  //     ru: 'Руководство',
  //   },
  // },
  // {
  //   id: ArticleTags.TEAM_MEMBER,
  //   code: ArticleTagsCode.TEAM_MEMBER,
  //   i18n: {
  //     en: 'Board of Directors',
  //     ro: 'Consiliu de administrație',
  //     ru: 'Совет директоров',
  //   },
  //   pid: ArticleTags.TEAM,
  // },
  // {
  //   id: ArticleTags.MAP,
  //   code: ArticleTagsCode.MAP,
  //   i18n: {
  //     en: 'ATM map',
  //     ro: 'Harta cu oficii si atm',
  //     ru: 'Карта ATM',
  //   },
  // },
  {
    id: ArticleTags.SECURITY,
    code: ArticleTagsCode.SECURITY,
    i18n: {
      en: 'Security',
      ro: 'Securitate',
      ru: 'Безопасность',
    },
    pid: ArticleTags.SECURITY,
  },
  {
    id: ArticleTags.HOME,
    code: ArticleTagsCode.HOME,
    i18n: {
      en: 'Home page',
      ro: 'Pagina principală',
      ru: 'Главная страница',
    },
    pid: ArticleTags.HOME,
  },
  // {
  //   id: ArticleTags.DISCLOSURE_INFORMATION,
  //   code: ArticleTagsCode.DISCLOSURE_INFORMATION,
  //   i18n: {
  //     en: 'Home page',
  //     ro: 'Disclosure information',
  //     ru: 'Раскрытие информации',
  //   },
  //   pid: ArticleTags.DISCLOSURE_INFORMATION,
  // },
];

export default {
  /**
   * Set default names to all tags
   */
  resetAll: async () => {
    for (const item of defaultTags) {
      const { id, ...tag } = item;
      await api.request.patch(`/v1/articleTag/${id}/`, tag);
    }
    // await api.request.post(`/v1/articleTag/`, { code: tag.code, i18n: tag.i18n });
  },
};
