import React, { useContext, useState } from 'react';
import { DropTarget } from 'react-dnd';
import { useDebounce, useSetState } from 'react-use';
import { Tree, Spin, Empty, Tabs, Row, Col, Button, Input } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import EmptyData from 'resources/icons/empty.svg';

import Item from './Item';
import { Fields } from '../Drag/Fields';
import { loop } from '../utils';
import { titles } from '..';
import { Properties } from 'types';
import { TypeForm } from 'lib/api/forms';
import { BuilderAction, BuilderContext } from 'context/Builder';
import { uid } from 'utils';

const TreeNode = Tree.TreeNode;

const Drop: React.FC<Properties> = (props) => {
  const {
    loading,
    state: { content, drag, keys },
    tabKey: category,
    lang,
    dispatch,
  } = useContext(BuilderContext);
  const [state, setState] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [editStep, setEditStep] = useSetState({
    key: undefined,
    value: '',
  });

  useDebounce(
    () => {
      setIsOver(state);
    },
    50,
    [state],
  );

  const onOver = (value) => {
    setState(value);
  };

  const onDrop = (info) => {
    let dragObj;

    const { node: dropNode, dragNode, dropPosition, dropToGap } = info;
    const dropKey = dropNode?.key;
    const dragKey = dragNode?.key;

    const dropPos = dropNode.pos.split('-');
    const dropIndex = dropPosition - Number(dropPos[dropPos.length - 1]);

    if ((dropNode?.canNesting && !dropToGap) || dropToGap) {
      loop(content, dragKey, (item, i, arr) => {
        arr.splice(i, 1);
        dragObj = { ...item, category };
      });
    }

    loop(content, dropKey, (item, i, arr) => {
      if (!dropToGap && item?.canNesting) {
        item.children = item.children || [];

        item.children.splice(dropIndex, 0, dragObj);
      } else if (dropToGap) {
        if (dropIndex === 1) {
          arr.splice(i + 1, 0, dragObj);
        } else {
          arr.splice(dropIndex === -1 ? i : i + 1, 0, dragObj);
        }
      }

      return item;
    });

    if (dragNode) {
      props.onSubmit(content);
    } else {
      addItem({
        type: drag.type,
        treeTitle: titles[drag.type as keyof typeof titles],
      });
    }

    dispatch({
      type: BuilderAction.SET_STATE,
      value: {
        drag: {
          type: undefined,
          is: false,
        },
      },
    });
  };

  const onCreate = (index) => {
    if (isOver && drag.type) {
      content.splice(index + 1, 0, {
        id: uid(),
        type: drag.type,
        category,
        label: '',
        name: '',
        options: {},
      });

      props.onSubmit(content);
      onOver(false);
    }
  };

  const onNodeSelect = (selectedKeys, e) => {
    if (
      Array.from(e.nativeEvent.srcElement.classList).some((className: any) =>
        className.includes('drop-tree__node-edit'),
      )
    ) {
      dispatch({
        type: BuilderAction.SET_STATE,
        value: {
          keys: {
            expanded: keys.expanded,
            selected: keys.selected.some((i) => selectedKeys.includes(i))
              ? keys.selected.filter((i) => !selectedKeys.includes(i))
              : [...keys.selected, ...selectedKeys],
          },
        },
      });
    }
  };

  const onExpand = (expandedKeys) => {
    dispatch({
      type: BuilderAction.SET_STATE,
      value: {
        keys: {
          expanded: expandedKeys,
          selected: keys.selected,
        },
      },
    });
  };

  const deleteItem = (i) => {
    content.splice(i, 1);

    props.onSubmit(content);

    dispatch({
      type: BuilderAction.SET_STATE,
      value: {
        keys: {
          expanded: keys.expanded,
          selected: keys.selected.filter((_, index) => index !== i),
        },
      },
    });
  };

  const getTreeNode = (treeContent, step?) => {
    if (!treeContent || treeContent.length === 0) {
      return <Empty image={EmptyData} description={<span>Drag & Drop elemente aici</span>} />;
    }

    const treeNode = treeContent.map((value, i) => {
      return (
        (!step || value.category === step) && (
          <TreeNode
            key={i.toString()}
            draggable
            showLine
            switcherIcon={
              value.children ? (
                !keys.expanded.includes(i.toString()) ? (
                  <FolderOutlined />
                ) : (
                  <FolderOpenOutlined />
                )
              ) : undefined
            }
            className={`drop-tree__node ant-col ant-col-${
              (value?.options?.col && value?.options.col * 2) || 12 * 2
            }${value?.children?.length ? ' has-children' : ''}${
              drag.type && drag.is && isOver === i.toString() ? ' is-over' : ''
            }`}
            title={
              <>
                <div
                  className="drop-tree__node-edit"
                  onDragOver={() => drag.is && onOver(i.toString())}
                  onDragLeave={() => drag.is && onOver(false)}
                  onDrop={() => onCreate(i)}
                >
                  <span>{Fields[value.type] || titles[value.type]}</span>
                  {keys.selected.includes(i.toString()) && (
                    <Item
                      category={category}
                      initialValue={props.initialValue}
                      item={value}
                      onSaved={props.onSubmit}
                    />
                  )}
                </div>

                {(typeof value.canRemove === 'undefined' || value.canRemove) && (
                  <DeleteOutlined onClick={() => deleteItem(i)} />
                )}
              </>
            }
            {...(value?.canNesting && { canNesting: value.canNesting })}
          >
            {getTreeNode(value.children, step)}
          </TreeNode>
        )
      );
    });

    return treeNode;
  };

  const addItem = (Item) => {
    content.push({ ...Item, id: uid(), category, label: null, name: '', value: null, options: {} });

    props.onSubmit(content);

    dispatch({
      type: BuilderAction.SET_STATE,
      value: {
        keys: {
          expanded: keys.expanded,
          selected: [...keys.selected, (content.length - 1).toString()],
        },
      },
    });
  };

  const onChangeTabs = (key, action) => {
    const prevTabs = props.code[lang].length ? props.code[lang].split(';') : [];

    switch (action) {
      case 'add':
        props.onChangeCode([...prevTabs, `Pas nou ${prevTabs.length + 1}`].join(';'));
        break;
      case 'remove':
        props.onChangeCode(prevTabs.filter((_, i) => i !== parseInt(key)).join(';'));
        break;
    }
  };

  const onSaveStep = () => {
    const prevTabs = props.code[lang].length ? props.code[lang].split(';') : [];

    props.onChangeCode(
      prevTabs
        .map((tab, i) => {
          if (i === editStep.key) {
            return editStep.value;
          }

          return tab;
        })
        .join(';'),
    );

    setEditStep({ key: undefined, value: '' });
  };

  const onChangeTabKey = (value) => {
    dispatch({
      type: BuilderAction.SET_TAB,
      value,
    });
  };

  return props.connectDropTarget(
    <div className="drop-tree drop-tree--hide-switcher" style={{ padding: 0 }}>
      {props.type === TypeForm.STEPS ? (
        <>
          <Tabs
            className="drop-tree__tabs"
            type="editable-card"
            addIcon={
              <Row gutter={[8, 8]}>
                <Col>
                  <PlusOutlined />
                </Col>
                <Col>Adaugă</Col>
              </Row>
            }
            activeKey={category}
            onChange={onChangeTabKey}
            onEdit={onChangeTabs}
          >
            {props.code[lang]?.length
              ? props.code[lang].split(';').map((item, i) => (
                  <Tabs.TabPane
                    key={i.toString()}
                    tab={
                      <Row
                        gutter={[8, 8]}
                        // onDragOver={() => {
                        //   dispatch({
                        //     type: BuilderAction.SET_TAB,
                        //     value: i.toString(),
                        //   });
                        // }}
                      >
                        <Col>
                          {editStep.key === i ? (
                            <Input
                              size="small"
                              value={editStep.value}
                              onChange={(e) => setEditStep({ value: e.target.value })}
                            />
                          ) : (
                            item
                          )}
                        </Col>
                        <Col>
                          {editStep.key === i ? (
                            <Button size="small" icon={<SaveOutlined />} onClick={onSaveStep} />
                          ) : (
                            <Button
                              size="small"
                              icon={<EditOutlined />}
                              onClick={() => setEditStep({ key: i, value: item })}
                            />
                          )}
                        </Col>
                      </Row>
                    }
                  >
                    {loading ? (
                      <Spin className="full-width" />
                    ) : content.length ? (
                      <Tree
                        showLine
                        blockNode
                        draggable
                        expandedKeys={keys.expanded}
                        onSelect={onNodeSelect}
                        onExpand={onExpand}
                        onDrop={onDrop}
                      >
                        {getTreeNode(content, i.toString())}
                      </Tree>
                    ) : (
                      <Empty
                        image={EmptyData}
                        description={<span>Drag & Drop elemente aici</span>}
                      />
                    )}
                  </Tabs.TabPane>
                ))
              : null}
          </Tabs>
        </>
      ) : loading ? (
        <Spin className="full-width" />
      ) : content.length ? (
        <Tree
          showLine
          blockNode
          draggable
          expandedKeys={keys.expanded}
          onSelect={onNodeSelect}
          onExpand={onExpand}
          onDrop={onDrop}
        >
          {getTreeNode(content)}
        </Tree>
      ) : (
        <Empty image={EmptyData} description={<span>Drag & Drop elemente aici</span>} />
      )}
    </div>,
  );
};

const spec = {
  drop(props, monitor) {
    const addItem = (Item) => {
      const {
        state: { content, keys },
        tabKey: category,
      } = props;

      content.push({
        ...Item,
        id: uid(),
        category,
        label: null,
        name: '',
        value: null,
        options: {},
      });

      props.onSubmit(content);
      props.dispatch({
        type: BuilderAction.SET_STATE,
        value: {
          keys: {
            expanded: keys.expanded,
            selected: [...keys.selected, (content.length - 1).toString()],
          },
        },
      });
    };

    addItem(monitor.getItem());
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

export default DropTarget('components', spec, collect)(Drop);
