import api from '.';
import { stringifyUrl } from 'utils';
import { ArrayResult, Log, Properties } from 'types';
import { ArticleTags, Tag } from './tags';

export enum Locale {
  EN = 'en',
  RO = 'ro',
  RU = 'ru',
}

export interface Content {
  title: string;
  content?: string;
  preview?: string;
  contact?: string;
  slug?: string;
}

export interface GalleryItem {
  url: string;
  caption?: string;
}

export type VersionType = 1 | 2;
// export type PersonType = VersionType;
export type StateType = VersionType | 3;

export interface Article {
  id: number;
  timestamp: string;
  edited_timestamp: string;
  publish_date: string;
  data: Properties;
  images: string[];
  videos: string[];
  gallery: Properties[];
  state: StateType;
  author: number;
  state_changed: string;
  order: number;
  background?: string | null;
  icon?: string;
  email_sent: boolean;
  parents: number[];
  parents_objects: Properties[];
  i18n: I18n;
  parent_id?: number;
  children: Article[];
  tags: Tag[] | ArticleTags[];
  children_objects: ChildrenObjects[];
}

export interface ChildrenObjects {
  id: number;
  i18n: I18n;
  tags: Tag[];
  order: number;
}

export interface I18n {
  ro: I18nLanguageArticle;
  en: I18nLanguageArticle;
  ru: I18nLanguageArticle;
}

export interface I18nLanguageArticle {
  contact: string | null;
  content: string;
  preview: string | null;
  slug: string;
  title: string;
}

export enum ArticleState {
  PUBLIC = 1,
  DRAFT = 2,
  INACTIVE = 3,
}

export enum LayoutVariant {
  DEFAULT = 'default',
  BANNER = 'banner',
  BANNER_INDENT = 'banner-indent',
  FULLSCREEN = 'fullscreen',
}

export const StateT: { [key in ArticleState]: string } = {
  [ArticleState.PUBLIC]: 'Public',
  [ArticleState.DRAFT]: 'Schiță',
  [ArticleState.INACTIVE]: 'Inactiv',
};

export default {
  all: async (params: any = {}) => {
    const query = new URLSearchParams({ page_size: 10, ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(stringifyUrl(`article/?${query.toString()}`, query, {}));

    return data;
  },
  bySlug: async (slug: string) => {
    const { data } = await api.request.get(`/v1/article/${slug}/`);

    return data;
  },
  upsert: async (article: Partial<Article>) => {
    const id = article.id ? `${article.id}/` : '';
    const method = id ? api.request.patch : api.request.post;

    const { data } = await method(`/v1/article/${id}`, article);

    return data;
  },
  uploadFile: async (file: File) => {
    const formData = new FormData();
    formData.append('upload', file);

    const { data } = await api.request.post('/v1/upload-file/?upload', formData);

    return data;
  },
  remove: async (id: number) => {
    const { data } = await api.request.delete(`/v1/article/${id}/`);

    return data;
  },
  getLogs: async (params: any = {}): Promise<ArrayResult<Log>> => {
    const query = new URLSearchParams({ page_size: '10', ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(`/v2/article/logs/?${query.toString()}`);

    return data;
  },
  getLog: async (id: number): Promise<Log> => {
    const { data } = await api.request.get(`/v2/article/logs/${id}/`);

    return data;
  },
};
