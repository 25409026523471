import api from '.';

export interface Subscribers {
  id: number;
  email: string;
}

export default {
  getSubscribers: async (params: any = {}) => {
    const query = new URLSearchParams({ page_size: 10, ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(`/v1/newsletter-email/?${query.toString()}`);

    return data;
  },
  removeSubscriber: async (id: number) => {
    await api.request.delete(`/v1/newsletter-email/${id}/`);
  },
};
