import React, { useState } from 'react';
import api, { Ii18n } from 'lib/api/i18n';
import { Card, Row, Col, Modal, Button, Spin } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import BackgroundForm from './BackgroundForm';

export interface ListProps {
  dictionary: Ii18n;
  update: (dictionar?: Ii18n) => void;
}

const List: React.FC<ListProps> = ({ dictionary, update }) => {
  const {
    i18n: { ro },
  } = dictionary;

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ visible: false, id: '' });

  const updateDictionary = async (urls: string | string[], id: string): Promise<void> => {
    setLoading(true);
    try {
      const i18n = JSON.parse(JSON.stringify(dictionary.i18n));
      const [image] = Array.isArray(urls) ? urls : [urls];

      i18n.ro[id] = image;
      i18n.ro = i18n.ru = i18n.en = JSON.stringify(i18n.ro);
      update(await api.update(`${dictionary.id}`, i18n));
    } catch (content) {
      Modal.error({ content });
    }
    setLoading(false);
  };

  const handleEdit = (id: string): void => setState({ visible: true, id });

  const handleRemove = async (id: string): Promise<void> => updateDictionary('', id);

  const cards: any[] = [];

  for (const key in ro) {
    cards.push(
      <Col key={key} span={10}>
        <Card
          cover={<img alt="example" src={ro[key] || 'https://via.placeholder.com/500x300'} />}
          actions={[
            <DeleteOutlined onClick={() => handleRemove(key)} key="delete" />,
            <EditOutlined onClick={() => handleEdit(key)} key="edit" />,
          ]}
        >
          <h2 style={{ textAlign: 'center' }}>{key}</h2>
        </Card>
      </Col>,
    );
  }

  const onCancel = (): void => setState({ visible: false, id: '' });

  const onSave = async (url?: string | string[]): Promise<void> => {
    if (url) {
      updateDictionary(url, state.id);
      onCancel();
    }
  };

  return (
    <Spin spinning={loading}>
      <Row justify="space-around">{cards}</Row>

      <Modal
        destroyOnClose
        width="80vw"
        style={{ maxWidth: '1000px' }}
        closable={false}
        visible={state.visible}
        onCancel={onCancel}
        footer={
          <>
            <Button onClick={onCancel}>Cancel</Button>

            <Button type="primary" htmlType="submit" form="BackgroundForm">
              Actualizează
            </Button>
          </>
        }
      >
        <BackgroundForm value={dictionary.i18n.ro[state.id]} onSave={onSave} />
      </Modal>
    </Spin>
  );
};

export default List;
