import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { FormsProps } from 'lib/api/forms';
import env from '@beam-australia/react-env';
import { defaultLocale } from 'lib/utils';

const REACT_APP_WEB_APP_URL = env('WEB_APP_URL');

interface FormActionsProps {
  type?: string;
  form: FormsProps;
  onRemove: (form: FormsProps) => void;
  onOpenDownloadForm: (form: FormsProps) => void;
  vizualization?: boolean;
}

/**
 * `ArticleActions` are used in `Article table`.
 * Containing 3 buttons: edit, remove and view.
 *
 * `Edit` button will redirect user to `/pages/edit/id`
 * `Delete` button will remove data and trigger refetch table content.
 * `View` button is a link to the article for preview.
 */
const FormActions: React.FC<FormActionsProps> = ({
  form,
  onRemove,
  onOpenDownloadForm,
  type = 'forms',
  vizualization = false,
}) => {
  const history = useHistory();

  return (
    <Dropdown.Button
      overlay={
        <Menu>
          {vizualization && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${REACT_APP_WEB_APP_URL}/${defaultLocale}/${form.id}`}
            >
              <Menu.Item key="1" icon={<EyeOutlined />}>
                Vizualizează
              </Menu.Item>
            </a>
          )}
          <Menu.Item key="2" icon={<DownloadOutlined />} onClick={() => onOpenDownloadForm(form)}>
            Descarcă
          </Menu.Item>
          <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(form)}>
            Șterge
          </Menu.Item>
        </Menu>
      }
      onClick={() => history.push(`/${type}/edit/${form.id}`)}
    >
      <EditOutlined /> Editează
    </Dropdown.Button>
  );
};

export default FormActions;
