import React from 'react';
import { Form } from 'antd';
import { Properties } from 'types';

import { IconSelect } from '../components';

export default ({ showIcon }: Properties) => {
  return (
    <>
      {showIcon && (
        <Form.Item name="icon" className="drop-tree__node-edit__col">
          <IconSelect size="small" />
        </Form.Item>
      )}
    </>
  );
};
