import React from 'react';
import { Form, Select } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';

export default ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, 'g']}
        initialValue={'4'}
        label="Distanța dintre elemente"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="0">Zero</Select.Option>
          <Select.Option value="1">Cel mai mic</Select.Option>
          <Select.Option value="2">Foarte mic</Select.Option>
          <Select.Option value="3">Mic</Select.Option>
          <Select.Option value="4">Mediu</Select.Option>
          <Select.Option value="5">Mare</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};
