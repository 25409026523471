import React from 'react';
import { Form, Select, Checkbox } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';
import { Link } from '../Custom';

export default ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, 'size']}
        initialValue="medium"
        label="Dimensiune"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="small">Mic</Select.Option>
          <Select.Option value="medium">Mediu</Select.Option>
          <Select.Option value="large">Mare</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'dataType']}
        initialValue="primary"
        label="Tip"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="text">Text</Select.Option>
          <Select.Option value="primary">Galben</Select.Option>
          <Select.Option value="light">Alb</Select.Option>
          <Select.Option value="fill">Umple</Select.Option>
          <Select.Option value="ghost">Frontieră</Select.Option>
          <Select.Option value="dark">Întuneric</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'block']}
        valuePropName="checked"
        label="Lățime completă"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  block: !item.block,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'round']}
        valuePropName="checked"
        label="Rotund"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  round: !item.round,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'action']}
        initialValue={item?.action}
        label="Acțiune"
        style={formItemStyle}
      >
        <Link lang={lang} />
      </Form.Item>
    </>
  );
};
