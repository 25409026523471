import { BuilderType, BuilderAction, BuilderReducerAction } from './types';

export const reducer = (state: BuilderType, action: BuilderReducerAction): BuilderType => {
  switch (action.type) {
    case BuilderAction.SET_LANG: {
      return { ...state, lang: action.value };
    }
    case BuilderAction.SET_LOADING: {
      return { ...state, loading: action.value };
    }
    case BuilderAction.SET_STATE: {
      return { ...state, state: { ...state.state, ...action.value } };
    }
    case BuilderAction.SET_TAB: {
      return { ...state, tabKey: action.value };
    }
  }
};
