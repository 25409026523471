import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Row, Col } from 'antd';
import { Languages } from 'lib/utils';
import { Properties } from 'types';

import { GenericFields } from '../Fields';
import { formItemLayout } from '../helpers';
import { loopChildren } from '../utils';

interface ItemFormProps {
  treeContent: Properties[];

  item: Properties;
  lang: Languages;
  showIcon?: boolean;

  onSaved: (values: Properties) => void;
}

const ItemForm: React.FC<ItemFormProps> = ({ showIcon, item, treeContent, onSaved }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...item.data });
  }, [item]);

  const onChange = useCallback(
    (values: Properties): void => {
      onSaved(
        loopChildren([...treeContent], item.id, (item) => ({
          ...item,
          data: { ...item.data, ...values },
        })),
      );
    },
    [item.id, treeContent, onSaved],
  );

  const props = useMemo(
    () => ({
      showIcon,
    }),
    [showIcon],
  );

  return (
    <div>
      <Form form={form} {...formItemLayout} colon={false} onValuesChange={onChange}>
        <GenericFields {...props} />

        <Row gutter={[16, 16]} justify="space-between">
          <Col span={24}>
            {(() => {
              switch (item?.type) {
                default:
                  return null;
              }
            })()}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ItemForm;
