import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { notification, Spin, message } from 'antd';
import { Form } from 'components/Forms';
import forms, { FormsProps } from 'lib/api/forms';

enum Operation {
  CREATE = 'create',
  EDIT = 'edit',
}

const EditFormsPage: React.FC = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: Operation }>();
  const [form, setForm] = useState<FormsProps>();

  useEffect(() => {
    if (id && tab === Operation.EDIT) {
      try {
        forms.getFormById(id).then((data) => setForm(data));
      } catch (e) {
        history.push('/forms-list');

        return notification.error({ message: 'Forma inexistenă' });
      }
    }
  }, [id, tab]);

  switch (tab) {
    case Operation.CREATE:
      return (
        <Form
          onSaved={() => {
            history.push('/forms-list');
            message.success('Formular a fost creat cu succes');
          }}
        />
      );
    case Operation.EDIT:
      return (
        <Spin spinning={!form} delay={300}>
          <Form
            initialForm={form}
            onSaved={() => message.success('Formular a fost salvat cu succes')}
          />
        </Spin>
      );
    default:
      return <Redirect to="/forms" />;
  }
};

export default EditFormsPage;
