import React, { FC, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Row, Col, Button, Dropdown, Menu, notification } from 'antd';
import Search from 'antd/lib/input/Search';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import deposit, { NomenclaturesDeposits } from 'lib/api/deposit';
import { ArrayResult } from 'lib/api';

const DepositList: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<ArrayResult<NomenclaturesDeposits>>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;

  const fetchDeposits = useCallback(async () => {
    try {
      setData(
        await deposit.getDeposits({
          ...params,
          search,
          page,
        }),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [page, search]);

  const onRemove = async ({ id }: NomenclaturesDeposits): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest articol?',
      onOk: async () => {
        try {
          await deposit.removeDeposit(id as number);
          fetchDeposits();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const onSearchHandler = (): void => {
    history.push({
      pathname: '/deposit',
      search: '?' + new URLSearchParams({ search: searchValue }).toString(),
    });
  };

  useEffect(() => {
    fetchDeposits();
  }, [fetchDeposits]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<NomenclaturesDeposits>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      dataIndex: 'title',
    },
    {
      title: 'Data creării',
      render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Ratele',
      render: ({ currencies }) =>
        currencies.map((el) => {
          const rate = el.rates.map(({ rate }) => Number(rate).toFixed(2)).join('/');

          return (
            <p>
              {rate} {el.currency.code}
            </p>
          );
        }),
    },

    {
      title: 'Acțiuni',
      width: 100,
      align: 'right',
      render: (deposit) => {
        return (
          <>
            <Dropdown.Button
              overlay={
                <Menu>
                  <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(deposit)}>
                    Șterge
                  </Menu.Item>
                </Menu>
              }
              onClick={() => history.push(`/deposit/edit/${deposit.id}`)}
            >
              <EditOutlined /> Editează
            </Dropdown.Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Search
            enterButton="Caută"
            placeholder="Caută credit după titlu"
            size="large"
            value={searchValue}
            onSearch={onSearchHandler}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Col>
        <Col>
          <Button type="primary" size="large" onClick={() => history.push('/deposit/create')}>
            Creează deposit
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />
    </>
  );
};

export default DepositList;
