import React, { useEffect } from 'react';
import { Card, Form, Row, Col, Input, Button, Modal, notification } from 'antd';
import profile from 'lib/api/profile';
import { User } from 'types';

const Profile = () => {
  const [form] = Form.useForm();

  const fetchProfile = async () => {
    const data = await profile.getProfile();
    form.setFieldsValue(data);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleUpdateProfile = (user: User): void => {
    Modal.confirm({
      title: `Doriți să editați profilul?`,
      onOk: async () => {
        try {
          await profile.patchProfile(user);
          fetchProfile();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };
  return (
    <Row>
      <Col xs={24} md={16} xl={12}>
        <Card>
          <Form form={form} onFinish={handleUpdateProfile} layout="vertical">
            <Form.Item label="Email" name="username">
              <Input placeholder="Email" disabled />
            </Form.Item>

            <Form.Item name="first_name" label="Prenume">
              <Input placeholder="Prenume" />
            </Form.Item>

            <Form.Item name="last_name" label="Nume">
              <Input placeholder="Nume" />
            </Form.Item>

            <Row justify="end">
              <Col>
                <Button type="primary" htmlType="submit">
                  Salvează
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Profile;
