import env from '@beam-australia/react-env';
import React from 'react';
import { Form, Input, Button, Card, Modal, Row, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import api from 'lib/api';
import { loginPath } from 'lib/router';
import { useHistory } from 'react-router-dom';
import { Properties } from 'types';

const { auth } = api;

const baseUrl = env('BASE_DASH_URL') || window.location.origin;

const ForgotPassowrdForm: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const handleSubmit = async (values: Properties): Promise<void> => {
    const success = await auth.resetByEmail({ ...values, url: `${baseUrl}/recovery-password` });

    if (success) {
      Modal.success({
        title: 'Un mesaj a fost trimis pe adresa specificată.',
        content: 'Accesați link-ul primit pentru a va reseta parola.',
        onOk: () => {
          history.push(loginPath);
        },
      });
    } else {
      Modal.error({
        title: 'Eroare. Încercați din nou!',
        onOk: () => {
          form.resetFields();
        },
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <Card title="Introduceți e-mailul dvs. mai jos">
        <Form
          onFinish={handleSubmit}
          style={{ width: 300, maxWidth: '90%', height: 'fit-content' }}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Introduceți e-mailul!' },
              { type: 'email', message: 'E-mail invalid!' },
            ]}
          >
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="E-mail"
            />
          </Form.Item>

          <Row justify="space-between">
            <Col>
              <Button onClick={() => history.push(loginPath)}>Anulare</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" className="login-form__button">
                Trimite link
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassowrdForm;
