import { ValidationRule } from 'antd/lib/form';

type Fields = 'title' | 'content' | 'state' | 'order';

export const fieldRules: { [key in Fields]: ValidationRule[] } = {
  title: [
    {
      required: true,
      message: 'Titlu este necesar.',
    },
  ],
  content: [
    {
      required: true,
      message: 'Contentul este necesar.',
    },
  ],
  state: [
    {
      required: true,
      message: 'Statutul este necesar.',
    },
  ],
  order: [
    {
      required: true,
      message: 'Nr. de ordine este necesar.',
    },
  ],
};
