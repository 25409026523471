import api from '.';

export interface OpenStreet {
  boundingbox?: string[];
  class?: string;
  display_name?: string;
  icon?: string;
  importance?: number;
  lat: string;
  licence?: string;
  lon: string;
  osm_id?: number;
  osm_type?: string;
  place_id?: number;
  type?: string;
}

export default {
  get: async (value: string, limit?: number): Promise<OpenStreet[]> => {
    const { data } = await api.request.get(
      `https://nominatim.openstreetmap.org/search?format=json&limit=${
        limit || 10
      }&polygon=1&accept-language=ro&addressdetails=1&q=${value}&countrycodes=md&limit=50`,
    );

    return data;
  },
  reverse: async ([lat, lng]: [number, number]): Promise<OpenStreet> => {
    const { data } = await api.request.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&limit=1&lat=${lat}&lon=${lng}`,
    );

    return data;
  },
};
