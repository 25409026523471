import { Properties } from 'types';

export type BuilderDragType = {
  type?: string;
  is: boolean;
};

export type BuilderKeysType = {
  expanded: string[];
  selected: string[];
};

export type BuilderStateType = {
  content: Properties[];
  drag: BuilderDragType;
  keys: BuilderKeysType;
  isFullscreen: boolean;
};

export interface BuilderType {
  lang: string;
  loading: boolean;
  state: BuilderStateType;
  tabKey?: string;
}

export enum BuilderAction {
  SET_LANG = 'SET_LANG',
  SET_LOADING = 'SET_LOADING',
  SET_STATE = 'SET_STATE',
  SET_TAB = 'SET_TAB',
}

export type BuilderReducerAction =
  | {
      type: BuilderAction.SET_LANG;
      value: string;
    }
  | {
      type: BuilderAction.SET_LOADING;
      value: boolean;
    }
  | {
      type: BuilderAction.SET_STATE;
      value: Partial<BuilderStateType>;
    }
  | {
      type: BuilderAction.SET_TAB;
      value?: string;
    };
