import React, { FC, useReducer, useEffect, useCallback } from 'react';
import { loopAll } from 'components/Forms/Builder/components';
import classifiers, { NomenclaturesClassifiers } from 'lib/api/classifiers';
import { ArrayResult } from 'types';

import { reducer } from './reducer';
import { BuilderContext, initialState } from './context';
import { BuilderAction } from './types';

export const BuilderProvider: FC = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, initialState);
  const [dataClassifiers, setDataClassifiers] =
    React.useState<ArrayResult<NomenclaturesClassifiers>>();

  const fetchClassifiers = React.useCallback(async () => {
    try {
      setDataClassifiers(await classifiers.getClassifiers());
    } catch (e: any) {
      console.error(e);
    }
  }, []);

  React.useEffect(() => {
    fetchClassifiers();
  }, [fetchClassifiers]);

  const onEsc = useCallback(() => {
    dispatch({ type: BuilderAction.SET_STATE, value: { isFullscreen: false } });
  }, []);

  const onExpandAll = useCallback(() => {
    const expanded: string[] = [];

    loopAll(store.state.content, (_, i) => expanded.push(i.toString()));

    dispatch({
      type: BuilderAction.SET_STATE,
      value: {
        keys: {
          selected: store.state.keys.selected,
          expanded,
        },
      },
    });
  }, [store]);

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false);

    return () => {
      document.removeEventListener('keydown', onEsc, false);
    };
  }, []);

  const value = {
    ...store,
    dataClassifiers,
    onEsc,
    onExpandAll,
    dispatch,
  };

  return <BuilderContext.Provider value={value}>{children}</BuilderContext.Provider>;
};
