import * as React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, Col, Modal, Row, Select, Table, Tabs, Space, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Actions } from 'components/Actions';
import { NomenclaturesATMForm } from 'components/ATM';
import nomenclatures, { NomenclaturesATM, NomenclaturesType } from 'lib/api/nomenclatures';
import groups, { NomenclatureGroup } from 'lib/api/groups';
import { ArrayResult } from 'types';

const { TabPane } = Tabs;

import './style.css';

const ATMPage: React.FC = () => {
  const history = useHistory();
  const [pageATM, setPageATM] = React.useState(1);
  const [pageOffice, setPageOffice] = React.useState(1);
  const [dataAtm, setDataAtm] = React.useState<ArrayResult<NomenclaturesATM>>();
  const [dataOffice, setDataOffice] = React.useState<ArrayResult<NomenclaturesATM>>();
  const [selectedItem, setSelectedItem] = React.useState<NomenclaturesATM>();
  const [openForm, setOpenForm] = React.useState(false);
  const [tab, setTab] = React.useState<NomenclaturesType>(NomenclaturesType.SUBSIDIARY);
  const [groupList, setGroupList] = React.useState<ArrayResult<NomenclatureGroup>>();
  const [group, setGroup] = React.useState<number>();
  const pageSize = 10;

  const fetchAtm = React.useCallback(async () => {
    try {
      setDataAtm(await nomenclatures.getAtm({ limit: pageSize, page: pageATM, group }));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [pageATM, group]);

  const fetchSubsidiaries = React.useCallback(async () => {
    try {
      setDataOffice(await nomenclatures.getBranches({ limit: pageSize, page: pageOffice, group }));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [pageOffice, group]);

  const fetchGroups = React.useCallback(async () => {
    try {
      setGroupList(await groups.getGroupsATMList({ page: 1, limit: 100 }));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, []);

  React.useEffect(() => {
    fetchAtm();
  }, [pageATM, fetchAtm]);

  React.useEffect(() => {
    fetchSubsidiaries();
  }, [pageOffice, fetchSubsidiaries]);

  React.useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const handleRemove = (id: number) => {
    Modal.confirm({
      title: 'Doriți să ștergeți acestă locație?',
      onOk: async () => {
        try {
          if (tab === NomenclaturesType.SUBSIDIARY) {
            await nomenclatures.removeBranch(id);
            setPageOffice(1);
            fetchSubsidiaries();
          } else {
            await nomenclatures.removeAtm(id);
            setPageATM(1);
            fetchAtm();
          }
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const handleCreate = () => {
    setOpenForm(true);
  };

  const handleEdit = (id: number) => {
    if (tab === NomenclaturesType.SUBSIDIARY) {
      setSelectedItem(dataOffice?.results.find((item) => item.id === id));
    } else {
      setSelectedItem(dataAtm?.results.find((item) => item.id === id));
    }
    setOpenForm(true);
  };

  const columns: ColumnProps<NomenclaturesATM>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      dataIndex: 'title',
    },
    {
      title: 'Adresă',
      dataIndex: 'address',
    },
    {
      title: 'Grupul',
      dataIndex: 'group',
      render: (group) => group?.title,
    },
    {
      title: 'Poziția în grup',
      dataIndex: 'code',
    },
    {
      width: 175,
      title: 'Data publicarii',
      render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Acțiuni',
      width: 100,
      align: 'right',
      render: (item) => {
        return <Actions id={item.id} onEdit={handleEdit} onRemove={handleRemove} />;
      },
    },
  ];

  const handleOnSave = () => {
    handleCloseForm();
    fetchAtm();
    fetchSubsidiaries();
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedItem(undefined);
  };

  return (
    <>
      <Row gutter={10} className="atm-office">
        <Col span={24} className="atm-office__position">
          <Space>
            <Select
              size="large"
              className="atm-office__select"
              placeholder="Filtrați după grup"
              value={group}
              onChange={(group) => setGroup(group)}
              allowClear
            >
              {groupList?.results?.map((group) => (
                <Select.Option key={group.id} value={group.id}>
                  {group?.title}
                </Select.Option>
              ))}
            </Select>
            <Button type="primary" size="large" onClick={() => history.push('atm/groups')}>
              Grupuri
            </Button>
            <Button type="primary" size="large" onClick={handleCreate}>
              Creează articol
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Tabs
            defaultActiveKey={tab}
            onChange={(val) => setTab(val as NomenclaturesType)}
            type="card"
          >
            <TabPane tab="Oficii" key={NomenclaturesType.SUBSIDIARY}>
              <Table
                columns={columns}
                dataSource={dataOffice ? dataOffice.results : []}
                rowKey="id"
                childrenColumnName=""
                scroll={{ x: 1400 }}
                pagination={{
                  hideOnSinglePage: true,
                  current: pageOffice,
                  pageSize: pageSize,
                  total: dataOffice?.count,
                }}
                onChange={({ current }) => setPageOffice(current as number)}
              />
            </TabPane>
            <TabPane tab="ATM" key={NomenclaturesType.ATM}>
              <Table
                columns={columns}
                dataSource={dataAtm ? dataAtm.results : []}
                rowKey="id"
                childrenColumnName=""
                scroll={{ x: 1400 }}
                pagination={{
                  hideOnSinglePage: true,
                  current: pageATM,
                  pageSize: 25,
                  total: dataAtm?.count,
                }}
                onChange={({ current }) => setPageATM(current as number)}
              />
            </TabPane>
          </Tabs>
        </Col>
        {openForm && (
          <Modal
            title="Modal"
            visible={openForm}
            width="70%"
            okButtonProps={{ htmlType: 'submit', form: 'NomenclaturesATMForm' }}
            onCancel={handleCloseForm}
          >
            <NomenclaturesATMForm tab={tab} onSaved={handleOnSave} initialValue={selectedItem} />
          </Modal>
        )}
      </Row>
    </>
  );
};

export default ATMPage;
