import React, { FC, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import forms, { FormsProps } from 'lib/api/forms';
import { Properties } from 'types';
import Search from 'antd/lib/input/Search';
import { Table, Modal, Row, Col, Dropdown, Menu, List, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { DeleteOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { downloadBlobFile } from 'utils/downloadBlobFile';

const FormResults: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [edit, setEdit] = useState<Properties>();
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<Properties>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;

  const fetchForms = useCallback(async () => {
    try {
      setData(
        await forms.getFormSubmits({
          search,
          page,
        }),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [search, page]);

  const onRemove = async ({ id }: Properties): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți această formă?',
      onOk: async () => {
        try {
          await forms.removeFormSubmit(id);
          fetchForms();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const onDownload = async ({ id, ...fields }: Properties): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să descărcați această formă?',
      onOk: async () => {
        try {
          const response = await forms.downloadForm(id);
          const lastName =
            fields.data['last_name'] || fields.data['Prenume'] || fields.data['Prenumele']
              ? `${fields.data['last_name'] || fields.data['Prenume'] || fields.data['Prenumele']}_`
              : '';

          const fileName = `${
            fields.data['first_name'] ||
            fields.data['Nume Prenume'] ||
            fields.data['Nume'] ||
            fields.data['Numele'] ||
            fields.data['Numele/Prenumele administratorului']
          }_${lastName}${moment(fields.timestamp).format('DD_MM_YYYY_HH_mm')}.pdf`;

          downloadBlobFile(response.data, fileName, 'application/pdf');
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const onSearchHandler = (): void => {
    history.push({
      pathname: '/forms',
      search: '?' + new URLSearchParams({ search: searchValue }).toString(),
    });
  };

  useEffect(() => {
    fetchForms();
  }, [fetchForms]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<FormsProps>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nume Prenume',
      render: ({ data }) =>
        data['first_name'] ||
        data['last_name'] ||
        data['Nume'] ||
        data['Prenume'] ||
        data['Numele'] ||
        data['Prenumele'] ||
        data['Nume Prenume'] ||
        data['Numele/Prenumele administratorului']
          ? `${
              data['first_name'] ||
              data['Nume Prenume'] ||
              data['Nume'] ||
              data['Numele'] ||
              data['Numele/Prenumele administratorului']
            } ${
              data['last_name'] || data['Prenume'] || data['Prenumele']
                ? data['last_name'] || data['Prenume'] || data['Prenumele']
                : ''
            }`
          : '-',
    },
    {
      title: 'Email',
      render: ({ data }) =>
        data['E-mail'] || data['Email'] || data['e-mail 1'] || data['e-mail 2'] || '-',
    },
    {
      title: 'Sucursala',
      render: ({ data }) => data['Sucursala'] || '-',
    },
    {
      title: 'Suma creditului',
      render: ({ data }) =>
        data['Suma creditului']
          ? `${data['Suma creditului']} ${data['Valuta'] ? data['Valuta'] : ''}`
          : '-',
    },
    {
      title: 'Referință',
      render: ({ form, data }) =>
        form?.i18n?.ro?.title
          ? `${form?.i18n?.ro?.title} ${
              data['Suma creditului']
                ? `${data['Suma creditului']} ${data['Valuta'] ? data['Valuta'] : ''} `
                : ''
            }`
          : '-',
    },

    {
      width: 175,
      title: 'Data creării',
      render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Acțiuni',
      width: 100,
      align: 'right',
      render: (form) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu>
                <Menu.Item key="2" icon={<DownloadOutlined />} onClick={() => onDownload(form)}>
                  Descarcă
                </Menu.Item>
                <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(form)}>
                  Șterge
                </Menu.Item>
              </Menu>
            }
            onClick={() => setEdit(form.data)}
          >
            <EyeOutlined /> Vizualizează
          </Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Row gutter={10} justify="end" align="middle">
            <Col>
              <Search
                enterButton="Caută"
                placeholder="Caută forma după titlu"
                size="large"
                value={searchValue}
                onSearch={onSearchHandler}
                onChange={({ target: { value } }) => setSearchValue(value)}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col>
          <Button type="primary" size="large" onClick={() => history.push('/forms/create')}>
            Creează formă
          </Button>
        </Col> */}
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />

      <Modal
        title="Date trimise"
        visible={!!edit}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        onCancel={() => setEdit(undefined)}
        width={800}
        footer={false}
      >
        <List>
          {edit &&
            Object.keys(edit).map((key) => (
              <List.Item key={key}>
                <Row justify="space-between" className="full-width">
                  <Col>
                    <b>{key}</b>
                  </Col>
                  <Col>{edit[key] || '-'}</Col>
                </Row>
              </List.Item>
            ))}
        </List>
      </Modal>
    </>
  );
};

export default FormResults;
