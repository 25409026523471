export const loop = (data, index, callback) => {
  for (let i = 0; i < data.length; i++) {
    if (i.toString() === index) {
      return callback(data[i], i, data);
    }
    if (data[i].children) {
      loop(data[i].children, index, callback);
    }
  }
};

export const loopAll = (data, callback) => {
  for (let i = 0; i < data.length; i++) {
    callback(data[i], i, data);

    if (data[i].children) {
      loopAll(data[i].children, callback);
    }
  }
};
