import React, { FC, useState, useCallback, useEffect } from 'react';
import { Upload, Row, Col, Button, Form, Card, Modal, Divider, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import attachments, { Attachment } from 'lib/api/attachments';
import { ValidationError } from 'errors';
import { ArrayResult } from 'types';

const OnlineBanking: FC = () => {
  const [state, setState] = useState<Attachment[]>([]);

  const fetchAttachments = useCallback(async () => {
    try {
      const data: ArrayResult<Attachment> = await attachments.getAttachments();

      setState([...data.results]);
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, []);

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  const handleUpload = useCallback(async (file: any): Promise<void> => {
    const [error] = await attachments.postAttachments(file);

    if (!error) {
      fetchAttachments();
    } else {
      if (error instanceof ValidationError) {
        Modal.error({
          title: 'Ceva nu a mers bine.',
          content: error?.fields[0]?.errors,
        });
      } else {
        Modal.error({
          title: 'Procesul a eșuat.',
          content: error,
        });
      }
    }
  }, []);

  const handleBeforeUpload = (file: any): boolean => {
    handleUpload(file);

    return false;
  };

  const handleDelete = useCallback((id: number) => {
    if (!id) {
      return;
    }

    try {
      Modal.confirm({
        title: 'Doriți să ștergeți definitiv acest fişier?',
        okText: 'Șterge',
        cancelText: 'Anulează',
        onOk: async () => {
          await attachments.removeAttachment(id);
          fetchAttachments();
        },
      });
    } catch (e) {
      Modal.error({ title: e.message });
    }
  }, []);

  return (
    <Row>
      <Col xs={24} md={16} xl={12}>
        <Card>
          <Form>
            <Upload
              accept=".txt, .csv, .pdf"
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
            >
              <Button>
                Faceți clic pentru a încărca fişier <UploadOutlined />
              </Button>
            </Upload>

            {state?.length
              ? state.map((i) => (
                  <Row key={`key--${i.id}`}>
                    <Col xs={24}>
                      <Divider />
                    </Col>
                    <Col xs={24} md={21}>
                      <p>{i.title}</p>
                    </Col>
                    <Col xs={24} md={3}>
                      <Button onClick={() => handleDelete(i.id as number)}>Șterge</Button>
                    </Col>
                  </Row>
                ))
              : null}
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default OnlineBanking;
