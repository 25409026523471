import React, { useCallback, useEffect, useState } from 'react';
import { Form, Tabs, Modal, Input, Button, Row, Col, Radio, notification } from 'antd';
import { languages, i18n, Languages, isLanguage } from 'lib/utils';
import jobs, { Vacancy } from 'lib/api/jobs';
import { ArticleEditor } from 'components/Articles';
import { fieldRules } from './utils';
import { ArticleState } from 'lib/api/pages';
import { Properties } from 'types';

const { TabPane } = Tabs;

//const fields = ['title', 'content'];

interface VacanciesModalProps {
  id?: number;
  onClose: () => void;
  onSuccess: () => void;
}

const VacanciesModal: React.FC<VacanciesModalProps> = ({ onClose, onSuccess, id }) => {
  const [data, setData] = useState<Vacancy>();
  const [activeTabKey, setActiveTabKey] = useState<Languages>('ro');

  const fetch = useCallback(async () => {
    try {
      if (id) {
        setData(await jobs.byId(id));
      }
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const onChange = (key: string): void => {
    if (isLanguage(key)) setActiveTabKey(key);
  };

  const handleSubmit = async (values: Properties): Promise<void> => {
    try {
      await jobs.upsert({ id, ...values } as Vacancy);

      onSuccess();
      onClose();

      Modal.success({ title: 'Modificările au fost cu succes.' });
    } catch (e) {
      Modal.error({
        title: `${values.id ? 'Editarea' : 'Crearea'} postului vacant a eșuat.`,
        content: e.message,
      });
    }
    // for (const key of languages) {
    //   let valid = true;

    //   fields.forEach((field) => {
    //     if (!values['i18n'][key][field] || !values['i18n'][key][field].length) {
    //       valid = false;
    //     }
    //   });

    //   if (!valid) {
    //     setActiveTabKey(key);
    //     break;
    //   }
    // }
  };

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>

          <Button type="primary" htmlType="submit" form="vacancy">
            Save
          </Button>
        </>
      }
      destroyOnClose
      width="60vw"
    >
      <Form id="vacancy" onFinish={handleSubmit}>
        <Tabs className="custom-tabs" animated={false} activeKey={activeTabKey} onChange={onChange}>
          {languages.map((language) => {
            const source = data?.i18n && data.i18n[language];
            const { title, content } = source || {};

            return (
              <TabPane tab={i18n.language[language]} key={language}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Titlu"
                      name={['i18n', language, 'title']}
                      initialValue={title}
                      rules={fieldRules.title}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item label="Statut" name="state" rules={fieldRules.state}>
                      <Radio.Group>
                        <Radio value={ArticleState.PUBLIC}>Publicat</Radio>
                        <Radio value={ArticleState.DRAFT}>Schiță</Radio>
                        <Radio value={ArticleState.INACTIVE}>Inactiv</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={18}>
                    <Form.Item
                      label="Order"
                      name="order"
                      initialValue={data?.order}
                      rules={fieldRules.order}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Descriere"
                      name={['i18n', language, 'content']}
                      initialValue={content}
                      rules={fieldRules.content}
                    >
                      <ArticleEditor />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </Tabs>
      </Form>
    </Modal>
  );
};

export default VacanciesModal;
