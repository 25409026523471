import api from '.';
import { stringifyUrl } from 'utils/query';
import { NonFieldsError, FieldsValidationError } from './utils';
import { EnvApi, Properties } from 'types';
import { NomenclatureGroup } from './groups';

export enum NomenclaturesType {
  ATM = 'atm',
  SUBSIDIARY = 'subsidiary',
}
export interface NomenclaturesATM {
  id: number;
  timestamp: string;
  edited_timestamp: string;
  code: string;
  title: string;
  description: string;
  slug: string;
  number: string;
  address: string;
  location: string;
  work_hours: string;
  long: string;
  lat: string;
  data: Properties;
  contact_email?: string;
  group?: NomenclatureGroup;
}

export interface NomenclatureCurrency {
  id?: number;
  code: string;
}

export interface NomenclatureShortURL {
  id?: number;
  slug: string;
  redirect_to: string;
  redirect_from: string;
}

export default {
  getAtm: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(`nomenclatures/atms`, params, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },
  upsertAtm: async (atm: NomenclaturesATM) => {
    let error, data;

    try {
      const { id, ...values } = atm;

      if (id) {
        ({ data } = await api.request.patch(
          stringifyUrl(`nomenclatures/atms/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
          values,
        ));
      } else {
        ({ data } = await api.request.post(
          stringifyUrl(`nomenclatures/atms`, {}, { api: EnvApi.API_MAIN_URL }),
          atm,
        ));
      }
    } catch (e) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, atm);
      } else {
        error = message;
      }
    }

    return [error, data];
  },

  removeAtm: async (id: number) => {
    await api.request.delete(
      stringifyUrl(`nomenclatures/atms/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );
  },
  getBranches: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(`nomenclatures/branches`, params, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },
  upsertBranch: async (atm: NomenclaturesATM) => {
    let error, data;

    try {
      const { id, ...values } = atm;

      if (id) {
        ({ data } = await api.request.patch(
          stringifyUrl(`nomenclatures/branches/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
          values,
        ));
      } else {
        ({ data } = await api.request.post(
          stringifyUrl(`nomenclatures/branches`, {}, { api: EnvApi.API_MAIN_URL }),
          atm,
        ));
      }
    } catch (e) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, atm);
      } else {
        error = message;
      }
    }

    return [error, data];
  },
  removeBranch: async (id: number) => {
    await api.request.delete(
      stringifyUrl(`nomenclatures/branches/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );
  },

  getCurrencies: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(`nomenclatures/currencies`, params, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },

  getShortUrlList: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl('nomenclatures/short-url', params, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },

  getShortUrlById: async (id: number) => {
    const { data } = await api.request.get(
      stringifyUrl(
        `nomenclatures/short-url/${id}`,
        {},
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  createShortUrl: async (values: NomenclatureShortURL) => {
    const { data } = await api.request.post(
      stringifyUrl('nomenclatures/short-url', {}, { api: EnvApi.API_MAIN_URL }),
      values,
      {
        headers: {
          'Accept-Language': 'ro',
        },
      },
    );

    return data;
  },

  updateShortUrl: async ({ id, ...values }: NomenclatureShortURL) => {
    const { data } = await api.request.patch(
      stringifyUrl(`nomenclatures/short-url/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
      values,
      {
        headers: {
          'Accept-Language': 'ro',
        },
      },
    );
    return data;
  },

  removeShortUrl: async (id: number) => {
    await api.request.delete(
      stringifyUrl(`nomenclatures/short-url/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );
  },
};
