import * as React from 'react';
import { useSetState } from 'react-use';
import {
  Form,
  Row,
  Col,
  Input,
  Modal,
  Checkbox,
  Radio,
  Select,
  InputNumber,
  notification,
} from 'antd';
import nomenclatures, { NomenclaturesATM, NomenclaturesType } from 'lib/api/nomenclatures';
import groups, { NomenclatureGroup } from 'lib/api/groups';
import { ArrayResult } from 'lib/api';
import { Properties } from 'types';
import MapPicker from './MapPicker';

const weekDay: { [key: string]: string } = {
  monday: 'Luni',
  tuesday: 'Marţi',
  wednesday: 'Miercuri',
  thursday: 'Joi',
  friday: 'Vineri',
  saturday: 'Sâmbătă',
  sunday: 'Duminică',
};

const formItemStyle = {
  marginBottom: 0,
  lineHeight: 'initial',
};

interface Props {
  tab: NomenclaturesType;
  initialValue?: NomenclaturesATM;
  onSaved: () => void;
}

const NomenclaturesATMForm: React.FC<Props> = ({ tab, onSaved, initialValue }) => {
  const [form] = Form.useForm();
  const [location, setLocation] = React.useState<[number, number]>();
  const [workHours, setWorkHours] = useSetState<Properties>({
    monday: {},
    tuesday: {},
    wednesday: {},
    thursday: {},
    friday: {},
    saturday: {},
    sunday: {},
  });
  const [lunchBreak, setLunchBreak] = useSetState<Properties>({});
  const [type, setType] = React.useState<string>(tab);
  const [groupList, setGroupList] = React.useState<ArrayResult<NomenclatureGroup>>();

  const fetchGroups = React.useCallback(async () => {
    try {
      setGroupList(await groups.getGroupsATMList({ page: 1, limit: 100 }));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, []);

  React.useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  React.useEffect(() => {
    if (initialValue) {
      form.setFieldsValue({
        ...initialValue,
        group: initialValue.group?.id,
      });

      if (initialValue.data.work_hours) {
        setWorkHours(initialValue.data.work_hours);
      }
      if (initialValue.data.lunch_break) {
        setLunchBreak(initialValue.data.lunch_break);
      }
      if (initialValue.data.type) {
        setType(initialValue.data.type);
      }
    }
  }, [initialValue]);

  const handleSubmit = async (values: Properties): Promise<void> => {
    const [lat, long] =
      location ||
      (initialValue?.lat && initialValue?.long
        ? [parseFloat(initialValue.lat), parseFloat(initialValue.long)]
        : []);

    try {
      if (values.data.type === NomenclaturesType.ATM) {
        await nomenclatures.upsertAtm({
          ...(initialValue?.id && { id: initialValue?.id }),
          ...values,
          lat: lat?.toFixed(6),
          long: long?.toFixed(6),
        } as NomenclaturesATM);
      } else {
        await nomenclatures.upsertBranch({
          ...(initialValue?.id && { id: initialValue?.id }),
          ...values,
          lat: lat?.toFixed(6),
          long: long?.toFixed(6),
        } as NomenclaturesATM);
      }
      onSaved();
    } catch (e) {
      const { message = 'Eroare. Locația nu putut fi salvat!' } = e;

      Modal.error({ content: message });
    }
  };

  const handleChooseLocation = (coordinates: [number, number]): void => {
    setLocation(coordinates);
  };

  const onValuesChange = (values) => {
    if (values.data?.work_hours) {
      Object.keys(values.data.work_hours).forEach((day) => {
        setWorkHours((prevState) => ({
          [day]: { ...prevState[day], ...values.data.work_hours[day] },
        }));
      });
    } else if (values.data?.lunch_break) {
      setLunchBreak(values.data.lunch_break);
    } else if (values.data?.type) {
      setType(values.data?.type);
    }
  };

  return (
    <Form
      id="NomenclaturesATMForm"
      form={form}
      onValuesChange={onValuesChange}
      onFinish={handleSubmit}
    >
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={15}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Tip"
                name={['data', 'type']}
                initialValue={initialValue?.data?.type || tab}
                rules={[
                  {
                    required: true,
                    message: 'Tipul este necesar',
                  },
                ]}
                style={formItemStyle}
              >
                <Radio.Group disabled={!!initialValue?.id}>
                  <Radio value={NomenclaturesType.SUBSIDIARY}>Oficiu</Radio>
                  <Radio value={NomenclaturesType.ATM}>ATM</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Titlu"
                name="title"
                initialValue={initialValue?.title}
                rules={[
                  {
                    required: true,
                    message: 'Titlul este necesar',
                  },
                ]}
                style={formItemStyle}
              >
                <Input placeholder={`Introduceți titlul`} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Numarul de telefon"
                name={['data', 'phone_number']}
                initialValue={initialValue?.data?.phone_number}
                style={formItemStyle}
              >
                <Input placeholder={'Introduceți numarul de telefon'} />
              </Form.Item>
            </Col>

            {type === NomenclaturesType.SUBSIDIARY && (
              <Col span={24}>
                <Form.Item
                  label="Email pentru contact"
                  name="contact_email"
                  initialValue={initialValue?.contact_email}
                  style={formItemStyle}
                >
                  <Input placeholder={`Introduceți email pentru contact`} />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item label="Grupul" name="group" style={formItemStyle}>
                <Select style={{ width: '100%' }}>
                  {groupList?.results?.map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                      {group?.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Poziția în grup"
                name="code"
                initialValue={initialValue?.code}
                style={formItemStyle}
              >
                <InputNumber
                  min={1}
                  placeholder="Introduceți poziția în grup"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Adresa"
                name="address"
                initialValue={initialValue?.address}
                rules={[
                  {
                    required: true,
                    message: 'Adresa este necesara',
                  },
                ]}
                style={formItemStyle}
              >
                <Input placeholder={`Introduceți adresa`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <MapPicker
                onSet={handleChooseLocation}
                initialMarker={
                  initialValue?.lat && initialValue.long
                    ? {
                        lat: parseFloat(initialValue.lat),
                        lng: parseFloat(initialValue.long),
                      }
                    : undefined
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={9} className="work-hours">
          {Object.keys(weekDay).map((day) => (
            <Row key={day} gutter={[16, 16]} className="work-hours__top-border">
              <Col span={12}>
                <Form.Item
                  className="no-margin"
                  name={['data', 'work_hours', day, 'day']}
                  initialValue={initialValue?.data?.work_hours?.[day]?.day}
                  valuePropName="checked"
                >
                  <Checkbox>{weekDay?.[day]}</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="no-margin"
                  name={['data', 'work_hours', day, 'all_day']}
                  initialValue={initialValue?.data?.work_hours?.[day]?.all_day}
                  valuePropName="checked"
                >
                  <Checkbox disabled={!workHours?.[day]?.day}>All Day</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['data', 'work_hours', day, 'from']}
                  initialValue={initialValue?.data?.work_hours?.[day]?.from}
                >
                  <Input
                    addonBefore="de la"
                    disabled={!workHours?.[day]?.day || workHours?.[day]?.all_day}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['data', 'work_hours', day, 'to']}
                  initialValue={initialValue?.data?.work_hours?.[day]?.to}
                >
                  <Input
                    addonBefore="până la"
                    disabled={!workHours?.[day]?.day || workHours?.[day]?.all_day}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
          {type === NomenclaturesType.SUBSIDIARY && (
            <Row className="work-hours__top-border">
              <Col span={24}>
                <Form.Item
                  className="no-margin"
                  name={['data', 'lunch_break', 'lunch']}
                  initialValue={initialValue?.data?.lunch_break?.lunch}
                  valuePropName="checked"
                >
                  <Checkbox>Pauza de masa</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['data', 'lunch_break', 'from']}
                  initialValue={initialValue?.data?.lunch_break?.from}
                >
                  <Input addonBefore="de la" disabled={!lunchBreak?.lunch} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['data', 'lunch_break', 'to']}
                  initialValue={initialValue?.data?.lunch_break?.to}
                >
                  <Input addonBefore="până la" disabled={!lunchBreak?.lunch} />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default NomenclaturesATMForm;
