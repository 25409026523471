import React from 'react';
import { Form } from 'antd';
import { Properties } from 'types';

import { RowSelect, IndexSelect } from '../Custom';
import { formItemStyle } from '../helpers';

export default ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item name={[item.id, lang, 'id']} className="hide" style={formItemStyle}>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={[item.id, lang, 'type']} className="hide" style={formItemStyle}>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={[item.id, lang, 'order']} className="hide" style={formItemStyle}>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={[item.id, lang, 'zIndex']} className="drop-tree__node-edit__zIndex">
        <IndexSelect />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'col']}
        initialValue={12}
        className="drop-tree__node-edit__col"
      >
        <RowSelect />
      </Form.Item>
    </>
  );
};
