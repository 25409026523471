import React, { useCallback, useEffect, useState } from 'react';
import { Form, Tabs, Modal, Input, Button, Select, Row, Col, notification } from 'antd';
import { languages, i18n, Languages, isLanguage } from 'lib/utils';
import faq, { QandA } from 'lib/api/faq';
import { ArticleEditor } from 'components/Articles';
import { Properties, FAQType } from 'types';

const { TabPane } = Tabs;

interface FrequentlyAskedQuestionModalProps {
  id?: number;
  onClose: () => void;
  onSuccess: () => void;
}

const FrequentlyAskedQuestionModal: React.FC<FrequentlyAskedQuestionModalProps> = ({
  id,
  onClose,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<QandA>();
  const [activeTabKey, setActiveTabKey] = useState<Languages>('ro');

  useEffect(() => {
    if (data) {
      Object.keys(data.i18n).forEach((lang) => {
        if (!Array.isArray(data.i18n[lang])) {
          form.setFieldsValue({
            i18n: {
              [lang]: data.i18n[lang],
            },
            type: data.type,
          });
        }
      });
    }
  }, [data]);

  const fetch = useCallback(async () => {
    try {
      if (id) {
        setData(await faq.byId(id));
      }
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const onChange = (key: string): void => {
    if (isLanguage(key)) setActiveTabKey(key);
  };

  const handleSubmit = async (values: Properties): Promise<void> => {
    try {
      await faq.upsert({ id, ...values } as QandA);

      onSuccess();
      onClose();

      Modal.success({ title: 'Modificările au fost cu succes.' });
    } catch (e) {
      Modal.error({
        title: `${values.id ? 'Editarea' : 'Crearea'} întrebării frecvente a eșuat.`,
        content: e.message,
      });
    }
    // let errorLang = '';
    // Object.keys(err?.i18n as any).forEach((lang) => {
    //   Object.keys(err?.i18n[lang]).forEach((el) => {
    //     if (err?.i18n[lang][el]?.errors.length) {
    //       errorLang = lang;
    //     }
    //   });
    // });
    // if (errorLang) {
    //   onChange(errorLang);
    // }
  };

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>

          <Button type="primary" htmlType="submit" form="vacancy">
            Save
          </Button>
        </>
      }
      destroyOnClose
      width="60vw"
    >
      <Form id="vacancy" form={form} onFinish={handleSubmit}>
        <Tabs className="custom-tabs" animated={false} activeKey={activeTabKey} onChange={onChange}>
          {languages.map((language) => {
            return (
              <TabPane tab={i18n.language[language]} key={language}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Întrebarea"
                      name={['i18n', language, 'question']}
                      rules={[
                        {
                          required: true,
                          message: 'Întrebarea este necesar',
                        },
                      ]}
                    >
                      <Input placeholder={`Introduceți întrebarea [${language}]`} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Tip"
                      name={['type']}
                      rules={[
                        {
                          required: true,
                          message: 'Tipul este necesar',
                        },
                      ]}
                    >
                      <Select>
                        {Object.keys(FAQType).map((key) => (
                          <Select.Option key={key} value={key}>
                            {FAQType[key]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Răspuns" name={['i18n', language, 'answer']}>
                      <ArticleEditor />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </Tabs>
      </Form>
    </Modal>
  );
};

export default FrequentlyAskedQuestionModal;
