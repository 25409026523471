import React from 'react';
import { Button, Row, Col } from 'antd';
import { StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { User } from 'types';

interface UserActionsProps {
  user: User;
  onRemove: (user: User) => void;
  onBlock: (user: User) => void;
}

const UserActions: React.FC<UserActionsProps> = ({ user, onRemove, onBlock }) => {
  return (
    <Row>
      <Col span={12}>
        <Button icon={<StopOutlined />} onClick={() => onBlock(user)}>
          {user.is_active ? 'B' : 'Deb'}lochează
        </Button>
      </Col>
      <Col span={12}>
        <Button icon={<DeleteOutlined />} onClick={() => onRemove(user)}>
          Șterge
        </Button>
      </Col>
    </Row>
  );
};

export default UserActions;
