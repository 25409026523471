import React, { FC, useContext } from 'react';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Link, useHistory } from 'react-router-dom';
import api from 'lib/api';
import { Roles } from 'lib/api/users';
import {
  Users,
  Article,
  Gallery,
  // Complaint,
  User,
  Cog,
  Translate,
  // Profession,
  Logout,
  Sigma,
  ATM,
  Layers,
  Report,
  Orders,
  Credit,
  Deposit,
  OnlineBanking,
  ShortUrl,
} from 'resources';
import { SessionContext } from '../context/utils';

interface Page {
  title: string;
  icon?:
    | React.ComponentType<React.SVGProps<SVGSVGElement> | CustomIconComponentProps>
    | React.ForwardRefExoticComponent<CustomIconComponentProps>
    | undefined;
  link: string;
  submenu?: Page[];
  /**
   * Array of Roles that haven't access to this route.
   */
  disabled?: Roles[];
}

const pages: Page[] = [
  { title: 'Pagini statice', icon: Article, link: 'pages' },
  { title: 'Menu', icon: Layers, link: 'menu' },
  {
    title: 'Formulare',
    icon: Report,
    link: '-forms',
    submenu: [
      { title: 'Lista', icon: Report, link: 'forms-list' },
      { title: 'Opțiuni de selectare', icon: Sigma, link: 'custom-options' },
      { title: 'Rezultate', icon: Orders, link: 'form-results' },
    ],
  },
  {
    title: 'Utilizatori',
    icon: Users,
    link: 'users',
    disabled: [Roles.CONTENT_MANAGER],
  },
  { title: 'Credite', icon: Credit, link: 'credits' },
  { title: 'Deposite', icon: Deposit, link: 'deposit' },
  { title: 'Bannere', icon: Gallery, link: 'banners' },
  // { title: 'Posturi Vacante', icon: Profession, link: 'vacancies' },
  { title: 'FAQ', icon: Article, link: 'faq' },
  { title: 'Oficii si ATM', icon: ATM, link: 'atm' },
  { title: 'Short urls', icon: ShortUrl, link: 'short-url' },
  { title: 'Online banking', icon: OnlineBanking, link: 'online-banking' },
  // { title: 'Leadership', icon: Profession, link: 'leadership' },
  { title: 'Traduceri', icon: Translate, link: 'i18n' },
  // { title: 'Reclamații & Petiții', icon: Complaint, link: 'complaints' },
  { title: 'Contact', icon: Article, link: 'support' },
];

const settings: Page[] = [{ title: 'Settings', icon: Cog, link: 'settings' }];

const SiderMenu: FC = () => {
  const { push, location } = useHistory();
  const { removeSession } = useContext(SessionContext);

  const handleLogout = (): void => {
    removeSession();

    api.removeSessionTokens();
  };

  const handleSelect = (select: any): void => {
    push(`/${select.key}`);
  };

  const menuProps = (link: string): { key: string; className: string } => ({
    key: link,
    className: location.pathname.includes(link) ? 'ant-menu-item-selected' : '',
  });

  //const role = session.user!.role as Roles;
  const role = Roles.ADMINISTRATOR;

  return (
    <>
      <Menu
        className="sticky-top"
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['-forms']}
        onSelect={handleSelect}
      >
        {pages.map(({ icon, link, title, submenu, disabled }) => {
          if (disabled && disabled.includes(role)) {
            return null;
          }

          const menuTitle = (
            <>
              {icon && <Icon component={icon} />}
              <span>{title}</span>
            </>
          );

          return submenu ? (
            <Menu.SubMenu {...menuProps(link)} title={menuTitle}>
              {submenu.map(({ title, link }) => (
                <Menu.Item {...menuProps(link)}>
                  <Link to={`/${link}`}>{title}</Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item {...menuProps(link)}>
              <Link to={`/${link}`}>{menuTitle}</Link>
            </Menu.Item>
          );
        })}
        <Menu.Divider />
      </Menu>
      <Menu className="sticky-bottom">
        <Menu.Item onClick={() => push('/profile')}>
          <Icon component={User} />
          <span>My profile</span>
        </Menu.Item>

        {settings.map(({ icon, link, title, disabled }) => {
          if (disabled && disabled.includes(role)) {
            return null;
          }
          const menuTitle = (
            <>
              {icon && <Icon component={icon} />}
              <span>{title}</span>
            </>
          );

          return (
            <Menu.Item {...menuProps(link)}>
              <Link to={`/${link}`}>{menuTitle}</Link>
            </Menu.Item>
          );
        })}

        <Menu.Item onClick={handleLogout}>
          <Icon component={Logout} />
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SiderMenu;
