import React from 'react';
import { Form, Input, Radio } from 'antd';
import { Properties } from 'types';
import { formItemStyle } from '../helpers';

enum DatePickerType {
  INPUT = 'input',
  SELECT = 'select',
}

export default ({ lang }: Properties) => {
  return (
    <>
      <Form.Item
        label="Nume"
        name="name"
        rules={[
          {
            required: true,
            message: 'Nume este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți Nume`} />
      </Form.Item>

      <Form.Item label="Label" name={['i18n', lang, 'label']} style={formItemStyle}>
        <Input placeholder={`Introduceți label`} />
      </Form.Item>
      <Form.Item
        label="Tip"
        name={['options', 'type']}
        initialValue={DatePickerType.INPUT}
        rules={[
          {
            required: true,
            message: 'Tipul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Radio.Group>
          <Radio value={DatePickerType.INPUT}>Introducere</Radio>
          <Radio value={DatePickerType.SELECT}>Selectare</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};
