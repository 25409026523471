import React, { useState, useCallback } from 'react';
import { Form, Input, Upload, message, Tabs, Row, Col, Button, Select } from 'antd';
import env from '@beam-australia/react-env';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import api from 'lib/api';
import { getBase64 } from 'utils';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';
import { Items, Link } from '../Custom';

const REACT_APP_API_BASE_URL = env('API_BASE_URL');
const maxImageSizeInMb = 4;

export default ({ item, lang, setFieldsValue }: Properties) => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const [loading, setLoading] = useState(false);

  const handleChange = (info: Properties) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
  };

  const handleUpload = async (file: any, i: number): Promise<void> => {
    setLoading(true);
    try {
      const data = await api.pages.uploadFile(file);
      const { id: uid, upload, uploaded } = data;

      const newFile: UploadFile<any> = {
        uid,
        size: 1,
        name: upload,
        status: uploaded ? 'done' : 'error',
        url: `${REACT_APP_API_BASE_URL}api/v1/static${upload}`,
        type: 'image',
      };

      setFieldsValue({
        [item.id]: {
          [lang]: {
            tabs: item?.tabs?.map((tab, index) => {
              if (index === i) {
                tab.image = { fileList: [newFile], url: newFile.url };
              }

              return tab;
            }),
          },
        },
      });
    } catch (e) {
      message.error('Unsuccessfull image upload!');
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file: any, i: number): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Puteți încărca doar imagini în formatul JPG/PNG!');
      return false;
    }

    const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
    if (!isMbLt) {
      message.error(`Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`);
      return false;
    }
    handleUpload(file, i);

    return false;
  };

  const onChangeTabs = useCallback(
    (key, action) => {
      switch (action) {
        case 'add':
          setFieldsValue({
            [item.id]: {
              [lang]: {
                tabs: [
                  ...(item?.tabs || []),
                  {
                    title: `Card #${(item?.tabs?.length || 0) + 1}`,
                    action: { link: undefined, text: '' },
                    image: undefined,
                    description: [],
                  },
                ],
              },
            },
          });
          break;
        case 'remove':
          setFieldsValue({
            [item.id]: {
              [lang]: {
                tabs: [...(item?.tabs || [])].filter((_, i) => i.toString() !== key),
              },
            },
          });
          break;
      }
    },
    [item, lang, setFieldsValue],
  );

  return (
    <>
      <Form.Item
        name={[item.id, lang, 'dataType']}
        initialValue="card"
        label="Tip"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="card">Card</Select.Option>
          <Select.Option value="banner">Banner</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Titlu"
        name={[item.id, lang, 'title']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți titlul`} />
      </Form.Item>

      <Tabs
        type="editable-card"
        addIcon={
          <Row gutter={[8, 8]}>
            <Col>
              <PlusOutlined />
            </Col>
            <Col>Adaugă</Col>
          </Row>
        }
        activeKey={activeKey}
        onChange={setActiveKey}
        onEdit={onChangeTabs}
      >
        {[...(item.tabs || [])]?.map((tabItem: Properties, i: number) => (
          <Tabs.TabPane forceRender key={i.toString()} tab={tabItem?.title || ''} closable>
            <Form.Item
              label="Tag"
              name={[item.id, lang, 'tabs', i, 'tag']}
              initialValue={tabItem?.tag}
              style={formItemStyle}
            >
              <Input placeholder={`Introduceți tag cardului`} />
            </Form.Item>
            <Form.Item
              label="Imagine"
              name={[item.id, lang, 'tabs', i, 'image']}
              style={formItemStyle}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => handleBeforeUpload(file, i)}
                onChange={handleChange}
              >
                {tabItem?.image?.url ? (
                  <img src={`${tabItem.image.url}`} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div className="ant-upload-text">Încărca</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="Titlul cardului"
              name={[item.id, lang, 'tabs', i, 'title']}
              initialValue={tabItem?.title}
              rules={[
                {
                  required: true,
                  message: 'Titlul cardului este necesar',
                },
              ]}
              style={formItemStyle}
            >
              <Input placeholder={`Introduceți titlul cardului`} />
            </Form.Item>
            <Form.Item
              label={
                <Button
                  type="primary"
                  className="full-width"
                  onClick={() =>
                    setFieldsValue({
                      [item.id]: {
                        [lang]: {
                          tabs: [...(item?.tabs || [])].map((tab, index) => {
                            if (index === i) {
                              tab.items = [...(tab?.items || []), { title: '' }];
                            }

                            return tab;
                          }),
                        },
                      },
                    })
                  }
                >
                  Adaugă
                </Button>
              }
              name={[item.id, lang, 'tabs', i, 'items']}
              initialValue={tabItem?.items}
              style={formItemStyle}
            >
              <Items />
            </Form.Item>
            <Form.Item
              name={[item.id, lang, 'tabs', i, 'action']}
              initialValue={tabItem?.action}
              label="Acțiune"
              style={formItemStyle}
            >
              <Link lang={lang} />
            </Form.Item>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};
