import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Row, Col, Button, Form, Card, Select, Typography, notification } from 'antd';
import api from 'lib/api';
import { SettingsFrontStrings } from 'lib/api/settings';
import { ArrayResult, Properties } from 'types';

const Settings: FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [pages, setPages] = useState<Properties[]>([]);
  const [stateId, setStateId] = useState<number>();
  const [securityId, setSecurityId] = useState<number>();

  const setData = (data: ArrayResult<SettingsFrontStrings>): void => {
    if (!data?.results.length) {
      return;
    }
    const findLang = data.results.find((i) => i.keyword === 'lang');
    const securityPolicy = data.results.find((i) => i.keyword === 'policy');

    try {
      if (findLang) {
        const value = JSON.parse(findLang.value as string);
        form.setFieldsValue(value);
        setStateId(findLang.id);
      }

      if (securityPolicy) {
        form.setFieldsValue({ securityPolicy: parseInt(securityPolicy.value) });
        setSecurityId(securityPolicy.id);
      }
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      api.pages
        .all({ name_content_icontains: search, page: 1, page_size: 1000 })
        .then(({ results }) => {
          setPages(results);
          setLoading(false);
        });
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [search]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const data = await api.settings.getSettings();
      setData(data);
    };
    fetchData();
  }, []);

  const handleSubmit = async ({ securityPolicy, ...values }: Properties): Promise<void> => {
    setLoading(true);

    try {
      const data: SettingsFrontStrings = {
        keyword: 'lang',
        value: JSON.stringify({
          ...values,
        }),
      };

      if (stateId) {
        await api.settings.editSetting({ id: stateId, ...data }, stateId);
      } else {
        await api.settings.addSettings(data);
      }

      if (securityId && securityPolicy) {
        await api.settings.editSetting(
          { id: securityId, keyword: 'policy', value: securityPolicy },
          securityId,
        );
      } else if (securityPolicy) {
        const { id } = await api.settings.addSettings({ keyword: 'policy', value: securityPolicy });
        setSecurityId(id);
      }
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }

    setLoading(false);
  };

  const handleClearCache = async () => {
    try {
      await api.support.clearCache();
      notification.success({ message: 'Memoria cache a fost ștersă cu succes!' });
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col xs={24} md={16} xl={12} xxl={8}>
          <Form form={form} onFinish={handleSubmit}>
            <Card>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Row gutter={8}>
                    <Col xs={24} sm={8}>
                      <Form.Item name="ro" valuePropName="checked" initialValue={true}>
                        <Checkbox disabled>Română</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Form.Item name="ru" valuePropName="checked">
                        <Checkbox>Pусский</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Form.Item name="en" valuePropName="checked">
                        <Checkbox>English</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="securityPolicy" label="Politica de securitate a datelor">
                    <Select
                      loading={loading}
                      showSearch
                      searchValue={search}
                      onSearch={setSearch}
                      optionFilterProp="children"
                      autoClearSearchValue
                      placeholder="Link"
                    >
                      {pages.map((page) => (
                        <Select.Option key={page.id} value={page.id}>
                          {page.i18n.ro.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Salvează
                  </Button>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={16} xl={12} xxl={8}>
          <Card>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button type="dashed" onClick={handleClearCache}>
                  Șterge memoria cache
                </Button>
              </Col>
              <Col span={24}>
                <Typography.Text type="danger">
                  Dacă faceți clic pe butonul „Șterge memoria cache”, întregul cache va fi șters!
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
