import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { BlockOutlined } from '@ant-design/icons';

const options = [3, 2, 1, -1];

interface IndexSelectProps {
  value?: number;
  onChange?: (value: number) => void;
}

const IndexSelect: React.FC<IndexSelectProps> = (props) => {
  const [state, setState] = useState(props?.value);

  useEffect(() => {
    if (props.value && props.value !== state) {
      setState(props.value);
    }
  }, [props]);

  const onChange = (value) => {
    setState(value);

    if (props?.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Dropdown
      overlayClassName="col-select"
      overlay={
        <Menu>
          {options.map((item) => (
            <Menu.Item
              key={item}
              className={state === item ? 'selected' : undefined}
              onClick={() => onChange(item)}
            >
              {item}
            </Menu.Item>
          ))}
        </Menu>
      }
      placement="bottomLeft"
    >
      <Button size="small">
        <BlockOutlined /> {state || '-'}
      </Button>
    </Dropdown>
  );
};

export default IndexSelect;
