import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Row, Col, notification } from 'antd';
import Search from 'antd/lib/input/Search';
import users from 'lib/api/users';
import { User } from 'types';
import { ArrayResult } from 'lib/api';
import UserActions from './UserActions';
import AddUser from './AddUser';
import { ColumnProps } from 'antd/lib/table';

const UsersTable: React.FC = () => {
  const { location, push } = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<ArrayResult<User>>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);
  const search = params.search || undefined;
  const page = Number(params.page) || 1;
  const pageSize = 10;

  const fetchUsers = useCallback(async () => {
    try {
      setData(
        await users.getAll({
          ...params,
          search,
          limit: pageSize,
          page,
        }),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [page, search]);

  const handleSearchHandler = (): void => {
    push({
      pathname: '/users',
      search: '?' + new URLSearchParams({ search: searchValue }).toString(),
    });
  };

  const handleBlock = (user: User): void => {
    Modal.confirm({
      title: `Doriți să ${user?.is_active ? 'blocați' : 'deblocați'} acest user?`,
      onOk: async () => {
        try {
          if (user?.is_active) {
            await users.block(user);
          } else {
            await users.unblock(user);
          }
          fetchUsers();
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const handleRemove = async (user: User): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest articol?',
      onOk: async () => {
        try {
          await users.remove(user.id as number);
          push(
            `?${new URLSearchParams({
              ...params,
              page: String(1),
            }).toString()}`,
          );
          fetchUsers();
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<any>[] = [
    {
      title: 'Nume',
      render: (user: User) => [user.first_name, user.last_name].join(' '),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Ultima accesare',
      render: (user: User) => (user.last_login ? moment(user.last_login).fromNow() : '-'),
    },
    {
      width: 250,
      align: 'right',
      title: 'Acțiuni',
      render: (user: User) => (
        <UserActions user={user} onBlock={handleBlock} onRemove={handleRemove} />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Search
            enterButton="Caută"
            placeholder="Caută userul"
            size="large"
            value={searchValue}
            onSearch={handleSearchHandler}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Col>

        <Col>
          <AddUser />
        </Col>
      </Row>
      <Table
        rowKey="id"
        columns={columns}
        loading={!data}
        dataSource={data ? data.results : []}
        style={{ overflow: 'auto', backgroundColor: 'white' }}
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: pageSize,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />
    </>
  );
};

export default UsersTable;
