import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Dropdown, Menu, Button } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

interface Props {
  id: number;
  code?: string;
  customText?: string;
  onEdit?: (id: number) => void;
  onRemove?: (id: number) => void;
}

const Actions: React.FC<Props> = ({ id, code, customText, onRemove, onEdit }) => {
  const history = useHistory();
  return (
    <Row gutter={[16, 16]} justify="end" style={{ display: 'flex' }}>
      {code && (
        <Col>
          <Button icon={<EyeOutlined />} type="dashed" onClick={() => history.push(code)}>
            {customText}
          </Button>
        </Col>
      )}
      <Col>
        <Dropdown.Button
          overlay={
            <Menu>
              {onRemove && (
                <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(id)}>
                  Șterge
                </Menu.Item>
              )}
            </Menu>
          }
          onClick={() => onEdit && onEdit(id)}
        >
          <EditOutlined /> Editează
        </Dropdown.Button>
      </Col>
    </Row>
  );
};

export default Actions;
