import React, { FC, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Row, Col, Button, notification } from 'antd';
import Search from 'antd/lib/input/Search';
import credits, { NomenclaturesCredits } from 'lib/api/credits';

import { ColumnProps } from 'antd/lib/table';
import { ArrayResult } from 'lib/api';
import { Actions } from 'components/Actions';

const CreditsList: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<ArrayResult<NomenclaturesCredits>>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;

  const fetchCredits = useCallback(async () => {
    try {
      setData(
        await credits.getCredits({
          ...params,
          search,
          page,
        }),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [page, search]);

  const onRemove = async ({ id }: NomenclaturesCredits): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest articol?',
      onOk: async () => {
        try {
          await credits.removeCredit(id as number);
          fetchCredits();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const onSearchHandler = (): void => {
    history.push({
      pathname: '/credits',
      search: '?' + new URLSearchParams({ search: searchValue }).toString(),
    });
  };

  useEffect(() => {
    fetchCredits();
  }, [fetchCredits]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<NomenclaturesCredits>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      dataIndex: 'title',
    },
    {
      title: 'Data creării',
      render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
    },

    {
      title: 'Acțiuni',
      width: 400,
      align: 'right',
      render: (credit) => {
        return (
          <Actions
            id={credit.id}
            code={`/credits/types/${credit.id}`}
            customText="Credit types"
            onEdit={() => history.push(`/credits/edit/${credit.id}`)}
            onRemove={() => onRemove(credit)}
          />
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Search
            enterButton="Caută"
            placeholder="Caută credit după titlu"
            size="large"
            value={searchValue}
            onSearch={onSearchHandler}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Col>
        <Col>
          <Button type="primary" size="large" onClick={() => history.push('/credits/create')}>
            Creează credit
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />
    </>
  );
};

export default CreditsList;
