import api from './index';
import { EnvApi, Properties } from 'types';
import { stringifyUrl } from 'utils';

export interface NomenclatureGroup {
  timestamp?: string;
  edited_timestamp?: string;
  code?: string;
  title?: string;
  description?: string;
  slug?: string;
  data?: Properties;
  id?: number;
}

export default {
  getGroupsATMList: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl('nomenclatures/groups', params, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },

  getGroupATMById: async (id: number) => {
    const { data } = await api.request.get(
      stringifyUrl(
        `nomenclatures/groups/${id}`,
        {},
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  createGroupATM: async (values) => {
    const { data } = await api.request.post(
      stringifyUrl('nomenclatures/groups', {}, { api: EnvApi.API_MAIN_URL }),
      values,
    );

    return data;
  },

  updateGroupATM: async ({ id, ...values }) => {
    const { data } = await api.request.patch(
      stringifyUrl(`nomenclatures/groups/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
      values,
    );
    return data;
  },

  removeGroupATM: async (id: number) => {
    await api.request.delete(
      stringifyUrl(`nomenclatures/groups/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );
  },
};
