import * as React from 'react';
import { PublicRoutes } from 'lib/router';
import { BrowserRouter } from 'react-router-dom';
import { AppSession } from 'context/utils';
import SessionGuard from './SessionGuard';
import { ConfigProvider } from 'antd';
import ro from 'antd/es/locale/ro_RO';

/**
 * This is the "entry" component of this application.
 * Its job is to render routes wrapped by session context.
 *
 * This is the comonent mounted in the #root element.
 */

const App: React.FC = () => (
  <AppSession>
    <ConfigProvider locale={ro}>
      <BrowserRouter basename="/cms">
        <PublicRoutes />
        <SessionGuard />
      </BrowserRouter>
    </ConfigProvider>
  </AppSession>
);

export default App;
