import api from '.';
import { stringifyUrl } from 'utils';
import { Languages } from 'lib/utils';

export interface QandAContent {
  question?: string;
  answer?: string;
}

export interface QandA {
  id: number;
  upvote?: number;
  downvote?: number;
  type?: string;
  i18n: Record<Languages, QandAContent>;
  edited_timestamp?: string;
  timestamp?: string;
}

export default {
  /**
   * Get all FAQs
   */
  getAll: async (params: any = {}) => {
    const { data } = await api.request.get(stringifyUrl(`faq/`, { page_size: 10, ...params }, {}));

    return data;
  },

  /**
   * get FAQ by ID
   */
  byId: async (id: number) => {
    const { data } = await api.request.get(stringifyUrl(`faq/${id}/`, {}, {}));

    return data;
  },
  /**
   * Remove a FAQ by id
   */
  remove: async (id: number) => {
    await api.request.delete(stringifyUrl(`faq/${id}/`, {}, {}));
  },
  /**
   * `Insert`, either `Update` a FAQ item based on existence of id.
   */
  upsert: async (question: QandA): Promise<QandA> => {
    const id = question.id ? `${question.id}/` : '';

    const method = id ? api.request.patch : api.request.post;

    const { data } = await method(stringifyUrl(`faq/${id}`, {}, {}), question);

    return data as QandA;
  },
};
