import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Dropdown, Menu, Modal, Row, Form, Table, notification } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { ArrayResult } from 'lib/api';
import { ColumnProps } from 'antd/lib/table';
import nomenclatures, { NomenclatureShortURL } from 'lib/api/nomenclatures';
import Search from 'antd/lib/input/Search';
import { ShortUrlForm } from 'components/ShortUrlForm';

const formId = 'short-url-form';

const ShortUrl = () => {
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const [form] = Form.useForm();

  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState<ArrayResult<NomenclatureShortURL>>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [shortUrlId, setShortUrlId] = React.useState<number>();

  const page = Number(params.page) || 1;
  const limit = Number(params.limit) || 10;
  const search = params.search || undefined;

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const fetchShortUrlList = useCallback(async () => {
    try {
      setData(
        await nomenclatures.getShortUrlList({
          ...params,
          search,
          page,
          limit,
        }),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [page, search]);

  useEffect(() => {
    fetchShortUrlList();
  }, [fetchShortUrlList]);

  const fetchShortUrl = useCallback(async () => {
    try {
      if (shortUrlId) {
        const data = await nomenclatures.getShortUrlById(shortUrlId);
        form.setFieldsValue({ slug: data.slug, redirect_to: data.redirect_to });
      }
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [shortUrlId]);

  useEffect(() => {
    fetchShortUrl();
  }, [fetchShortUrl]);

  const onSubmit = async (values: NomenclatureShortURL): Promise<void> => {
    try {
      if (shortUrlId) {
        await nomenclatures.updateShortUrl({
          ...values,
          id: Number(shortUrlId),
        });
      } else {
        await nomenclatures.createShortUrl(values);
      }
      onCloseModal();
      fetchShortUrlList();
    } catch (error) {
      const message = error?.response?.data?.slug?.[0] || error.message;

      Modal.error({ content: message });
    }
  };

  const onRemove = async ({ id }: NomenclatureShortURL): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acestă adresă (short URL) ?',
      onOk: async () => {
        try {
          await nomenclatures.removeShortUrl(Number(id));
          fetchShortUrlList();
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const onCloseModal = () => {
    form.resetFields();
    setShortUrlId(undefined);
    setIsOpen(false);
  };

  const columns: ColumnProps<NomenclatureShortURL>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
    },
    {
      title: 'Redirecționează de la',
      dataIndex: 'redirect_from',
    },
    {
      title: 'Redirecționează către',
      dataIndex: 'redirect_to',
    },

    {
      title: 'Acțiuni',
      width: 100,
      align: 'right',
      render: (shortUrl) => {
        return (
          <>
            <Dropdown.Button
              overlay={
                <Menu>
                  <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(shortUrl)}>
                    Șterge
                  </Menu.Item>
                </Menu>
              }
              onClick={() => {
                setIsOpen(true);
                setShortUrlId(shortUrl?.id);
              }}
            >
              <EditOutlined /> Editează
            </Dropdown.Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {isOpen && (
        <Modal
          title={shortUrlId ? 'Editează' : 'Adaugă'}
          maskClosable={false}
          destroyOnClose
          visible={isOpen}
          width={800}
          okButtonProps={{ htmlType: 'submit', form: formId }}
          onCancel={onCloseModal}
        >
          <ShortUrlForm formId={formId} form={form} handleSubmit={onSubmit} />
        </Modal>
      )}

      <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Search
            enterButton="Caută"
            placeholder="Caută url după slug"
            size="large"
            value={searchValue}
            onSearch={() => updateQuery({ search: searchValue })}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Col>
        <Col>
          <Button type="primary" size="large" onClick={() => setIsOpen(true)}>
            Creează short url
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        scroll={{ x: 1100 }}
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current, pageSize }, filters) => {
          updateQuery({ ...params, ...filters, page: String(current), limit: String(pageSize) });
        }}
      />
    </>
  );
};

export default ShortUrl;
