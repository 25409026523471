import React from 'react';
import { DragSource } from 'react-dnd';

const spec = {
  beginDrag(props) {
    return { ...props.value };
  },
};
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class DraggableItem extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      lang,
      connectDragSource,
      isDragging,
      canDrag = true,
      value: { i18n },
      disabled,
    } = this.props;

    return canDrag && !disabled ? (
      connectDragSource(
        <div
          className="float-left"
          style={{
            opacity: isDragging ? 0.5 : 1,
            cursor: isDragging ? 'move' : '',
          }}
          onDragStart={this.props.onMouseDown}
          onDragEnd={this.props.onMouseOut}
        >
          {i18n[lang].title}
        </div>,
      )
    ) : (
      <div className="float-left">{i18n[lang].title}</div>
    );
  }
}

export default DragSource('components', spec, collect)(DraggableItem);
