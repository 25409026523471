import api from '.';
import { stringifyUrl } from 'utils';

export interface Complaint {
  id: number;
  captcha: string;
  attachments?: Attachment[];
  attachments_id?: number[];
  timestamp?: string;
  edited_timestamp?: string;
  type: PetitionComplaintType;
  person_type: PersonType;
  reply_type: ReplyType;
  fullname?: string;
  address?: string;
  phone_number?: string;
  email?: string;
  description?: string;
  title?: string;
  data?: Properties;
  inquiry?: boolean;
  code?: string;
  reason?: ReasonsType;
  date?: any;
  recipient_name?: string;
  recipient_address?: string;
  recipient_phone?: string;
  recipient_email?: string;
  sender_name?: string;
  sender_address?: string;
  sender_phone?: string;
  sender_email?: string;
}

export type Properties = Record<string, any>;

export interface Attachment {
  id: number;
  upload: string;
  url: string;
  uploaded: string;
}

export enum PetitionComplaintType {
  PETITION = '1',
  COMPLAINT_INTERN = '2',
  COMPLAINT_EXTERN = '3',
}

export enum PersonType {
  PHYSICS = '1',
  LEGALLY = '2',
}

export enum ReplyType {
  BY_PHONE = '1',
  BY_EMAIL = '2',
  BY_POST = '3',
}

export enum ReasonsType {
  SEARCH_POSTAL_ITEM = '1',
  MISSING_DAMAGED_CONTENT = '2',
  DISAGREE_WITH_RETURN = '3',
  LACK_ACKNOWLEDGMENT_RECEIPT = '4',
  NON_RECEIPT_REFUND_AMOUNT = '5',
  ANOTHER_REASON = '6',
}

export const type = (type: PetitionComplaintType): string => {
  switch (type) {
    case PetitionComplaintType.PETITION:
      return 'Petiție';
    case PetitionComplaintType.COMPLAINT_INTERN:
      return 'Reclamație';
    case PetitionComplaintType.COMPLAINT_EXTERN:
      return 'Reclamație internațională';
    default:
      return 'Tip necunoscut';
  }
};

export default {
  all: async (params: any = {}) => {
    const query = new URLSearchParams({ page_size: 10, ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(
      stringifyUrl(`complaint/list/?${query.toString()}`, query, { version: 2 }),
    );

    return data;
  },
  byId: async (id: number) => {
    const { data } = await api.request.get(`/v2/complaint/${id}/`);

    return data;
  },
  remove: async (id: number) => {
    const { data } = await api.request.delete(`/v2/complaint/${id}/`);

    return data;
  },
};
