import React, { useEffect, useState } from 'react';
import { Button, Row, Col, List, Card, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import IconSelect from './IconSelect';
import { Properties } from 'types';

interface ItemsProps {
  value?: Properties[];
  onChange?: (value: Properties[]) => void;
}

const Items: React.FC<ItemsProps> = (props) => {
  const [state, setState] = useState(props?.value || []);

  useEffect(() => {
    if (props?.value && JSON.stringify(props.value) !== JSON.stringify(state)) {
      setState(props.value);
    }
  }, [props]);

  const onChange = (value) => {
    setState(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Card size="small" style={{ minWidth: 250 }} bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
      <List
        itemLayout="horizontal"
        dataSource={state}
        renderItem={(tabItem, i) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <IconSelect
                  value={tabItem?.icon}
                  onChange={(icon) =>
                    onChange(
                      [...state].map((tabDesription, index) => {
                        if (index === i) {
                          tabDesription.icon = icon;
                        }

                        return tabDesription;
                      }),
                    )
                  }
                />
              }
              title={
                <Row wrap={false} gutter={[16, 16]} align="middle">
                  <Col className="full-width">
                    <Input
                      value={tabItem.title}
                      className="full-width"
                      onChange={(e) =>
                        onChange(
                          [...state].map((tabDesription, index) => {
                            if (index === i) {
                              tabDesription.title = e.target.value;
                            }

                            return tabDesription;
                          }),
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => onChange([...state].filter((_, index) => index !== i))}
                    />
                  </Col>
                </Row>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default Items;
