import React, { useState } from 'react';
import { Form, Input, Upload, message, Checkbox } from 'antd';
import env from '@beam-australia/react-env';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import api from 'lib/api';
import { getBase64 } from 'utils';
import { Properties } from 'types';

import { Link } from '../Custom';
import { formItemStyle } from '../helpers';

const REACT_APP_API_BASE_URL = env('API_BASE_URL');
const maxImageSizeInMb = 4;

export default ({ item, lang, setFieldsValue }: Properties) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (info: Properties) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
  };

  const handleUpload = async (file: any): Promise<void> => {
    setLoading(true);
    try {
      const data = await api.pages.uploadFile(file);
      const { id: uid, upload, uploaded } = data;

      const newFile: UploadFile<any> = {
        uid,
        size: 1,
        name: upload,
        status: uploaded ? 'done' : 'error',
        url: `${REACT_APP_API_BASE_URL}/v1${upload}`,
        type: 'image',
      };

      setFieldsValue({
        [item.id]: {
          [lang]: {
            image: { fileList: [newFile], url: newFile.url },
          },
        },
      });
    } catch (e) {
      message.error('Unsuccessfull image upload!');
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file: any): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Puteți încărca doar imagini în formatul JPG/PNG!');
      return false;
    }

    const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
    if (!isMbLt) {
      message.error(`Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`);
      return false;
    }
    handleUpload(file);

    return false;
  };

  return (
    <>
      <Form.Item label="Imagine" name={[item.id, lang, 'image']} style={formItemStyle}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
        >
          {item?.image?.url ? (
            <img src={item.image.url} alt="avatar" style={{ width: '100%' }} />
          ) : (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Încărca</div>
            </div>
          )}
        </Upload>
      </Form.Item>

      <Form.Item
        label="Titlu"
        name={[item.id, lang, 'title']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți titlul`} />
      </Form.Item>

      <Form.Item
        label="Descriere"
        name={[item.id, lang, 'description']}
        rules={[
          {
            required: true,
            message: 'Descriere este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți descriere`} />
      </Form.Item>

      <Form.Item
        label="Reflectă"
        name={[item.id, lang, 'reverseBlock']}
        valuePropName="checked"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  reverseBlock: !item.reverseBlock,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'action']}
        initialValue={item?.action}
        label="Acțiune"
        style={formItemStyle}
      >
        <Link lang={lang} />
      </Form.Item>
    </>
  );
};
