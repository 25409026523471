import React, { FC, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Button, Modal, Row, Col, Form, DatePicker, notification } from 'antd';
import moment from 'moment';
import forms, { FormsProps, IsActiveForm } from 'lib/api/forms';
import { ArrayResult, Properties } from 'types';
import { FormActions } from 'components/Forms';
import Search from 'antd/lib/input/Search';
import { ColumnProps } from 'antd/lib/table';
import { defaultLocale } from 'lib/utils';
import { downloadBlobFile } from 'utils/downloadBlobFile';

const FormsPage: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<ArrayResult<FormsProps>>();
  const [formItem, setFormItem] = React.useState<FormsProps | undefined>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;

  const fetchForms = useCallback(async () => {
    try {
      setData(
        await forms.getForms({
          search,
          page,
        }),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [search, page]);

  const onRemove = async ({ id }: Properties): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți această formă?',
      onOk: async () => {
        try {
          await forms.removeForm(id);
          fetchForms();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const onDownload = async (values: Properties): Promise<void> => {
    try {
      const response = await forms.downloadForms(formItem?.id as number, {
        ...(values?.form_date && {
          start_date: moment(values?.form_date[0]).format('YYYY-MM-DD'),
          end_date: moment(values?.form_date[1]).format('YYYY-MM-DD'),
        }),
      });

      const date = new Date();

      const fileName = `${(formItem?.i18n['ro']?.title as string)
        .split(' ')
        .join('_')}_${date.toLocaleDateString('de-DE')}.xls`;
      downloadBlobFile(response.data, fileName, 'application/vnd.ms-excel');

      onCloseDownloadForm();
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  };

  const onOpenDownloadForm = (form) => {
    setFormItem(form);
  };

  const onCloseDownloadForm = () => {
    setFormItem(undefined);
    form.resetFields();
  };

  const onSearchHandler = (): void => {
    history.push({
      pathname: '/forms',
      search: '?' + new URLSearchParams({ search: searchValue }).toString(),
    });
  };

  useEffect(() => {
    fetchForms();
  }, [fetchForms]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<FormsProps>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      render: ({ i18n }) => i18n[defaultLocale]?.title || '-',
    },
    {
      title: 'Tip',
      render: ({ type }) => type || '-',
    },
    {
      title: 'Status',
      render: ({ is_active }) =>
        !is_active ? `${IsActiveForm.INACTIVE}` : `${IsActiveForm.ACTIVE}`,
    },

    {
      width: 175,
      title: 'Data creării',
      render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Acțiuni',
      width: 100,
      align: 'right',
      render: (form) => {
        return (
          <FormActions form={form} onRemove={onRemove} onOpenDownloadForm={onOpenDownloadForm} />
        );
      },
    },
  ];

  return (
    <>
      {formItem && (
        <Modal
          title="Modal"
          visible={!!formItem}
          width="30%"
          okButtonProps={{ htmlType: 'submit', form: 'downloadForm' }}
          onCancel={onCloseDownloadForm}
        >
          <Form id="downloadForm" form={form} onFinish={onDownload}>
            <Form.Item name="form_date">
              <DatePicker.RangePicker format="DD-MM-YYYY" style={{ width: `100%` }} />
            </Form.Item>
          </Form>
        </Modal>
      )}
      <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Row gutter={10} justify="end" align="middle">
            <Col>
              <Search
                enterButton="Caută"
                placeholder="Caută forma după titlu"
                size="large"
                value={searchValue}
                onSearch={onSearchHandler}
                onChange={({ target: { value } }) => setSearchValue(value)}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button type="primary" size="large" onClick={() => history.push('/forms/create')}>
            Creează formă
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />
    </>
  );
};

export default FormsPage;
