import React from 'react';
import { Form, Input } from 'antd';
import { Properties } from 'types';
import { formItemStyle } from '../helpers';

export default ({ lang }: Properties) => {
  return (
    <>
      <Form.Item
        label="Nume"
        name="name"
        rules={[
          {
            required: true,
            message: 'Nume este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți Nume`} />
      </Form.Item>

      <Form.Item label="Placeholder" name={['i18n', lang, 'placeholder']} style={formItemStyle}>
        <Input placeholder={`Introduceți placeholder`} />
      </Form.Item>

      <Form.Item label="Label" name={['i18n', lang, 'label']} style={formItemStyle}>
        <Input placeholder={`Introduceți label`} />
      </Form.Item>
    </>
  );
};
