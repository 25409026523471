import React, { FC, forwardRef, useState, useEffect } from 'react';
import { Upload, Button, message } from 'antd';
import env from '@beam-australia/react-env';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import api from 'lib/api';

interface GalleryWallProps {
  showFileList?: boolean;
  value?: string;
  onChange?: (content: string[], index?: number) => void;
  fieldIndex?: number;
}

const REACT_APP_API_BASE_URL = env('API_BASE_URL');
const maxImageSizeInMb = 4;

const UploadImage: FC<GalleryWallProps> = forwardRef(
  ({ value, onChange, showFileList, fieldIndex }) => {
    const [fileList, setFileList] = useState<UploadFile[]>(() => {
      const initialList: UploadFile[] = [];

      initialList.push({
        url: value,
        uid: 'backgournd',
        size: 1,
        name: `backgournd`,
        status: 'done',
        type: 'image',
      });

      return initialList;
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const images: string[] = [];

      fileList.forEach((file) => {
        if (file.url) {
          images.push(file.url);
        }
      });

      if (onChange && typeof fieldIndex !== 'number') {
        onChange(images);
      }
      if (onChange && typeof fieldIndex === 'number') {
        onChange(images, fieldIndex);
      }
    }, [fileList]);

    const handleChange = ({ fileList: newFileList }: { fileList: any[] }): void => {
      if (fileList.length > newFileList.length) {
        setFileList(newFileList);
      }
    };

    const handleUpload = async (file: any): Promise<void> => {
      setLoading(true);
      try {
        const data = await api.pages.uploadFile(file);
        const { id, upload, uploaded } = data;

        const newFile: UploadFile<any> = {
          uid: `${id}`,
          size: 1,
          name: upload,
          status: uploaded ? 'done' : 'error',
          url: `${REACT_APP_API_BASE_URL}/v1${upload}`,
          type: 'image',
        };

        setFileList([newFile]);
      } catch (e) {
        message.error('Unsuccessfull image upload!');
      }
      setLoading(false);
    };

    const handleBeforeUpload = (file: any): boolean => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('Puteți încărca doar imagini în formatul JPG/PNG!');
        return false;
      }

      const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
      if (!isMbLt) {
        message.error(`Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`);
        return false;
      }
      handleUpload(file);

      return false;
    };

    return (
      <div>
        <Upload
          fileList={showFileList ? fileList : []}
          onChange={handleChange}
          beforeUpload={handleBeforeUpload}
        >
          <Button loading={loading}>
            Alege fișier <UploadOutlined />
          </Button>
        </Upload>
      </div>
    );
  },
);

export default UploadImage;
