import env from '@beam-australia/react-env';
import { stringifyUrl } from 'utils';
import { EnvApi } from 'types';

export interface SettingsFrontStrings {
  id?: number;
  value: string;
  keyword: string;
}

export default {
  getList: async () => {
    const data = await fetch(
      stringifyUrl(`icons/index.json`, {}, { api: EnvApi.WEB_APP_URL }),
    ).then((response) => {
      return response.json();
    });

    return data
      .filter((name) => name !== 'cancel')
      .map((name: string) => ({
        name,
        url: `${env(EnvApi.WEB_APP_URL)}/icons/${name}.svg`,
      }));
  },
};
