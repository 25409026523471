import { createContext } from 'react';
import { ArrayResult } from 'lib/api';
import { Locale } from 'lib/api/pages';
import { NomenclaturesClassifiers } from 'lib/api/classifiers';

import { BuilderType, BuilderReducerAction } from './types';

export const initialState: BuilderType = {
  lang: Locale.RO,
  loading: false,
  state: {
    content: [],
    drag: {
      type: undefined,
      is: false,
    },
    keys: {
      expanded: [],
      selected: [],
    },
    isFullscreen: false,
  },
};

export interface BuilderProps extends BuilderType {
  dataClassifiers?: ArrayResult<NomenclaturesClassifiers>;
  onEsc: () => void;
  onExpandAll: () => void;
  dispatch: (value: BuilderReducerAction) => void;
}

export const BuilderContext = createContext<BuilderProps>({
  ...initialState,
  onEsc: () => null,
  onExpandAll: () => null,
  dispatch: () => null,
});
