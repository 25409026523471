export * from './log';
export * from './user';
export * from './faq';

export interface ArrayResult<T> {
  count: number;
  next: number | null;
  previous: number | null;
  results: T[];
}

export type Properties = { [key: string]: any };

export type VersionType = 1 | 2;

export enum EnvApi {
  API_BASE_URL = 'API_BASE_URL',
  API_AUTH_URL = 'API_AUTH_URL',
  API_MAIN_URL = 'API_MAIN_URL',
  WEB_APP_URL = 'WEB_APP_URL',
}
