import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import UploadImage from './UploadImage';
import { Properties } from 'types';

interface BackgroundFormProps {
  value: string;
  onSave: (url?: string | string[]) => Promise<void>;
}

const BackgroundForm: React.FC<BackgroundFormProps> = ({ onSave, value: initialValue }) => {
  const handleOnSubmit = async ({ url }: Properties): Promise<void> => {
    await onSave(url);
  };

  return (
    <Form onFinish={handleOnSubmit} id="BackgroundForm">
      <Row>
        <Col span={18}>
          <Form.Item name="url" initialValue={initialValue}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={5} push={1}>
          <Form.Item name="url" initialValue={initialValue} hasFeedback={false}>
            <UploadImage />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BackgroundForm;
