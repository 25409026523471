import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactDiffViewer from 'react-diff-viewer';
import { Button } from 'antd';

import api from '../lib/api';
import { Log as LogType } from '../types';

const Log: React.FC = () => {
  const { goBack } = useHistory();
  const [log, setLog] = useState<LogType>();

  const { id, route } = useParams<{ id: string; route: string }>();

  const fetchLog = async (id: string): Promise<void> => {
    setLog(await api[route as keyof typeof api].getLog(id));
  };

  useEffect(() => {
    if (id) {
      fetchLog(id);
    }
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <>
      <Button onClick={goBack} style={{ marginBottom: 10 }}>
        Înapoi
      </Button>

      <ReactDiffViewer
        oldValue={log && JSON.stringify(log.old_value, null, 2)}
        newValue={log && JSON.stringify(log.new_value, null, 2)}
        splitView={true}
      />
    </>
  );
};

export default Log;
