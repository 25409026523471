import React, { FC } from 'react';
import { Form, Col, Input, Row, Modal, Select, Tabs } from 'antd';
import banners, { Banner, BannerType } from 'lib/api/banners';
import { languages, i18n, Languages, isLanguage } from 'lib/utils';
import UploadImage from '../Backgrounds/UploadImage';
import { FieldsValidationError } from 'lib/api/utils';
import { Properties } from 'types';

const { TabPane } = Tabs;

interface BannerFormProps {
  data: Banner | null;
  onSuccess: (banner: Banner) => any;
  toggleType: (el: string) => void;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const bannerTypeName = {
  1: 'Persoane fizice',
  2: 'Business',
  3: 'Dezvăluirea informaţiei',
};

const BannerForm: FC<BannerFormProps> = ({ data, onSuccess, toggleType }) => {
  const [form] = Form.useForm();
  const [activeTabKey, setActiveTabKey] = React.useState<Languages>('ro');

  const handleSubmit = async ({ images, ...values }: Properties): Promise<void> => {
    // let errorLang = '';
    // Object.keys(err?.i18n as any).forEach((lang) => {
    //   Object.keys(err?.i18n[lang]).forEach((el) => {
    //     if (err?.i18n[lang][el]?.errors.length) {
    //       errorLang = lang;
    //     }
    //   });
    // });
    // if (errorLang) {
    //   onChange(errorLang);
    // }

    const [image] = Array.isArray(images) ? images : [images];
    const i18n: Properties = {};

    if (values) {
      Object.keys(values.i18n).forEach((key) => {
        i18n[key] = JSON.stringify(values.i18n[key]);
      });
    }
    const [error, banner] = await banners.upsert({
      ...(data?.id && { id: data?.id }),
      image,
      ...values,
      i18n,
    } as Banner);

    if (!error) {
      onSuccess(banner);
    } else {
      if (error instanceof FieldsValidationError) {
        //setFields(error.fields);
      } else {
        Modal.error({
          title: 'Procesul a eșuat.',
        });
      }
    }
    toggleType(values.type);
  };

  const onParseString = (el: Banner): { [key: string]: string | Properties } | undefined => {
    const i18n: { [key: string]: string | Properties } = {};
    Object.keys(el.i18n as any).forEach((lang) => {
      const text = el.i18n[lang as Languages];
      try {
        i18n[lang] = JSON.parse(text as string);
      } catch (e) {
        i18n[lang] = { title: text, content: text };
      }
    });
    return i18n;
  };

  const onChange = (key: string): void => {
    if (isLanguage(key)) setActiveTabKey(key);
  };

  const onFinishFailed = ({ errorFields }): void => {
    if (errorFields[0]?.name[1]) {
      setActiveTabKey(errorFields[0].name[1]);
    }
  };

  return (
    <Form
      {...formItemLayout}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      id="BannerForm"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Tabs
            className="custom-tabs"
            activeKey={activeTabKey}
            onChange={onChange}
            animated={false}
          >
            {languages.map((language) => {
              const parseI18n: any = data && onParseString(data);
              return (
                <TabPane forceRender tab={i18n.language[language]} key={language}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Titlu"
                        name={['i18n', language, 'title']}
                        initialValue={parseI18n?.[language]?.title}
                      >
                        <Input
                          style={{ width: '100%' }}
                          placeholder={`Introduceți titlul [${language}]`}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Conținut"
                        name={['i18n', language, 'content']}
                        initialValue={parseI18n?.[language]?.content}
                      >
                        <Input
                          style={{ width: '100%' }}
                          placeholder={`Introduceți contentul [${language}]`}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Titlul butonului"
                        name={['i18n', language, 'buttonTitle']}
                        initialValue={parseI18n?.[language]?.buttonTitle}
                      >
                        <Input
                          style={{ width: '100%' }}
                          placeholder={`Introduceți titlul butonului [${language}]`}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Link"
                        name={['i18n', language, 'link']}
                        initialValue={parseI18n?.[language]?.link}
                      >
                        <Input
                          style={{ width: '100%' }}
                          placeholder={`Introduceți link-ul [${language}]`}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              );
            })}
          </Tabs>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Tip"
            name="type"
            initialValue={data?.type}
            rules={[
              {
                required: true,
                message: 'Selectarea unui tip este obligatorie',
              },
            ]}
          >
            <Select showSearch placeholder="Selectați un tip">
              {Object.values(BannerType).map((el, i) => (
                <Select.Option key={i} value={el}>
                  {bannerTypeName[el]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="URL imagine" name="images" initialValue={data?.image}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="sau încarcăți" name="images" hasFeedback={false}>
                <UploadImage
                  onChange={(image) => form.setFieldsValue({ image })}
                  value={form.getFieldValue('image')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default BannerForm;
