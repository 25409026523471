export const languages = ['ro', 'ru', 'en'] as const;

export const defaultLocale = languages[0];

export type Languages = 'ro' | 'ru' | 'en';

export const i18n: { [key: string]: any } = {
  language: {
    ro: 'Română',
    ru: 'Pусский',
    en: 'English',
  },
};

export const isLanguage = (str: string): str is Languages => {
  return languages.includes(str as Languages);
};
