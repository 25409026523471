import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { notification, Spin } from 'antd';
import { MenuForm } from 'components/Menu';
import api from 'lib/api/menu';
import { Properties } from 'types';

enum Operation {
  CREATE = 'create',
  EDIT = 'edit',
}

const EditPage: React.FC = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: Operation }>();
  const [menu, setMenu] = useState<Properties>({});

  useEffect(() => {
    if (id && tab === Operation.EDIT) {
      try {
        api.getMenuById(id).then((data) => setMenu(data));
      } catch (e) {
        history.push('/menu');

        return notification.error({ message: 'Menu inexistent' });
      }
    }
  }, [id, tab]);

  switch (tab) {
    case Operation.CREATE:
      return (
        <MenuForm
          onSaved={() => {
            notification.success({ message: 'Menu a fost creat cu succes' });
            history.push(`/menu`);
          }}
        />
      );
    case Operation.EDIT:
      return (
        <Spin spinning={!menu} delay={300}>
          <MenuForm
            initialValue={menu}
            onSaved={() => {
              notification.success({ message: 'Menu a fost salvat cu succes' });
              history.push(`/menu`);
            }}
          />
        </Spin>
      );

    default:
      return <Redirect to="/menu" />;
  }
};

export default EditPage;
