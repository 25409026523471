import React, { FC, forwardRef, useState, useEffect, useCallback } from 'react';
import { Tree, Input, Row, Col } from 'antd';
import { ArrayResult } from 'types';
import api, { Article } from 'lib/api/pages';
import ReOrder from './ReOrder';

const { TreeNode } = Tree;
export interface TreeNode {
  key: number;
  title: string;
  isLeaf?: boolean;
}

interface ArticlesTreeProps {
  value?: string[];
  activeTabKey: string;
  onChange?: (value: string[]) => void;
  selected?: TreeNode[];
}

/**
 * `ArticlesTree` mouting an tree component that dinammically loads
 * child nodes from tree items.
 * It also have an search bar to list pages with a certain name.
 *
 * By default, tree will have as data the first level of pages ..
 * .. and the current selected values from backend.
 *
 * Default selected values will persist on tree during current page edit.
 */
const ArticlesTree: FC<ArticlesTreeProps> = forwardRef(
  ({ activeTabKey, value, onChange }, ref: any) => {
    const [searchValue, setSearchValue] = useState('');
    const [treeData, setTreeData] = useState<TreeNode[]>([]);

    const onLoadData = (treeNode): Promise<void> =>
      new Promise((resolve) => {
        if (treeNode.props.children_objects) {
          resolve();
          return;
        }

        const level = treeNode.props.eventKey && +treeNode.props.eventKey;

        if (level) {
          fetchLevel(level, true).then((response) => {
            treeNode.props.dataRef.children_objects = response;

            setTreeData([...treeData]);
          });
        }

        resolve();
      });

    const renderTreeNodes = useCallback(
      (data: any) =>
        data &&
        data.map((item: any) => (
          <TreeNode
            key={item.key}
            title={
              <Row justify="space-between">
                <Col>{item.title}</Col>
                {value?.includes(item.key) && (
                  <Col>
                    <ReOrder id={item.key} />
                  </Col>
                )}
              </Row>
            }
          >
            {renderTreeNodes(item.children_objects)}
          </TreeNode>
        )),
      [],
    );

    const fetchLevel = useCallback(
      async (level?: number, hasParent?: boolean) => {
        const pages: ArrayResult<Article> = await api.all({
          page_size: '1000',
          parents__in: level ? [level] : undefined,
          has_parents: Boolean(hasParent),
          tags: level ? undefined : ['page'],
        });

        return pages.results.map((item) => ({
          key: item.id,
          title: item.i18n[activeTabKey] ? item.i18n[activeTabKey].title : '-',
        }));
      },
      [activeTabKey],
    );

    const handleSearch = useCallback(async () => {
      const pages: ArrayResult<Article> = await api.all({
        page_size: '1000',
        name_content_icontains: searchValue,
      });

      setTreeData(
        pages.results.map((item) => ({
          key: item.id,
          title: item.i18n[activeTabKey] ? item.i18n[activeTabKey].title : '-',
        })),
      );
    }, [searchValue, activeTabKey]);

    useEffect(() => {
      (async () => {
        const nodes = await fetchLevel(0);

        setTreeData(nodes);
      })();
    }, []);

    return (
      <div ref={ref}>
        <Input.Search
          placeholder="Search"
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
          onSearch={handleSearch}
          style={{ marginBottom: 8 }}
        />

        <Tree
          checkable
          loadData={onLoadData}
          checkedKeys={value?.map((i) => i.toString())}
          onCheck={(data) => onChange && onChange(data as string[])}
        >
          {renderTreeNodes(treeData)}
        </Tree>
      </div>
    );
  },
);

export default ArticlesTree;
