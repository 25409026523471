import React, { FC, useMemo, useContext } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { SessionContext } from 'context/utils';
import Layout from 'App/Layout';
import routes, { defaultPath } from './routes';
import { Roles } from 'lib/api/users';
import { loginPath } from '.';

export const RedirectToDefaultPath: FC = () => {
  const { pathname } = useLocation();
  const { session } = useContext(SessionContext);

  const publicRoutes = useMemo(() => routes.filter(({ isPublic }) => isPublic), []);

  if (!session.user || !session.isSessionValid) {
    /* No redirect for public routes */
    for (const route of publicRoutes) {
      if (pathname.includes(String(route.path))) {
        return null;
      }
    }

    return <Redirect to={loginPath} />;
  }

  const role = Roles.ADMINISTRATOR;

  return <Redirect to={defaultPath[role]} />;
};

/**
 * `PrivateRoutes` will render all private routes only if
 * there is a valid session detect and accountAccess setted.
 *
 * Otherwise, this component will redirect user to login path
 * to reauthenticate.
 */
const PrivateRoutes: FC = () => {
  const { session } = useContext(SessionContext);

  const privateRoutes = useMemo(() => {
    return routes.filter(({ isPublic }) => !isPublic);
  }, []);

  return (
    <Switch>
      {privateRoutes.map((r) => {
        const { name, component: Component, ...rest } = r;

        return (
          <Route
            key={name}
            render={(renderProps) => {
              if (!session.isSessionValid) {
                return <Redirect to="/authentication/login" />;
              }

              return (
                <Layout>
                  <Component {...renderProps} />
                </Layout>
              );
            }}
            {...rest}
            exact
          />
        );
      })}

      <Route path="**" component={RedirectToDefaultPath} />
    </Switch>
  );
};

export default PrivateRoutes;
