import * as React from 'react';
import { Button, Descriptions, Modal, notification } from 'antd';
import complaints, {
  Complaint,
  PersonType,
  PetitionComplaintType,
  ReplyType,
  type,
} from 'lib/api/complaints';
import { getTokenFromUrl } from 'utils';
import { getTokens } from 'lib/api/utils';
import moment from 'moment';

interface Props {
  id?: number;
  onClose: () => void;
}

export const ComplaintModal: React.FC<Props> = ({ id, onClose }) => {
  const sassAppToken = getTokenFromUrl();
  const tokens = getTokens();
  const token = tokens ? tokens.access : null;
  const [data, setData] = React.useState<Complaint>();

  const fetch = React.useCallback(async () => {
    try {
      if (id) {
        setData(await complaints.byId(id));
      }
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [id]);
  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const replayType = React.useMemo(() => {
    switch (data?.reply_type.toString()) {
      case ReplyType.BY_EMAIL:
        return 'Prin e-mail';
      case ReplyType.BY_PHONE:
        return 'Prin telefon';
      case ReplyType.BY_POST:
        return 'Prin posta';
      default:
        return 'Nici o metoda selectata';
    }
  }, [data]);

  const personType = React.useMemo(() => {
    switch (data?.person_type.toString()) {
      case PersonType.LEGALLY:
        return 'Juridica';
      case PersonType.PHYSICS:
        return 'Fizica';
      default:
        return 'Nici un tip selectat';
    }
  }, [data]);

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
      destroyOnClose
      width="60vw"
    >
      <Descriptions title={data && type(data.type?.toString() as PetitionComplaintType)} bordered>
        {data?.id && (
          <Descriptions.Item label="#" span={12}>
            {data.id}
          </Descriptions.Item>
        )}
        {data?.fullname && (
          <Descriptions.Item label="Nume, Prenume" span={12}>
            {data.fullname}
          </Descriptions.Item>
        )}
        {data?.email && (
          <Descriptions.Item label="E-mail" span={12}>
            {data.email}
          </Descriptions.Item>
        )}
        {data?.address && (
          <Descriptions.Item label="Adresă" span={12}>
            {data.address}
          </Descriptions.Item>
        )}
        {data?.code && (
          <Descriptions.Item label="Cod" span={12}>
            {data.code}
          </Descriptions.Item>
        )}
        {data?.data?.weight && (
          <Descriptions.Item label="Greutate" span={12}>
            {data.data.weight}
          </Descriptions.Item>
        )}
        {data?.data?.reference_type && (
          <Descriptions.Item label="Tip referinta" span={12}>
            {data.data?.reference_type}
          </Descriptions.Item>
        )}
        {data?.data?.declared_value && (
          <Descriptions.Item label="Valoarea declarata" span={12}>
            {data.data.declared_value}
          </Descriptions.Item>
        )}
        {data?.data?.repayment && (
          <Descriptions.Item label="Rambursare" span={12}>
            {data.data.repayment}
          </Descriptions.Item>
        )}
        {data?.data?.post_office_address && (
          <Descriptions.Item label="Adresa oficiului Postal" span={12}>
            {data.data.post_office_address}
          </Descriptions.Item>
        )}
        {data?.data?.voucher_number && (
          <Descriptions.Item label="Numar bon" span={12}>
            {data.data.voucher_number}
          </Descriptions.Item>
        )}
        {data?.data?.tax && (
          <Descriptions.Item label="Taxa" span={12}>
            {data.data.tax}
          </Descriptions.Item>
        )}
        {data?.data?.content_value && (
          <Descriptions.Item label="Valoarea continutului" span={12}>
            {data.data.content_value}
          </Descriptions.Item>
        )}
        {data?.data?.contents_detailed_value && (
          <Descriptions.Item label="Lista detailata a continutului" span={12}>
            {data.data.contents_detailed_value}
          </Descriptions.Item>
        )}
        {data?.person_type && (
          <Descriptions.Item label="Tip persoana" span={12}>
            {personType}
          </Descriptions.Item>
        )}
        {data?.reply_type && (
          <Descriptions.Item label="Tipul de raspuns asteptat" span={12}>
            {replayType}
          </Descriptions.Item>
        )}
        {data?.phone_number && (
          <Descriptions.Item label="Numarul de telefon a depunatorului" span={12}>
            {data.phone_number}
          </Descriptions.Item>
        )}
        {data?.title && (
          <Descriptions.Item label="Titlu" span={12}>
            {data.title}
          </Descriptions.Item>
        )}
        {data?.description && (
          <Descriptions.Item label="Descrierea" span={12}>
            {data.description}
          </Descriptions.Item>
        )}
        {data?.inquiry && (
          <Descriptions.Item label="Coletul este primit?" span={12}>
            {data.inquiry ? 'Da' : 'Nu'}
          </Descriptions.Item>
        )}
        {data?.reason && (
          <Descriptions.Item label="Motivul" span={12}>
            {data.reason}
          </Descriptions.Item>
        )}
        {data?.sender_name && (
          <Descriptions.Item label="Numele expediatorului" span={12}>
            {data.sender_name}
          </Descriptions.Item>
        )}
        {data?.sender_address && (
          <Descriptions.Item label="Adresa expediatorului" span={12}>
            {data.sender_address}
          </Descriptions.Item>
        )}
        {data?.sender_phone && (
          <Descriptions.Item label="Numarul de telefon a expediatorului" span={12}>
            {data.sender_phone}
          </Descriptions.Item>
        )}
        {data?.sender_email && (
          <Descriptions.Item label="E-mailul a expediatorului" span={12}>
            {data.sender_email}
          </Descriptions.Item>
        )}
        {data?.recipient_name && (
          <Descriptions.Item label="Numele destinatarului" span={12}>
            {data.recipient_name}
          </Descriptions.Item>
        )}
        {data?.recipient_address && (
          <Descriptions.Item label="Adresa destinatarului" span={12}>
            {data.recipient_address}
          </Descriptions.Item>
        )}
        {data?.recipient_phone && (
          <Descriptions.Item label="Numarul de telefon a destinatarului" span={12}>
            {data.recipient_phone}
          </Descriptions.Item>
        )}
        {data?.recipient_email && (
          <Descriptions.Item label="E-mailul destinatarului" span={12}>
            {data.recipient_email}
          </Descriptions.Item>
        )}

        {data?.date && (
          <Descriptions.Item label="Data" span={12}>
            {moment(data.date).format('DD/MM/YYYY')}
          </Descriptions.Item>
        )}
        {data?.timestamp && (
          <Descriptions.Item label="Data creării" span={12}>
            {moment(data.timestamp).format('DD/MM/YYYY, HH:mm')}
          </Descriptions.Item>
        )}
        {data?.attachments &&
          data?.attachments.map((el, i) => (
            <Descriptions.Item key={i} label="Încărcați acest fișier" span={12}>
              <a
                href={`${el.url}/?token=${token}&saas-app-token=${sassAppToken}`}
                target="_blank"
                rel="noreferrer"
                download
              >
                {`${el.upload}`.split('/').slice(-1).join('')}
              </a>
            </Descriptions.Item>
          ))}
      </Descriptions>
    </Modal>
  );
};
