import api from '.';
import env from '@beam-australia/react-env';
import { EnvApi, User } from 'types';
import { stringifyUrl } from 'utils';
import { NonFieldsError, FieldsValidationError } from './utils';

export enum Roles {
  ADMINISTRATOR = 1,
  CONTENT_MANAGER = 2,
}

export default {
  getAll: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl('users', params, { api: EnvApi.API_MAIN_URL }),
    );

    return data;
  },

  partialUpdate: async (id: number, user: User): Promise<[User | null, any]> => {
    let error;
    let $user: User | null = null;

    try {
      const { data, statusText } = await api.request.patch(
        stringifyUrl(`users/${id}/`, {}, {}),
        user,
      );

      if (statusText === 'OK') {
        $user = data;
      } else {
        error = data;
      }
    } catch (e: any) {
      const { response = {} } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, user);
      } else {
        error = e.message;
      }
    }

    return [$user, error];
  },

  create: async (user: User): Promise<[User | null, any]> => {
    let error;
    let $user: User | null = null;

    const dashUrl = env('BASE_DASH_URL') || window.location.origin;

    try {
      const { statusText, data } = await api.request.post(stringifyUrl('user/', {}, {}), {
        ...user,
        url: `${dashUrl}/recovery-password`,
      });

      if (statusText === 'Created') {
        $user = data;
      } else {
        error = data;
      }
    } catch (e: any) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, user);
      } else {
        error = message;
      }
    }

    return [$user, error];
  },

  /**
   * Remove user.
   */
  remove: async (id: number) => {
    await api.request.delete(stringifyUrl(`users/${id}`, {}, { api: EnvApi.API_MAIN_URL }));
  },

  /**
   * Block user.
   */
  block: async (user: User): Promise<[User | null, any]> => {
    let error, data;

    try {
      const { id, ...rest } = user;
      if (id) {
        ({ data } = await api.request.post(
          stringifyUrl(`users/${id}/block`, {}, { api: EnvApi.API_MAIN_URL }),
          rest,
        ));
      }
    } catch (e: any) {
      const { response } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, user);
      } else {
        error = e.message;
      }
    }

    return [error, data];
  },

  unblock: async (user: User): Promise<[User | null, any]> => {
    let error, data;

    try {
      const { id, ...rest } = user;
      if (id) {
        ({ data } = await api.request.post(
          stringifyUrl(`users/${id}/unblock`, {}, { api: EnvApi.API_MAIN_URL }),
          rest,
        ));
      }
    } catch (e: any) {
      const { response } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, user);
      } else {
        error = e.message;
      }
    }

    return [error, data];
  },

  /**
   * Reset password of an certain user.
   */
  resetPass: async (id: number, values = {}): Promise<[any]> => {
    let error;

    try {
      const response = await api.request.post(`/user/${id}/reset-pass/`, values);

      if (response.statusText === 'OK') {
        error = null;
      }
    } catch (e: any) {
      const { response } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, values);
      } else {
        error = e.message;
      }
    }

    return [error];
  },
};
