import React, { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Row, Col, Tooltip } from 'antd';
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  CopyOutlined,
  MergeCellsOutlined,
} from '@ant-design/icons';
import { copy, read } from 'components/Articles/Builder/components';
import { BuilderContext, BuilderProvider, BuilderAction } from 'context/Builder';

import { Drag, Drop, titles } from './components';

const Builder = ({ activeTabKey, value = [], type, code, onChange, onChangeCode }) => {
  const { state, tabKey, onExpandAll, dispatch } = useContext(BuilderContext);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!tabKey && code.length) {
      dispatch({
        type: BuilderAction.SET_TAB,
        value: '0',
      });
    }
  }, [tabKey, code]);

  useEffect(() => {
    dispatch({
      type: BuilderAction.SET_LANG,
      value: activeTabKey,
    });
  }, [activeTabKey]);

  useEffect(() => {
    if (value?.length && !initialized) {
      dispatch({
        type: BuilderAction.SET_STATE,
        value: {
          content: value
            .map((item) => ({ ...item, treeTitle: titles[item?.type] }))
            .sort(({ order: prevOrder }, { order }) =>
              prevOrder > order ? 1 : order > prevOrder ? -1 : 0,
            ),
          keys: {
            expanded: [],
            selected: value.map((_, i) => i.toString()),
          },
        },
      });
      setInitialized(true);
    }
  }, [value, initialized]);

  const onSubmit = (content) => {
    dispatch({
      type: BuilderAction.SET_STATE,
      value: {
        content,
        drag: {
          type: undefined,
          is: false,
        },
      },
    });
    onChange(content);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Row
        gutter={[16, 16]}
        className={`full-width${state.isFullScreen ? ' full-screen' : ''}`}
        style={{ width: `calc(100% + 16px)` }}
      >
        <Col xs={24} md={5}>
          <Drag name="components" state={state} />
        </Col>
        <Col xs={24} md={19}>
          <Row
            className="drag-tree__bar"
            gutter={[8, 8]}
            justify="end"
            style={{ paddingBottom: 8 }}
          >
            <Col>
              <Row gutter={[4, 4]} align="middle">
                <Col>
                  <Button type="ghost" onClick={() => copy(JSON.stringify({ state }))}>
                    <CopyOutlined /> Copiază
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="ghost"
                    onClick={() =>
                      read().then((val) => {
                        try {
                          const { state } = JSON.parse(val);
                          if (state) {
                            onSubmit(state.content);
                          }
                        } catch {
                          console.log('Failed to paste!');
                        }
                      })
                    }
                  >
                    <MergeCellsOutlined /> Pastă
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[4, 4]} align="middle">
                <Col>
                  <Tooltip placement="topLeft" title="Ascunde componentele interne">
                    <Button
                      onClick={() =>
                        dispatch({
                          type: BuilderAction.SET_STATE,
                          value: {
                            keys: {
                              expanded: [],
                              selected: state.keys.selected,
                            },
                          },
                        })
                      }
                    >
                      <NodeCollapseOutlined /> Ascunde
                    </Button>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip placement="topLeft" title="Descoperă componentele interne">
                    <Button onClick={onExpandAll}>
                      <NodeExpandOutlined /> Descoperă
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                type={state.isFullScreen ? 'primary' : 'default'}
                onClick={() =>
                  dispatch({
                    type: BuilderAction.SET_STATE,
                    value: {
                      isFullscreen: state.isFullscreen,
                    },
                  })
                }
              >
                {state.isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ height: '100%' }}>
            <Col span={24}>
              <Drop
                type={type}
                code={code}
                state={state}
                tabKey={tabKey}
                initialValue={value}
                onChangeCode={onChangeCode}
                onSubmit={onSubmit}
                dispatch={dispatch}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DndProvider>
  );
};

export default (props) => (
  <BuilderProvider>
    <Builder {...props} />
  </BuilderProvider>
);
