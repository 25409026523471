import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Row, Col, Button, Tooltip } from 'antd';
import { copy, read } from 'components/Articles/Builder/components';
import {
  CopyOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MergeCellsOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
} from '@ant-design/icons';

import { Drag, Drop } from './components';
import { loopAll, normalizeTree } from './utils';
import './index.scss';

class Builder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragItem: undefined,
      selectedKey: [],
      expandedKeys: [],
      treeContent: [],
      mounted: false,
    };
  }

  componentDidUpdate() {
    if (!this.state.mounted && this.props.isEdit && this.props.value.length) {
      this.setState({
        treeContent: normalizeTree(this.props.value).sort(
          ({ data: prevData }, { data: currentData }) =>
            prevData.order > currentData.order ? 1 : currentData.order > prevData.order ? -1 : 0,
        ),
        mounted: true,
      });
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  handler = (state) =>
    this.setState((prevState) => ({
      ...prevState,
      ...state,
    }));

  onExpandAll = () => {
    let expandedKeys = [];

    loopAll(
      this.state.treeContent,
      ({ id, children }) => children?.length && expandedKeys.push(id.toString()),
    );

    this.setState({ expandedKeys });
  };

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Row
          gutter={[16, 16]}
          className={`full-width${this.state.isFullScreen ? ' full-screen' : ''}`}
          style={{ width: 'calc(100% + 16px)' }}
        >
          <Col span={6}>
            <Drag
              name="components"
              lang={this.props.activeTabKey}
              state={this.state}
              handler={this.handler}
              onDrag={() => this.setState({ isDrag: true })}
              onDrop={() => this.setState({ dragItem: undefined, isDrag: false })}
            />
          </Col>
          <Col span={18}>
            <Row
              className="drag-tree__bar"
              gutter={[8, 8]}
              justify="end"
              style={{ paddingBottom: 8 }}
            >
              <Col>
                <Row gutter={[4, 4]} align="middle">
                  <Col>
                    <Button
                      type="ghost"
                      onClick={() => copy(JSON.stringify({ state: this.state }))}
                    >
                      <CopyOutlined /> Copiază
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="ghost"
                      onClick={() =>
                        read().then((val) => {
                          try {
                            const { state } = JSON.parse(val);
                            if (state) {
                              this.setState({ ...state, dragItem: undefined });

                              this.props.onChange(state.treeContent);
                            }
                          } catch {
                            console.log('Failed to paste!');
                          }
                        })
                      }
                    >
                      <MergeCellsOutlined /> Pastă
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={[4, 4]} align="middle">
                  <Col>
                    <Tooltip placement="topLeft" title="Ascunde componentele interne">
                      <Button onClick={() => this.setState({ expandedKeys: [] })}>
                        <NodeCollapseOutlined /> Ascunde
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip placement="topLeft" title="Descoperă componentele interne">
                      <Button onClick={this.onExpandAll}>
                        <NodeExpandOutlined /> Descoperă
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
              {/* <Col>
                <Button
                  type={this.state.isFullScreen ? 'showPreview' : 'default'}
                  onClick={() => this.setState({ showPreview: !this.state.showPreview })}
                >
                  <RocketOutlined /> {this.state.showPreview ? 'Hide' : 'Preview'}
                </Button>
              </Col> */}
              <Col>
                <Button
                  type={this.state.isFullScreen ? 'primary' : 'default'}
                  onClick={() => this.setState({ isFullScreen: !this.state.isFullScreen })}
                >
                  {this.state.isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                </Button>
              </Col>
            </Row>
            <Row gutter={[8, 8]} style={{ height: '100%' }}>
              <Col span={24}>
                <Drop
                  lang={this.props.activeTabKey}
                  initialValue={this.props.value}
                  loading={this.props.loading}
                  state={this.state}
                  isDrag={this.state.isDrag}
                  handler={this.handler}
                  showIcon={this.props.showIcon}
                  onDrop={() => this.setState({ dragItem: undefined, isDrag: false })}
                  onSubmit={this.props.onChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DndProvider>
    );
  }
}

export default Builder;
