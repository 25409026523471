import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Form, Row, Col, Tabs, Button, Input, Select } from 'antd';
import api, { MenuType } from 'lib/api/menu';
import { languages } from 'lib/utils';
import { Properties } from 'types';

import Builder from './Builder';
import { normalizeTree } from './Builder/utils';
import { ArticleTagsCode } from 'lib/api/tags';

//const fields = ['title', 'content', 'preview'];

const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

const formItemStyle = {
  marginBottom: 0,
  lineHeight: 'initial',
};

interface MenuFormProps {
  /**
   * Article to edit, this is used only on first render.
   */
  initialValue?: Properties;
  loading?: boolean;

  /**
   * Called after succesful save.
   */
  onSaved: () => void;
}

const normalizeMenu = (data: Properties[]) =>
  data
    .sort(({ data: prevData }, { data: currentData }) =>
      prevData.order > currentData.order ? 1 : currentData.order > prevData.order ? -1 : 0,
    )
    .map((el) => {
      if (el.children) {
        el.children = normalizeMenu(el.children);
      }

      return el;
    });

const MenuForm: React.FC<MenuFormProps> = ({ initialValue, onSaved, loading }) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [activeTabKey, setActiveTabKey] = React.useState('ro');
  const [isLoading, setIsLoading] = React.useState(false);
  const [type, setType] = React.useState<string>();
  const [pageType, setPageType] = React.useState<ArticleTagsCode | null>(null);
  const [cache, setCache] = React.useState<Properties>();

  React.useEffect(() => {
    if (initialValue) {
      form.setFieldsValue({
        title: initialValue?.title,
        type: initialValue?.type || MenuType.HEADER,
        children: normalizeMenu(initialValue?.children || []),
      });
      setType(initialValue?.type);

      if (initialValue?.data?.isBusiness) {
        setPageType(ArticleTagsCode.BUSINESS);
      }

      if (initialValue?.data?.isInfo) {
        setPageType(ArticleTagsCode.DISCLOSURE_INFORMATION);
      }

      if (!cache && Object.keys(initialValue).length) {
        setCache(initialValue);
      }
    }
  }, [initialValue]);

  const onSubmit = async ({ children, ...values }: Properties): Promise<void> => {
    setIsLoading(true);

    (cache?.children || []).forEach(async (item: Properties) => await api.deleteMenu(item.id));

    let menuId: number;
    if (!id) {
      menuId = await api
        .addMenu({
          ...values,
          data: {
            ...(pageType === ArticleTagsCode.BUSINESS && { isBusiness: true }),
            ...(pageType === ArticleTagsCode.DISCLOSURE_INFORMATION && { isInfo: true }),
          },
        })
        .then((menu) => menu.id);
    } else {
      menuId = parseInt(id);

      await api.editMenu(
        {
          ...values,
          data: {
            ...(pageType === ArticleTagsCode.BUSINESS && { isBusiness: true }),
            ...(pageType === ArticleTagsCode.DISCLOSURE_INFORMATION && { isInfo: true }),
          },
        },
        id,
      );
    }

    const items = normalizeTree([...children], (item, _, i) => ({
      data: { ...item.data, order: i },
      article: item.id,
      ...(item.children && {
        children: item.children.map((child, y) => ({
          ...child,
          data: { ...child.data, order: y },
        })),
      }),
    }));

    await api
      .bulkUpdate({
        id: menuId,
        items,
      })
      .then(() => {
        setIsLoading(false);
        onSaved();
      });
  };

  const onValuesChange = (values) => {
    if (values.type) {
      setType(values.type);
    }
  };

  return (
    <Form
      form={form}
      {...formItemLayout}
      colon={false}
      onValuesChange={onValuesChange}
      onFinish={onSubmit}
    >
      <Row gutter={[16, 16]} justify="end">
        <Col span={24}>
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col>
              <Form.Item name="title" style={formItemStyle}>
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={[16, 16]} align="middle" justify="end">
                <Col span={8}>
                  <Form.Item name="type" initialValue={MenuType.HEADER} style={formItemStyle}>
                    <Select placeholder="Type">
                      <Select.Option value={MenuType.TOP}>Top</Select.Option>
                      <Select.Option value={MenuType.HEADER}>Header</Select.Option>
                      <Select.Option value={MenuType.SIDEBAR}>Sidebar</Select.Option>
                      <Select.Option value={MenuType.FOOTER}>Footer</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Select
                    placeholder="Type"
                    value={pageType}
                    onChange={setPageType}
                    style={{ width: '100%' }}
                  >
                    <Select.Option value={null}>Persoane fizice</Select.Option>
                    <Select.Option value={ArticleTagsCode.BUSINESS}>Business</Select.Option>
                    <Select.Option value={ArticleTagsCode.DISCLOSURE_INFORMATION}>
                      Dezvăluirea informaţiei
                    </Select.Option>
                  </Select>
                  {/* <Switch
                    className="business-switch"
                    checkedChildren="Business"
                    unCheckedChildren="Persoane fizice"
                    checked={isBusiness}
                    onChange={setIsBusiness}
                  /> */}
                </Col>
                <Col>
                  <Link to="/menu">
                    <Button>Cancel</Button>
                  </Link>
                </Col>

                <Col>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="background-white" style={{ marginTop: 10, paddingBottom: 15 }}>
          <Row>
            <Col span={24}>
              <Tabs
                className="custom-tabs custom-tabs--hide-content"
                activeKey={activeTabKey}
                animated={false}
                onChange={setActiveTabKey}
                style={{ maxWidth: 'unset' }}
              >
                {languages.map((code) => {
                  return <TabPane tab={code.toUpperCase()} key={code} />;
                })}
              </Tabs>
            </Col>
            <Col span={24}>
              <Form.Item name="children" style={formItemStyle}>
                <Builder
                  showIcon={type === MenuType.SIDEBAR}
                  isEdit={!!id}
                  activeTabKey={activeTabKey}
                  loading={loading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default MenuForm;
