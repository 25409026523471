import React from 'react';
import { Table, Button } from 'antd';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import { ArrayResult } from '../lib/api';
import { Log } from '../types';

interface LogsTable {
  data?: ArrayResult<Log>;
  page: number;
  route: string;
}

const LogsTable: React.FC<LogsTable> = ({ data, page, route }) => {
  const { push } = useHistory();

  const onPageChange = (page: number): void => {
    push(`?page=${page}`);
  };

  return (
    <Table
      dataSource={data ? data.results : []}
      columns={[
        {
          title: 'ID',
          dataIndex: 'id',
        },
        {
          title: 'ID-ul afectat',
          dataIndex: 'affected_id',
        },
        {
          title: 'Metodă',
          dataIndex: 'method',
        },
        {
          title: 'Utilizator',
          render: ({ user: { email, first_name, last_name } }) =>
            `${email} (${first_name} ${last_name})`,
        },
        {
          title: 'Modificat',
          render: ({ log_date_time }) => moment(log_date_time).fromNow(),
        },
        {
          align: 'center',
          title: 'Acțiune',
          render: ({ id }) => (
            <Link to={`/logs/${route}/${id}`}>
              <Button type="primary">Vezi diferența</Button>
            </Link>
          ),
          width: 80,
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
        current: page,
        total: data ? data.count : 0,
        defaultPageSize: 10,
        onChange: onPageChange,
      }}
      rowKey="id"
    />
  );
};

export default React.memo(LogsTable);
