const download = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || '');
  link.setAttribute('target', '_blank');

  link.style.display = 'none';

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadBlobFile = async (response, fileName?: string, fileType?: string) => {
  try {
    const url = window.URL.createObjectURL(new Blob([response], { type: fileType }));
    download(url, fileName);
  } catch (err) {
    console.warn('Error downloading file: >>', err);
  }
};
