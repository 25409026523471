import React from 'react';
import { Form } from 'antd';
import { ArticleEditor } from 'components/Articles';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';

export default ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        wrapperCol={{ span: 24 }}
        name={[item.id, lang, 'text']}
        style={{ ...formItemStyle, width: 'calc(100% + 8px)' }}
      >
        <ArticleEditor height={60} />
      </Form.Item>
    </>
  );
};
