import React from 'react';
import { Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { Properties } from 'types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface PageActionsProps {
  type?: string;
  menu: Properties;
  onRemove: (menu: Properties) => void;
  vizualization?: boolean;
}

/**
 * `ArticleActions` are used in `Article table`.
 * Containing 3 buttons: edit, remove and view.
 *
 * `Edit` button will redirect user to `/pages/edit/id`
 * `Delete` button will remove data and trigger refetch table content.
 * `View` button is a link to the article for preview.
 */
const MenuActions: React.FC<PageActionsProps> = ({ menu, onRemove, type = 'menu' }) => {
  const history = useHistory();

  return (
    <Dropdown.Button
      overlay={
        <Menu>
          <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(menu)}>
            Șterge
          </Menu.Item>
        </Menu>
      }
      onClick={() => history.push(`/${type}/edit/${menu.id}`)}
    >
      <EditOutlined /> Editează
    </Dropdown.Button>
  );
};

export default MenuActions;
