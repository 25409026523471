import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Card,
  Form,
  Button,
  Input,
  message,
  Select,
  Space,
  Row,
  Col,
  Modal,
  notification,
} from 'antd';
import { PlusOutlined, MinusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ArrayResult } from 'lib/api';
import deposits, { NomenclaturesDeposits } from 'lib/api/deposit';
import nomenclatures, { NomenclatureCurrency } from 'lib/api/nomenclatures';
import { DepositRateForm } from 'components/DepositRateForm';
import { ValidationError } from 'errors';
import { Properties } from 'types';

import './style.css';

const formItemStyle = { width: '150px' };

enum Operation {
  CREATE = 'create',
  EDIT = 'edit',
}

const DepositForm: React.FC = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: Operation }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [currencies, setCurrencies] = React.useState<ArrayResult<NomenclatureCurrency>>();
  const [depositItem, setDepositItem] = React.useState<NomenclaturesDeposits>();
  const [openForm, setOpenForm] = React.useState(false);
  const [currencyId, setCurrencyId] = React.useState<number>();
  const [rateId, setRateId] = React.useState<number>();
  const [errors, setErrors] = React.useState<Properties[]>([]);

  const fetchCurrencies = React.useCallback(async () => {
    try {
      setCurrencies(await nomenclatures.getCurrencies());
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [id, tab, depositItem]);

  React.useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const fetchDepositById = React.useCallback(async () => {
    if (id && tab === Operation.EDIT) {
      try {
        deposits.getDepositById(Number(id)).then((deposit) => {
          setDepositItem(deposit);

          form.setFieldsValue({
            ...deposit,
            currencies: deposit.currencies.map((el) => ({ ...el, currency_id: el.currency.id })),
          });
        });
      } catch (e) {
        const { message: err = 'Error. The article could not be saved!' } = e;

        message.error(err);
      }
    }
  }, []);

  React.useEffect(() => {
    fetchDepositById();
  }, [fetchDepositById]);

  const handleSubmit = async ({ ...values }: NomenclaturesDeposits): Promise<void> => {
    setLoading(true);

    const [error, deposit] = await deposits.upsertDeposits({
      ...values,
      ...(id && { id: Number(id) }),
    });

    if (!error) {
      if (!id) {
        history.push(`/deposit/edit/${deposit?.id}`);
        location.reload();
      } else {
        Modal.success({ title: 'Deposit a fost editat cu succes' });
        history.push('/deposit');
      }
      setErrors([]);
    } else {
      if (error instanceof ValidationError) {
        form.setFields(error.fields);
        const err = error.fields.filter((err) => err.name.includes('currencies'))[0].errors as any;

        setErrors([...err]);
      } else {
        Modal.error({
          title: 'Procesul a eșuat.',
          content: error,
        });
      }
    }

    setLoading(false);
  };

  const onRemoveRate = async (id): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest articol?',
      onOk: async () => {
        try {
          await deposits.removeDepositRates(id as number);
          fetchDepositById();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const handleOnOpenModal = (currency_id, rate_id) => {
    setCurrencyId(currency_id);
    setRateId(rate_id);
    setOpenForm(true);
  };

  const handleOnSave = () => {
    handleCloseForm();
    fetchDepositById();
  };

  const handleCloseForm = () => {
    setCurrencyId(undefined);
    setRateId(undefined);
    setOpenForm(false);
  };

  return (
    <>
      {openForm && (
        <Modal
          title="Modal"
          visible={openForm}
          width="30%"
          okButtonProps={{ htmlType: 'submit', form: 'DepositRateForm' }}
          onCancel={handleCloseForm}
        >
          <DepositRateForm currencyId={currencyId} rateId={rateId} onSaved={handleOnSave} />
        </Modal>
      )}
      <Card>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={24} xl={12}>
              <Form.Item
                name="title"
                label="Titlu"
                rules={[{ required: true, message: 'Titlu este necesar!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24} xl={24}>
              <Form.List
                name="currencies"
                initialValue={[{ currency_id: '', minimum: '', maximum: '', terms: [''] }]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => {
                      return (
                        <Space key={key} align="start" wrap>
                          <Form.Item
                            {...restField}
                            name={[name, 'currency_id']}
                            label="Currency"
                            rules={[
                              {
                                required: true,
                                message: 'Valuta este necesara!',
                              },
                            ]}
                            style={formItemStyle}
                          >
                            <Select placeholder="Currency">
                              {currencies?.results?.map((currency) => (
                                <Select.Option key={currency.id} value={currency.id}>
                                  {currency.code}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label={
                              <>
                                <span className="deposit__asterisk">&#42;</span>Minimum
                              </>
                            }
                            name={[name, 'minimum']}
                            validateStatus={
                              !!errors.length && errors[name]?.minimum && errors[name]?.minimum[0]
                                ? 'error'
                                : 'success'
                            }
                            help={
                              !!errors.length && errors[name]?.minimum && errors[name]?.minimum[0]
                            }
                            style={formItemStyle}
                          >
                            <Input placeholder="minimum" type="number" />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label={
                              <>
                                <span className="deposit__asterisk">&#42;</span>Maximum
                              </>
                            }
                            name={[name, 'maximum']}
                            validateStatus={
                              !!errors.length && errors[name]?.maximum && errors[name]?.maximum[0]
                                ? 'error'
                                : 'success'
                            }
                            help={
                              !!errors.length && errors[name]?.maximum && errors[name]?.maximum[0]
                            }
                            style={formItemStyle}
                          >
                            <Input placeholder="maximum" type="number" />
                          </Form.Item>

                          <Form.List name={[name, 'terms']} initialValue={['']}>
                            {(terms, { add, remove }) => (
                              <div className="deposit__terms">
                                {terms.map((term) => (
                                  <Space key={term.key}>
                                    <Form.Item
                                      label="Terms"
                                      name={term.name}
                                      rules={[{ required: true, message: 'Termenul este necesar' }]}
                                      style={{ width: '100px' }}
                                    >
                                      <Input placeholder="maximum" type="number" />
                                    </Form.Item>

                                    <Button
                                      className="deposit__terms--minus"
                                      type="dashed"
                                      onClick={() => remove(term.name)}
                                      icon={<MinusOutlined />}
                                    />
                                  </Space>
                                ))}

                                <Button
                                  type="ghost"
                                  className="deposit__button"
                                  onClick={() => add()}
                                  icon={<PlusOutlined />}
                                >
                                  Adaugă term
                                </Button>
                              </div>
                            )}
                          </Form.List>

                          <div className="deposit__terms">
                            {depositItem?.currencies &&
                              depositItem?.currencies[key]?.rates?.map((r) => (
                                <Space key={r.id}>
                                  <Form.Item label="Rate" style={{ width: '150px' }}>
                                    <Input
                                      placeholder={`${r.maximum}/${r.minimum}/${r.rate}/${r.term}`}
                                      disabled
                                    />
                                  </Form.Item>

                                  <Button
                                    className="deposit__terms--minus"
                                    type="dashed"
                                    onClick={() =>
                                      handleOnOpenModal(
                                        depositItem?.currencies && depositItem?.currencies[key]?.id,
                                        r.id,
                                      )
                                    }
                                    icon={<EditOutlined />}
                                  />
                                  <Button
                                    className="deposit__terms--minus"
                                    type="dashed"
                                    onClick={() => onRemoveRate(r.id)}
                                    icon={<DeleteOutlined />}
                                  />
                                </Space>
                              ))}

                            <Button
                              className="deposit__button"
                              type="ghost"
                              onClick={() =>
                                handleOnOpenModal(
                                  depositItem?.currencies && depositItem?.currencies[name]?.id,
                                  undefined,
                                )
                              }
                              disabled={
                                !(depositItem?.currencies && depositItem?.currencies[name]?.id)
                              }
                            >
                              Adaugă rata
                            </Button>
                          </div>

                          <Button
                            className="deposit__button"
                            type="dashed"
                            onClick={() => remove(name)}
                            icon={<MinusOutlined />}
                          >
                            Șterge currency
                          </Button>
                        </Space>
                      );
                    })}
                    <Form.Item>
                      <Button type="primary" onClick={() => add()} icon={<PlusOutlined />}>
                        Adaugă currency
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>

          <Form.Item
            style={{
              width: '100%',
              textAlign: 'right',
            }}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Salvează
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default DepositForm;
