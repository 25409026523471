import React, { useCallback, useContext, useMemo, forwardRef } from 'react';
import CKEditor from 'ckeditor4-react';
import env from '@beam-australia/react-env';
import { SessionContext } from 'context/utils';

const fileUploadUrl = `${env('API_BASE_URL')}/v1/upload-file/?upload`;

interface ArticleEditorProps {
  features?: boolean;
  value?: string;
  height?: number;
  onChange?: (content: string) => void;
}

/**
 * This is the integration of CKEditor4 for editing article content.
 *
 * It has a default value, and will automatically update the form state
 */
const ArticleEditor: React.FC<ArticleEditorProps> = forwardRef(
  ({ value, height = 500, onChange = () => null, features = true }, ref) => {
    const state = useContext(SessionContext);
    const onBeforeLoad = useCallback((CKEDITOR: any) => {
      CKEDITOR.disableAutoInline = true;
    }, []);

    const config = useMemo(
      () => ({
        removeButtons: '',
        height,
        language: 'ro',
        allowedContent: true,
        extraPlugins:
          features &&
          'image2,justify,uploadimage,uploadfile,widget,widgetselection,clipboard,lineutils,maximize,font,basicstyles,colorbutton,autoembed,copyformatting',
        removePlugins: 'image',
        filebrowserUploadUrl: fileUploadUrl,
        filebrowserImageUploadUrl: fileUploadUrl,
        fileTools_requestHeaders: {
          Authorization: `Bearer ${state.session?.tokens!.access}`,
          'Saas-app-token': env('SASS_APP_TOKEN'),
        },
      }),
      [state.session, features, height],
    );

    return (
      <CKEditor
        ref={ref}
        data={value}
        onBeforeLoad={onBeforeLoad}
        config={config}
        onChange={(event: any) => onChange(event.editor.getData())}
        placeholder="Introduceți aici conținutul articolului"
      ></CKEditor>
    );
  },
);

export default ArticleEditor;
