import api, { SessionTokens } from '.';
import { stringifyUrl } from 'utils';
import { EnvApi } from 'types';
import env from '@beam-australia/react-env';
import { NonFieldsError, FieldsValidationError } from './utils';
import { ValidationError } from 'errors';

export default (): any => ({
  /**
   * This function handles authentification and returns
   * an array which contains tokens or errors.
   */
  login: async (email: string, password: string) => {
    let tokens: SessionTokens | null = null;
    let error;

    try {
      const response = await fetch(
        stringifyUrl(`authentication/login`, {}, { api: EnvApi.API_MAIN_URL }),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Saas-app-token': `${env('SASS_APP_TOKEN')}`,
          },
          body: JSON.stringify({
            username: email,
            password,
          }),
        },
      );
      const data = await response.json();

      if (response.status === 200 || response.statusText === 'OK') {
        tokens = data;
      } else {
        if (Object.keys(data).includes('detail')) {
          error = data;
        } else {
          const body =
            typeof data === 'string'
              ? JSON.parse(data) // this will fail if string is not valid JSON.
              : data;
          error = new ValidationError(body, data, data.non_field_errors);
        }
      }
    } catch (e: any) {
      const { message, response = {} } = e;
      console.error(message, response);
    }

    return [tokens, error];
  },

  /**
   * This function handles registration and returns
   * an array which contains tokens or errors.
   */

  register: async (username, password, confirm_password, first_name, last_name, token) => {
    let tokens: SessionTokens | null = null;
    let error;

    try {
      const response = await fetch(
        stringifyUrl('authentication/register', {}, { api: EnvApi.API_MAIN_URL }),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Saas-app-token': `${env('SASS_APP_TOKEN')}`,
          },
          body: JSON.stringify({
            username,
            password,
            confirm_password,
            first_name,
            last_name,
            token,
          }),
        },
      );

      const data = await response.json();
      if (response.status === 200 || response.statusText === 'OK') {
        tokens = data;
      } else {
        if (Object.keys(data).includes('detail')) {
          error = data;
        } else {
          const body =
            typeof data === 'string'
              ? JSON.parse(data) // this will fail if string is not valid JSON.
              : data;
          error = new ValidationError(body, data, data.non_field_errors);
        }
      }
    } catch (e: any) {
      const { message, response = {} } = e;
      console.error(message, response);
    }

    return [tokens, error];
  },

  resetPassowrd: async (
    token: string,
    values: {
      new_password1: string;
      new_password2: string;
    },
  ) => {
    let error;

    try {
      const { statusText } = await api.request.post(`/v1/user/token_recevier/${token}/`, values);

      error = statusText !== 'OK';
    } catch (e: any) {
      const { response } = e;

      error = e.message;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, values);
      }
    }

    return error;
  },
  resetByEmail: async ({ email, url }: { email: string; url: string }) => {
    try {
      const { statusText } = await api.request.post('/v1/user/reset-by-email/', {
        email,
        url,
      });

      return statusText === 'OK';
    } catch (e: any) {
      return false;
    }
  },
});
