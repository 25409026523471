import React, { useState } from 'react';
import { Form, Input, Button, Card, Modal, Row, Col } from 'antd';
import { LockTwoTone } from '@ant-design/icons';
import api from 'lib/api';
import { useHistory } from 'react-router-dom';
import { loginPath } from 'lib/router';
import { FieldsValidationError } from 'lib/api/utils';
import { Properties } from 'types';

const { auth } = api;

interface ResetPasswordProps {
  token: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ token }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { getFieldValue, validateFields } = form;
  const [confirmDirty, setConfirmDirty] = useState(false);

  const compareToFirstPassword = (rule: any, value: any, callback: any): void => {
    if (value && value !== getFieldValue('new_password1')) {
      callback('Parolele introduce nu coincid!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: any, callback: any): void => {
    if (value && confirmDirty) {
      validateFields(['new_password2']);
    }
    callback();
  };

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const redirectToLoginPath = (): void => {
    history.push(loginPath);
  };

  const handleSubmit = async (values: Properties): Promise<void> => {
    const error = await auth.resetPassowrd(token, values);

    if (!error) {
      Modal.success({
        title: 'Parola a fost resetată cu succes.',
        content: 'Veți fi redirecționat imediat pentru a vă autentifica.',
        onOk: redirectToLoginPath,
      });
    } else {
      if (error instanceof TypeError) {
        Modal.error({
          title: 'Procesul a eșuat.',
        });
      } else if (error instanceof FieldsValidationError) {
        //form.setFields(error.fields);
      } else {
        Modal.error({
          title: 'Resetea parolei a eșuat. Încercați din nou!',
          onOk: redirectToLoginPath,
        });
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <Card title="Introduceți parola nouă mai jos">
        <Form
          onFinish={handleSubmit}
          style={{ width: 300, maxWidth: '90%', height: 'fit-content' }}
        >
          <Form.Item
            name="new_password1"
            rules={[
              {
                required: true,
                message: 'Introduceți parola nouă',
              },
              {
                validator: validateToNextPassword,
              },
            ]}
          >
            <Input
              prefix={<LockTwoTone style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="new_password2"
            rules={[
              {
                required: true,
                message: 'Confrimați parola',
              },
              {
                validator: compareToFirstPassword,
              },
            ]}
          >
            <Input
              prefix={<LockTwoTone style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              onBlur={handleConfirmBlur}
            />
          </Form.Item>

          <Row justify="space-between">
            <Col>
              <Button type="primary" htmlType="submit" className="login-form__button">
                Resetează parola
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPassword;
