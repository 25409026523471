import * as React from 'react';
import { SessionContext } from 'context/utils';
// import { Redirect } from 'react-router-dom';
// import { message } from 'antd';

// import { defaultPath } from './routes';
import { Roles } from '../api/users';

/**
 * withRouteGuard gets an array of reles and checks if
 * they have permission to access it.
 *
 * If they will get into unallowed route they will be
 * redirected to theirs default path based on user role.
 *
 * @param roles
 * Array with allowed roles.
 *
 * @example
 *    export default RouteGuard([Roles.CONTANTE_MANAGER])(Users);
 */
const withRouteGuard =
  <T extends React.FC>(roles: Roles[]) =>
  (Component: React.FC) =>
  (props: T): React.ReactNode => {
    console.log('roles', roles);

    return (
      <SessionContext.Consumer>
        {({ session }) => {
          console.log('session', session);
          // if (
          //   session.user &&
          //   session.user.profile &&
          //   session.user.profile.role &&
          //   !roles.includes(session.user.profile.role)
          // ) {
          //   const role = session.user.profile.role as Roles;
          //   message.error("You don't have permission to access this page");
          //   return <Redirect to={defaultPath[role]} />;
          // }

          return <Component {...props} />;
        }}
      </SessionContext.Consumer>
    );
  };

export default withRouteGuard;
