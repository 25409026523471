import api from '.';
import { stringifyUrl } from 'utils/query';
import { EnvApi, Properties } from 'types';
import { I18n } from './pages';
import { NomenclatureCurrency } from './nomenclatures';
import { ValidationError } from 'errors';

export interface NomenclatureDepositRate {
  id?: number;
  currency: number;
  rate?: string;
  minimum?: string;
  maximum?: string;
  term?: number;
}
export interface NomenclatureDepositCurrency {
  id?: number;
  currency?: NomenclatureCurrency;
  currency_id: number;
  minimum?: string;
  maximum?: string;
  terms?: number[];
  rates?: NomenclatureDepositRate[];
}

export interface NomenclatureAttachment {
  url?: string;
  title?: string;
}

export interface NomenclaturesDeposits {
  id?: number;
  i18n?: I18n;
  title: string;
  currencies?: NomenclatureDepositCurrency[];
  attachment?: NomenclatureAttachment;
  attachment_id?: number;
  description?: string;
  slug?: string;
  data?: Properties;
}

export default {
  getDeposits: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(
        'nomenclatures/deposits',
        { limit: 10, ordering: '-timestamp', ...params },
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  getDepositById: async (id: number) => {
    const { data } = await api.request.get(
      stringifyUrl(`nomenclatures/deposits/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );

    return data;
  },

  upsertDeposits: async (deposit: NomenclaturesDeposits) => {
    let error, data;

    try {
      const { id, ...values } = deposit;
      if (id) {
        ({ data } = await api.request.patch(
          stringifyUrl(`nomenclatures/deposits/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
          values,
          {
            headers: {
              'Accept-Language': 'ro',
            },
          },
        ));
      } else {
        ({ data } = await api.request.post(
          stringifyUrl('nomenclatures/deposits', {}, { api: EnvApi.API_MAIN_URL }),
          deposit,
          {
            headers: {
              'Accept-Language': 'ro',
            },
          },
        ));
      }
    } catch (e: any) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;

        const body =
          typeof data === 'string'
            ? JSON.parse(data) // this will fail if string is not valid JSON.
            : data;

        error = new ValidationError(body, data, data.non_field_errors);
      } else {
        error = message;
      }
    }

    return [error, data];
  },

  removeDeposit: async (id: number) => {
    await api.request.delete(
      stringifyUrl(`nomenclatures/deposits/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );
  },

  getDepositsRates: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(
        'nomenclatures/deposit/rates',
        { limit: '10', ...params },
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  getDepositRatesById: async (id: number) => {
    const { data } = await api.request.get(
      stringifyUrl(`nomenclatures/deposit/rates/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );

    return data;
  },

  upsertDepositsRates: async (deposit: NomenclatureDepositRate) => {
    let error, data;

    try {
      const { id, ...values } = deposit;
      if (id) {
        ({ data } = await api.request.patch(
          stringifyUrl(`nomenclatures/deposit/rates/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
          values,
          {
            headers: {
              'Accept-Language': 'ro',
            },
          },
        ));
      } else {
        ({ data } = await api.request.post(
          stringifyUrl('nomenclatures/deposit/rates', {}, { api: EnvApi.API_MAIN_URL }),
          deposit,
          {
            headers: {
              'Accept-Language': 'ro',
            },
          },
        ));
      }
    } catch (e: any) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;

        const body =
          typeof data === 'string'
            ? JSON.parse(data) // this will fail if string is not valid JSON.
            : data;

        error = new ValidationError(body, data, data.non_field_errors);
      } else {
        error = message;
      }
    }

    return [error, data];
  },

  removeDepositRates: async (id: number) => {
    await api.request.delete(
      stringifyUrl(`nomenclatures/deposit/rates/${id}`, {}, { api: EnvApi.API_MAIN_URL }),
    );
  },
};
