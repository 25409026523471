import React, { useEffect, useState } from 'react';
import { Button, Row, Col, List, Card, Upload, message } from 'antd';
import env from '@beam-australia/react-env';
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import api from 'lib/api';
import { getBase64 } from 'utils';
import { Properties } from 'types';

import { Link } from './';
const REACT_APP_API_BASE_URL = env('API_BASE_URL');
const maxImageSizeInMb = 4;

interface PaymentsProps {
  lang: string;
  value?: Properties[];
  onChange?: (value: Properties[]) => void;
}

const Payments: React.FC<PaymentsProps> = (props) => {
  const [state, setState] = useState(props?.value || []);

  const [loading, setLoading] = useState(false);

  const handleChange = (info: Properties) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
  };

  const handleUpload = async (file: any, i: number): Promise<void> => {
    setLoading(true);
    try {
      const data = await api.pages.uploadFile(file);
      const { id: uid, upload, uploaded } = data;

      const newFile: UploadFile<any> = {
        uid,
        size: 1,
        name: upload,
        status: uploaded ? 'done' : 'error',
        url: `${REACT_APP_API_BASE_URL}/v1${upload}`,
        type: 'image',
      };

      onChange(
        [...state].map((tabDesription, index) => {
          if (index === i) {
            tabDesription.image = newFile;
          }

          return tabDesription;
        }),
      );
    } catch (e) {
      message.error('Unsuccessfull image upload!');
    }
    setLoading(false);
  };

  const handleBeforeUpload = (file: any, i: number): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Puteți încărca doar imagini în formatul JPG/PNG!');
      return false;
    }

    const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
    if (!isMbLt) {
      message.error(`Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`);
      return false;
    }
    handleUpload(file, i);

    return false;
  };

  useEffect(() => {
    if (props?.value && JSON.stringify(props.value) !== JSON.stringify(state)) {
      setState(props.value);
    }
  }, [props]);

  const onChange = (value) => {
    setState(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Card size="small" bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
      <List
        itemLayout="horizontal"
        dataSource={state}
        renderItem={(tabItem, i) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={(file) => handleBeforeUpload(file, i)}
                  onChange={handleChange}
                >
                  {tabItem?.image?.url ? (
                    <img src={`${tabItem.image.url}`} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    <div>
                      {loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div className="ant-upload-text">Încărca</div>
                    </div>
                  )}
                </Upload>
              }
              title={
                <Row wrap={false} gutter={[16, 16]} align="middle">
                  <Col className="full-width">
                    <Link
                      lang={props?.lang}
                      value={tabItem.link}
                      onChange={(link) =>
                        onChange(
                          [...state].map((tabDesription, index) => {
                            if (index === i) {
                              tabDesription.link = link;
                            }

                            return tabDesription;
                          }),
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => onChange([...state].filter((_, index) => index !== i))}
                    />
                  </Col>
                </Row>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default Payments;
