import { default as queryString } from 'query-string';
import env from '@beam-australia/react-env';
import { Properties, EnvApi, VersionType } from '../types';

type Settings = {
  version?: VersionType;
  api?: EnvApi;
};

export const stringifyUrl = (
  url: string,
  query: null | Properties = {},
  { api, version = 1 }: Settings,
): string => {
  return queryString.stringifyUrl(
    {
      url: `${api ? env(api) : `v${version}`}/${url}`,
      ...(query && { query }),
    },
    { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
  );
};

export const defaultOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
};
