import React from 'react';
import { Form, Checkbox, Input } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';

export default ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        label="Titlu"
        name={[item.id, lang, 'header']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți titlul`} />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'collapsed']}
        valuePropName="checked"
        label="Închis"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  collapsed: !item.collapsed,
                },
              },
            });
          }}
        />
      </Form.Item>
    </>
  );
};
