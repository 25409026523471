import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { Form as IForm, Row as IRow, Col } from 'antd';
import cn from 'classnames';
import { Languages } from 'lib/utils';
import { Properties } from 'types';

import { merge } from './utils';
import {
  Avatar,
  Button,
  GenericFields,
  Collapse,
  Card,
  Label,
  Link,
  Video,
  Responsive,
  Space,
  Text,
  Image,
  Row,
  Service,
  Contact,
  Credit,
  News,
  Deposit,
  Calculator,
  FAQ,
  PaymentCard,
  Container,
  Section,
  Slider,
  Form,
  Special,
  PaymentMethods,
  Information,
  ContactUs,
} from '../Fields';
import { formItemLayout } from '../helpers';
import { loopChildren, loopObjects } from '../utils';

interface ItemFormProps {
  initialValue: Properties;
  state: Properties;

  item: Properties;
  lang: Languages;
  isEdit: boolean;

  onSaved: (values: Properties) => void;
  handler: (values: Properties) => void;
}

const ItemForm: React.FC<ItemFormProps> = ({
  lang,
  item,
  initialValue,
  state,
  isEdit,
  handler,
  onSaved,
}) => {
  const [form] = IForm.useForm();
  const [params, setParams] = useState({});

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        [item.id]: {
          [lang]: item,
        },
      });
    }
  }, [item, lang]);

  const [, cancel] = useDebounce(
    () => {
      if (Object.keys(params).length) {
        const props = form.getFieldsValue();
        let values = { ...initialValue };
        let data = [...state.treeContent];

        values = loopObjects(
          values,
          item.id,
          (item) => {
            if (props) {
              Object.keys(props).forEach((id) => {
                item = {
                  ...item,
                  ...merge(
                    { ...(params && params[id] && params[id][lang]) },
                    { ...(props[id] && props[id][lang]) },
                  ),
                };
              });
            }

            data = loopChildren(data, item.id, (treeItem) => {
              treeItem = { ...treeItem, ...item };

              return treeItem;
            });

            return item;
          },
          lang,
        );

        onSaved(values);
        handler({ treeContent: data });
        setParams({});
      }
    },
    350,
    [params],
  );

  const onChange = useCallback(
    (params) => {
      cancel();
      setParams(params);
    },
    [cancel],
  );

  const props = useMemo(
    () => ({
      lang,
      item,
      setFieldsValue: onChange,
      onSaved,
    }),
    [item, lang, onChange, onSaved],
  );

  const hasOptions = useMemo(
    () =>
      item &&
      [
        'moduleOrder',
        'bannerHome',
        'bannerDownload',
        'templateInfo',
        'templateText',
        'componentCollapse',
        'templateConfirmation',
      ].includes(item.type),
    [item],
  );

  return (
    <div className={cn({ 'hide-options': !hasOptions })}>
      <IForm form={form} {...formItemLayout} colon={false} onFieldsChange={onChange}>
        <GenericFields {...props} />

        <IRow gutter={[16, 16]} justify="space-between">
          <Col span={24}>
            {item &&
              isEdit &&
              (() => {
                switch (item?.type) {
                  case 'componentCollapse':
                    return <Collapse {...props} />;
                  case 'componentAvatar':
                    return <Avatar {...props} />;
                  case 'componentButton':
                    return <Button {...props} />;
                  case 'componentCard':
                    return <Card {...props} />;
                  case 'componentLabel':
                    return <Label {...props} />;
                  case 'componentLink':
                    return <Link {...props} />;
                  case 'componentVideo':
                    return <Video {...props} />;
                  case 'componentResponsive':
                    return <Responsive {...props} />;
                  case 'componentSpace':
                    return <Space {...props} />;
                  case 'componentText':
                    return <Text {...props} />;
                  case 'componentImage':
                    return <Image {...props} />;
                  case 'componentContainer':
                    return <Container {...props} />;
                  case 'sectionRow':
                    return <Row {...props} />;
                  case 'contact':
                    return <Contact {...props} />;
                  case 'credit':
                    return <Credit {...props} />;
                  case 'news':
                    return <News {...props} />;
                  case 'deposit':
                    return <Deposit {...props} />;
                  case 'calculator':
                    return <Calculator {...props} />;
                  case 'faq':
                    return <FAQ {...props} />;
                  case 'service':
                    return <Service {...props} />;
                  case 'paymentCard':
                    return <PaymentCard {...props} />;
                  case 'section':
                    return <Section {...props} />;
                  case 'slider':
                    return <Slider {...props} />;
                  case 'form':
                    return <Form {...props} />;
                  case 'special':
                    return <Special {...props} />;
                  case 'paymentMethods':
                    return <PaymentMethods {...props} />;
                  case 'information':
                    return <Information {...props} />;
                  case 'contactUs':
                    return <ContactUs {...props} />;
                  default:
                    return null;
                }
              })()}
          </Col>
        </IRow>
      </IForm>
    </div>
  );
};

export default ItemForm;
