import React, { useContext, useState } from 'react';
import { Tree, Row, Col } from 'antd';
import { DragOutlined, FolderOpenOutlined, FolderOutlined } from '@ant-design/icons';
import { BuilderContext, BuilderAction } from 'context/Builder';

import Item from './Item';
import treeContent from './Fields';

const TreeNode = Tree.TreeNode;
const localSelectedKey = localStorage.getItem('selectedKey');

const Drag = () => {
  const { state, dispatch } = useContext(BuilderContext);
  const [selectedKey, setSelectedKey] = useState(
    localSelectedKey ? JSON.parse(localSelectedKey) : ['fields', 'input'],
  );

  const onMouseOver = (type) => {
    dispatch({
      type: BuilderAction.SET_STATE,
      value: {
        drag: {
          type,
          is: true,
        },
      },
    });
  };

  const onMouseOut = () => {
    dispatch({
      type: BuilderAction.SET_STATE,
      value: {
        drag: {
          type: undefined,
          is: false,
        },
      },
    });
  };

  const getTreeNode = (treeContent) => {
    if (!treeContent || treeContent.length === 0) {
      return null;
    }

    const treeNode = treeContent.map((value) => {
      const disabled = !!state.content.find(
        (item) => item.type === value.type && !value.isMultiple,
      );

      return (
        <TreeNode
          key={value.id}
          title={
            <Item
              canDrag={
                value.canDrag || (typeof value.canDrag === 'undefined' && !value?.children?.length)
              }
              value={value}
              onMouseOver={() => onMouseOver(value.type)}
              onMouseOut={onMouseOut}
              disabled={disabled}
            />
          }
          className="drag-tree__node"
          icon={
            <Row gutter={8}>
              {value?.children?.length ? (
                <Col>
                  {selectedKey.includes(value.type) ? <FolderOpenOutlined /> : <FolderOutlined />}
                </Col>
              ) : null}
              {value.canDrag ||
              (typeof value.canDrag === 'undefined' && !value?.children?.length) ? (
                <Col>
                  <DragOutlined />
                </Col>
              ) : null}
            </Row>
          }
          showIcon={false}
          disabled={disabled}
        >
          {getTreeNode(value?.children || [], true)}
        </TreeNode>
      );
    });

    return treeNode;
  };

  const onNodeSelect = (selectedKeys) => {
    const value = selectedKey.some((i) => selectedKeys.includes(i))
      ? selectedKey.filter((i) => !selectedKeys.includes(i))
      : [...selectedKey, ...selectedKeys];

    localStorage.setItem('selectedKey', JSON.stringify(value));

    setSelectedKey(value);
  };

  return (
    <div className="drag-tree">
      <Tree
        showLine
        blockNode
        showIcon
        expandedKeys={selectedKey}
        onExpand={onNodeSelect}
        onSelect={onNodeSelect}
      >
        {getTreeNode(treeContent)}
      </Tree>
    </div>
  );
};

export default Drag;
