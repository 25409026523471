import React, { useEffect, useState, useCallback } from 'react';
import api, { ArrayResult } from 'lib/api';
import { useLocation } from 'react-router-dom';
import LogsTable from 'components/LogsTable';
import { notification } from 'antd';
import { Log } from 'types';

interface Props {
  api: string;
  route: string;
}

const PageLogs: React.FC<Props> = ({ api: apiRoute, route }) => {
  const [data, setData] = useState<ArrayResult<Log>>();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page')) || 1;

  const fetchLogs = useCallback(async () => {
    try {
      setData(await api[apiRoute as keyof typeof api].getLogs(page));
    } catch (e) {
      const { message }: any = e || {};

      notification.error({ message });
    }
  }, [page, api, apiRoute]);

  useEffect(() => {
    if (apiRoute && apiRoute in api) {
      fetchLogs();
    }
  }, [fetchLogs, apiRoute, api]);

  return <LogsTable data={data} page={page} route={route} />;
};

export default PageLogs;
