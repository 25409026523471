import React, { FC, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import menu from 'lib/api/menu';
import { ArrayResult, Properties } from 'types';
import Search from 'antd/lib/input/Search';
import { MenuActions } from 'components/Menu';
import { Table, Button, Modal, Row, Col, Tag, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';

const MenuPage: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<ArrayResult<Properties>>();
  const [loading, setLoading] = useState(false);

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;
  const tags = params.tags;

  const fetchArticles = useCallback(async () => {
    setLoading(true);

    try {
      setData(
        await menu
          .getMenu({
            ordering: 'id',
            page_size: 1000,
            parent_id__isnull: true,
            name_content_icontains: search,
            page,
            tags,
          })
          .then((response) => ({
            ...response,
            results: response.results.map((item) => {
              if (!item.children.length) {
                delete item.children;
              }

              return item;
            }),
          })),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }

    setLoading(false);
  }, [search, page, tags]);

  const onRemove = async ({ id }: Properties): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest menu?',
      onOk: async () => {
        try {
          await menu.deleteMenu(id);
          fetchArticles();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const onSearchHandler = (): void => {
    history.push({
      pathname: '/menu',
      search: '?' + new URLSearchParams({ search: searchValue, tags }).toString(),
    });
  };

  useEffect(() => {
    fetchArticles();
  }, [search, page, tags]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<Properties>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      render: ({ title, article }) => article?.i18n?.ro?.title || title || '-',
    },
    {
      title: 'Tip',
      render: ({ type }) => (type ? type[0].toUpperCase() + type.slice(1) : '-'),
    },
    {
      title: 'Pagina',
      width: 150,
      render: ({ data }) => (
        <Tag color={data.isBusiness ? 'success' : data.isInfo ? 'cyan' : 'orange'}>
          {data.isBusiness
            ? 'Business'
            : data.isInfo
            ? 'Dezvăluirea informaţiei'
            : 'Persoane fizice'}
        </Tag>
      ),
    },
    {
      title: 'Acțiuni',
      width: 100,
      align: 'right',
      render: (menu) => {
        return <MenuActions menu={menu} onRemove={onRemove} />;
      },
    },
  ];

  return (
    <>
      <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Row gutter={10} justify="end" align="middle">
            <Col>
              <Search
                enterButton="Caută"
                placeholder="Caută menu după titlu"
                size="large"
                value={searchValue}
                onSearch={onSearchHandler}
                onChange={({ target: { value } }) => setSearchValue(value)}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button type="primary" size="large" onClick={() => history.push('/menu/create')}>
            Creează menu
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        loading={loading}
        rowKey="id"
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 1000,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />
    </>
  );
};

export default MenuPage;
