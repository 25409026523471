import axios from 'axios';
import env from '@beam-australia/react-env';
import refreshTokenInterceptor from './interceptor';
import auth from './auth';
import pages from './pages';
import newsletter from './newsletter';
import faq from './faq';
import tags from './tags';
import users from './users';
import banners from './banners';
import nomenclatures from './nomenclatures';
import map from './map';
import jobs from './jobs';
import i18n from './i18n';
import support from './support';
import { getTokens } from './utils';
import settings from './settings';
import profile from './profile';
import icons from './icons';
import menu from './menu';
import forms from './forms';
import keys from './classifiers';
import credits from './credits';
import deposit from './deposit';
import attachments from './attachments';
import invite from './invite';

const request: any = axios.create({
  baseURL: env('API_BASE_URL'),
});
request.defaults.headers.common['Accept-Language'] = 'no';
request.defaults.headers['Saas-app-token'] = env('SASS_APP_TOKEN');

/**
 * Integration of axios interceptor.
 **/

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any): any =>
  axios
    .post(`${env('API_AUTH_URL')}/authentication/refresh`, {
      refresh: getTokens() ? getTokens()!.refresh : null,
      access: getTokens() ? getTokens()!.access : null,
    })
    .then(({ data }) => {
      const { access } = data;

      if (access) {
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + access;
        setSessionTokens({ access });
      }

      return Promise.resolve();
    })
    .catch(() => {
      removeSessionTokens();

      return Promise.reject();
    });

refreshTokenInterceptor(request, refreshAuthLogic);

export interface SessionTokens {
  refresh?: string;
  access?: string;
}

export interface ApiConfig {
  tokens?: SessionTokens;
}

export const apiConfig: ApiConfig = {};

type TokenListener = (tokens: SessionTokens | null) => void;
let tokenChangeListener: TokenListener | null;

/**
 * This function is called after successfully authentication
 * stores tokens in localstore and in current user session.
 */
const setSessionTokens = (newTokens: SessionTokens): void => {
  const tokens = { ...getTokens(), ...newTokens };
  apiConfig.tokens = tokens;

  request.defaults.headers['Authorization'] = `Bearer ${tokens.access}`;
  window.localStorage.setItem('tokens', JSON.stringify(tokens));

  if (tokenChangeListener) {
    tokenChangeListener(tokens);
  }
};

/**
 * This function is used on `logout` action.
 * It will reset token and clear the listener.
 */
const removeSessionTokens = (): void => {
  request.defaults.headers['Authorization'] = undefined;
  window.localStorage.removeItem('tokens');
  apiConfig.tokens = undefined;
};

/**
 * This function get as param an function of type `TokenListener`
 * which
 * @param onChange
 */
const setTokensListener = (onChange: TokenListener): void => {
  tokenChangeListener = onChange;
};

/**
 * This function will remove tokenListener function.
 */
const removeTokensListener = (): void => {
  if (tokenChangeListener) {
    tokenChangeListener = null;
  }
};

/**
 * A generic interface for endpoints
 * that return an array of data plus
 * pagination information.
 */
export interface ArrayResult<T> {
  count: number;
  next: number | null;
  previous: number | null;
  results: T[];
}

export default {
  request,
  setSessionTokens,
  removeSessionTokens,
  setTokensListener,
  removeTokensListener,
  auth: auth(apiConfig),
  profile,
  pages,
  faq,
  tags,
  users,
  jobs,
  newsletter,
  banners,
  nomenclatures,
  map,
  i18n,
  support,
  settings,
  icons,
  menu,
  forms,
  keys,
  credits,
  deposit,
  attachments,
  invite,
};
