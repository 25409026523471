import React, { useEffect, useMemo, useState } from 'react';
import env from '@beam-australia/react-env';
import { Button, Dropdown, Menu } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import api from 'lib/api/icons';
import { EnvApi, Properties } from 'types';

interface IconSelectProps {
  value?: string;
  onChange?: (value: string) => void;
}

const IconSelect: React.FC<IconSelectProps> = (props) => {
  const [data, setData] = useState<Properties[]>([]);
  const [state, setState] = useState(props?.value || 'select-arrow');

  useEffect(() => {
    api.getList().then(setData);
  }, []);

  useEffect(() => {
    if (props?.value && props.value !== state) {
      setState(props.value);
    }
  }, [props]);

  const onChange = (value) => {
    setState(value);

    if (props?.onChange) {
      props.onChange(value);
    }
  };

  const onClear = () => {
    onChange(null);
    setState('select-arrow');
  };

  const selectedUrl = useMemo(() => data.find(({ name }) => name === state)?.url, [data, state]);

  return (
    <Dropdown
      overlayClassName="icon-select"
      overlay={
        <Menu>
          <Menu.Item className={!state ? 'selected' : undefined} onClick={onClear}>
            <img src={`${env(EnvApi.WEB_APP_URL)}/icons/cancel.svg`} />
          </Menu.Item>
          {data.map(({ name, url }, i) => (
            <Menu.Item
              key={i}
              className={state === name ? 'selected' : undefined}
              onClick={() => onChange(name)}
            >
              <img src={url} />
            </Menu.Item>
          ))}
        </Menu>
      }
      placement="bottomLeft"
    >
      <Button>
        {!data.length ? <LoadingOutlined /> : selectedUrl && <img src={selectedUrl} />}
      </Button>
    </Dropdown>
  );
};

export default IconSelect;
