import api from '.';
import { stringifyUrl } from 'utils';

export enum MenuType {
  TOP = 'top',
  HEADER = 'header',
  SIDEBAR = 'sidebar',
  FOOTER = 'footer',
}

export default {
  getMenu: async (params: any) => {
    const { data } = await api.request.get(stringifyUrl(`menu/`, params, { version: 2 }));

    return data;
  },
  getMenuById: async (id: string | number) => {
    const { data } = await api.request.get(`/v2/menu/${id}/`);

    return data;
  },
  addMenu: async (params: any) => {
    const { data } = await api.request.post('/v2/menu/', params);

    return data;
  },
  editMenu: async (params: any, id: string | number) => {
    const { data } = await api.request.patch(`/v2/menu/${id}/`, params);

    return data;
  },
  bulkUpdate: async ({ id, ...params }: any) => {
    const { data } = await api.request.patch(`/v2/menu/${id}/bulk/`, params);

    return data;
  },
  deleteMenu: async (id: string | number) => {
    const { data } = await api.request.delete(`/v2/menu/${id}/`);

    return data;
  },
};
