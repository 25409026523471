import React from 'react';
import { Form } from 'antd';
import { Properties } from 'types';

import { Link } from '../Custom';
import { formItemStyle } from '../helpers';

export default ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, 'action']}
        initialValue={item?.action}
        label="Acțiune"
        style={formItemStyle}
      >
        <Link lang={lang} />
      </Form.Item>
    </>
  );
};
