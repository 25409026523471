import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

interface Props {
  code: string;
  onEdit?: (code: string) => void;
  onRemove?: (code: string) => void;
}

const CustomActions: React.FC<Props> = ({ code, onRemove, onEdit }) => {
  const history = useHistory();
  return (
    <Row gutter={[16, 16]} justify="end" style={{ display: 'flex' }}>
      <Col>
        <Button
          icon={<EyeOutlined />}
          type="dashed"
          onClick={() => history.push(`/custom-options/${code}`)}
        >
          Options list
        </Button>
      </Col>

      <Col>
        {' '}
        <Dropdown.Button
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                icon={<DeleteOutlined />}
                onClick={() => onRemove && onRemove(code)}
              >
                Șterge
              </Menu.Item>
            </Menu>
          }
          onClick={() => onEdit && onEdit(code)}
        >
          <EditOutlined /> Editează
        </Dropdown.Button>
      </Col>
    </Row>
  );
};

export default CustomActions;
