import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, Modal, notification, Row, Table } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { ComplaintModal } from 'components/Complaint/ComplaintModal';
import complaints, { Complaint, PetitionComplaintType, type } from 'lib/api/complaints';
import { StateT } from 'lib/api/pages';
import { ArrayResult } from 'types';

const Complaints: FC = () => {
  const history = useHistory();
  const [selectedId, setSelectedId] = useState<number>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<ArrayResult<Complaint>>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const typeFilter = params.type;
  const page = Number(params.page) || 1;
  const search = params.search || undefined;

  const fetch = useCallback(async () => {
    try {
      setData(await complaints.all({ name_content_icontains: search, page, type: params.type }));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [search, page, params.type]);

  const onRemove = async ({ id }: Complaint): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest articol?',
      onOk: async () => {
        try {
          await complaints.remove(id);
          fetch();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  const columns: ColumnProps<Complaint>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Tip',
      render: (data) => data && type(data.toString()),
      dataIndex: 'type',
      filters: Object.values(PetitionComplaintType).map((i) => ({
        text: type(i),
        value: i,
      })),
      defaultFilteredValue: typeFilter?.split(','),
    },
    {
      title: 'Nume/Prenume',
      dataIndex: 'fullname',
    },
    {
      title: 'Adresa',
      dataIndex: 'address',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Data creării',
      render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Status',
      dataIndex: 'type',
      render: (record) => StateT[record as keyof typeof StateT],
    },
    {
      title: 'Tip utilizator',
      dataIndex: 'person_type',
      render: (record) => (
        <span>
          {record === 2 && 'Juridica'}
          {record === 1 && 'Fizica'} {record !== 2 && record !== 1 && 'Nici un tip selectat'}
        </span>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'sender_phone',
      render: (record) => <span>{record}</span>,
    },
    {
      title: 'Acțiuni',
      width: 400,
      align: 'center',
      render: (complaint) => {
        return (
          <Row justify="end" style={{ display: 'flex' }}>
            <Button
              icon={<EyeOutlined />}
              type="primary"
              onClick={() => {
                setSelectedId(complaint.id);
                setOpenModal(true);
              }}
            >
              Vezi
            </Button>

            <Button
              icon={<DeleteOutlined />}
              onClick={() => onRemove(complaint)}
              // type="danger"
              style={{ marginLeft: 20 }}
            >
              Șterge
            </Button>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />

      {openModal && <ComplaintModal id={selectedId} onClose={() => setOpenModal(false)} />}
    </>
  );
};

export default Complaints;
