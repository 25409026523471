import { SessionTokens } from '.';

/**
 * Error type for case when API returns error
 * not related to the submited form data.
 */
export class NonFieldsError extends Error {}

/**
 * Error type for cases when submited for fields
 * did not pass the validation.
 *
 * This will use the response fields anda form fields
 * to expose an object which can be used in antd `form.setFields` method.
 */
export class FieldsValidationError extends Error {
  private readonly failedFields: FailedFields;

  constructor(responseFields: ApiFailedFields, formFields: any) {
    super('Failed fields validation');
    const failedFields: FailedFields = {};

    for (const key in responseFields) {
      if (key in responseFields) {
        if (key in formFields) {
          failedFields[key] = {
            value: formFields[key],
            errors: responseFields[key].map((s) => new Error(s)),
          };
        } else {
          // tslint:disable-next-line: no-console
          console.warn(`Missing key in form fields: ${key}`);
        }
      }
    }

    this.failedFields = failedFields;
  }

  get fields(): FailedFields {
    return this.failedFields;
  }
}

/**
 * Util that extracts tokens from `localStorage`
 * and returns SessionTokens object, either null
 * if nothing was found in localStorage.
 */
export const getTokens = (): SessionTokens | null => {
  const tokens = window.localStorage.getItem('tokens');

  if (tokens) {
    const { access, refresh } = JSON.parse(tokens);

    return { access, refresh };
  }

  return null;
};

interface FailedFields {
  [key: string]: {
    value: any;
    errors: Error[];
  };
}

interface ApiFailedFields {
  [key: string]: string[];
}
