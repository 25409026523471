import api from '.';
import env from '@beam-australia/react-env';
import { stringifyUrl } from 'utils';
import { EnvApi, InviteUser } from 'types';
import { FieldsValidationError, NonFieldsError } from './utils';
import { registerPath } from 'lib/router';

export default {
  /**
   * invite user
   */
  inviteUser: async (user: InviteUser): Promise<[InviteUser | null, any]> => {
    let error, data;

    const dashUrl = env('BASE_DASH_URL') || window.location.origin;

    try {
      ({ data } = await api.request.post(
        stringifyUrl('invites', {}, { api: EnvApi.API_MAIN_URL }),
        {
          ...user,
          redirect: `${dashUrl}${registerPath}`,
        },
      ));
    } catch (e: any) {
      const { response } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, user);
      } else {
        error = e.message;
      }
    }

    return [error, data];
  },
};
