import api from '.';
import { stringifyUrl } from 'utils';
import { EnvApi, User } from 'types';

export default {
  /**
   * This will fetch current user profile,
   * if session in valid.
   */
  getProfile: async (): Promise<User | null> => {
    let user: User | null = null;

    if (!api.request.defaults.headers.Authorization) {
      return null;
    }

    const response = await api.request.get(
      stringifyUrl(`user/profile`, {}, { api: EnvApi.API_MAIN_URL }),
    );

    if (response.status === 200 || response.statusText === 'OK') {
      user = response.data;

      return user;
    }

    throw response.data;
  },
  patchProfile: async (user: User): Promise<User | null> => {
    let $user: User | null = null;

    if (!api.request.defaults.headers.Authorization) {
      return null;
    }
    const response = await api.request.patch(
      stringifyUrl(`user/profile`, {}, { api: EnvApi.API_MAIN_URL }),
      user,
    );

    if (response.status === 200 || response.statusText === 'OK') {
      $user = response.data;

      return $user;
    }

    throw response.data;
  },
};
