import * as React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Table,
  Button,
  Dropdown,
  Menu,
  InputNumber,
  Space,
  notification,
} from 'antd';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { ColumnProps } from 'antd/lib/table';
import { ArrayResult } from 'lib/api';
import groups, { NomenclatureGroup } from 'lib/api/groups';
import { useQueryParams, useQueryUpdate } from 'hooks';

const formId = 'group-branches-atm-form';

const GroupsPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const [searchValue, setSearchValue] = React.useState<string>('');
  const [data, setData] = React.useState<ArrayResult<NomenclatureGroup>>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [groupId, setGroupId] = React.useState<number>();

  const page = Number(params.page) || 1;
  const limit = Number(params.limit) || 10;
  const search = params.search || undefined;

  React.useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const fetchGroups = React.useCallback(async () => {
    try {
      setData(
        await groups.getGroupsATMList({
          ...params,
          search,
          page,
          limit,
        }),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [page, search]);

  const fetchGroup = React.useCallback(async () => {
    try {
      if (groupId) {
        const data = await groups.getGroupATMById(groupId);
        form.setFieldsValue(data);
      }
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [groupId]);

  React.useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  React.useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  const onSubmit = async (values: NomenclatureGroup): Promise<void> => {
    try {
      if (groupId) {
        await groups.updateGroupATM({
          ...values,
          id: Number(groupId),
        });
      } else {
        await groups.createGroupATM(values);
      }
      onCloseModal();
      fetchGroups();
    } catch (error) {
      const message = error?.response?.data?.slug?.[0] || error.message;

      Modal.error({ content: message });
    }
  };

  const onRemove = async ({ id }: NomenclatureGroup): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acestă grupă ?',
      onOk: async () => {
        try {
          await groups.removeGroupATM(Number(id));
          fetchGroups();
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const columns: ColumnProps<NomenclatureGroup>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'Titlu',
      dataIndex: 'title',
    },
    {
      title: 'Poziția',
      dataIndex: 'code',
    },
    {
      title: 'Descriere',
      dataIndex: 'description',
    },
    {
      width: 200,
      title: 'Data publicarii',
      render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Acțiuni',
      width: 100,
      align: 'right',
      render: (group) => {
        return (
          <>
            <Dropdown.Button
              overlay={
                <Menu>
                  <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => onRemove(group)}>
                    Șterge
                  </Menu.Item>
                </Menu>
              }
              onClick={() => {
                setIsOpen(true);
                setGroupId(group?.id);
              }}
            >
              <EditOutlined /> Editează
            </Dropdown.Button>
          </>
        );
      },
    },
  ];

  const onCloseModal = () => {
    form.resetFields();
    setGroupId(undefined);
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <Modal
          title={groupId ? 'Editează' : 'Adaugă'}
          maskClosable={false}
          destroyOnClose
          visible={isOpen}
          okButtonProps={{ htmlType: 'submit', form: formId }}
          onCancel={onCloseModal}
        >
          <Form id={formId} form={form} onFinish={onSubmit} layout="vertical">
            <Form.Item label="Titlu" name="title" rules={[{ required: true }]}>
              <Input placeholder="Introduceți titlu" />
            </Form.Item>
            <Form.Item label="Poziția" name="code" rules={[{ required: true }]}>
              <InputNumber min={1} placeholder="Introduceți poziția" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Descriere" name="description">
              <Input.TextArea rows={4} placeholder="Introduceți descriere" />
            </Form.Item>
          </Form>
        </Modal>
      )}

      <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Space>
            <Button icon={<ArrowLeftOutlined />} size="large" onClick={() => history.push('/atm')}>
              Inapoi
            </Button>

            <Search
              enterButton="Caută"
              placeholder="Caută"
              size="large"
              value={searchValue}
              onSearch={() => updateQuery({ search: searchValue })}
              onChange={({ target: { value } }) => setSearchValue(value)}
            />
          </Space>
        </Col>
        <Col>
          <Button type="primary" size="large" onClick={() => setIsOpen(true)}>
            Creează grup
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        scroll={{ x: 1200 }}
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current, pageSize }, filters) => {
          updateQuery({ ...params, ...filters, page: String(current), limit: String(pageSize) });
        }}
      />
    </>
  );
};

export default GroupsPage;
