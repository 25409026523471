import React, { FC, createContext, useReducer, useEffect, useCallback } from 'react';
import api, { SessionTokens } from 'lib/api';
import { getTokens } from 'lib/api/utils';
import profile from 'lib/api/profile';
import { User } from '../types';

/**
 * This holds data related to a valid session.
 */
export interface SessionState {
  /**
   * Current logged in user's tokens.
   */
  tokens: SessionTokens | null;

  /**
   * Whether we are in a valid session or not.
   * The validity of the session should depend on
   * the validity of session tokens.
   *
   * In other words, if access and refresh tokens are
   * both invalid or missing, the session should be considered
   * invalid.
   */
  isSessionValid: boolean;
  isReady: boolean;
  user: User | null;
}

export enum ReducerActionType {
  SET_TOKENS = 'SET_TOKENS',
  SET_SESSION_DATA = 'SET_SESSION_DATA',
  REMOVE_SESSION_DATA = 'REMOVE_SESSION_DATA',
  SET_VALID_FLAG = 'SET_VALID_FLAG',
  REMOVE_VALID_FLAG = 'REMOVE_VALID_FLAG',
}

export type ReducerAction =
  | {
      type: ReducerActionType.SET_TOKENS;
      tokens: SessionTokens | null;
    }
  | { type: ReducerActionType.REMOVE_SESSION_DATA }
  | { type: ReducerActionType.SET_VALID_FLAG }
  | { type: ReducerActionType.REMOVE_VALID_FLAG }
  | {
      type: ReducerActionType.SET_SESSION_DATA;
      user: User;
      tokens: SessionTokens;
    };

export const initialState: SessionState = {
  tokens: getTokens(),
  isSessionValid: false,
  isReady: false,
  user: null,
};

export const reducer = (state: SessionState, action: ReducerAction): SessionState => {
  switch (action.type) {
    case ReducerActionType.SET_SESSION_DATA: {
      const { tokens, user } = action;

      return { ...state, tokens, user, isSessionValid: true, isReady: true };
    }

    case ReducerActionType.REMOVE_SESSION_DATA:
      return {
        ...state,
        user: null,
        tokens: null,
        isSessionValid: false,
        isReady: true,
      };

    case ReducerActionType.SET_VALID_FLAG:
      return { ...state, isSessionValid: true, isReady: true };

    case ReducerActionType.REMOVE_VALID_FLAG:
      return { ...state, isSessionValid: false, isReady: true };

    case ReducerActionType.SET_TOKENS: {
      const { tokens } = action;

      return { ...state, tokens };
    }
  }
};

/**
 * This is the session context.
 * It holds info such as the selected account
 * and whether the session is valid or not.
 */
export interface SessionContextState {
  session: SessionState;
  setSession: (tokens: SessionTokens, user: User) => void;
  removeSession: () => void;
}

export const SessionContext = createContext<SessionContextState>({
  session: initialState,
  setSession: () => null,
  removeSession: () => null,
});

export const AppSession: FC = ({ children }) => {
  const [session, dispatch] = useReducer(reducer, initialState);

  const removeSession = useCallback(() => {
    dispatch({ type: ReducerActionType.REMOVE_SESSION_DATA });
  }, []);

  const setSession = useCallback((tokens: SessionTokens, user: User) => {
    dispatch({ type: ReducerActionType.SET_SESSION_DATA, user, tokens });
  }, []);

  const tokensListener = useCallback(
    (tokens: SessionTokens | null) => {
      if (tokens) {
        profile.getProfile().then((user) => {
          if (user) {
            setSession(tokens, user);
          } else {
            removeSession();
          }
        });
      } else {
        removeSession();
      }
    },
    [removeSession, setSession],
  );

  useEffect(() => {
    api.setTokensListener(tokensListener);

    return () => api.removeTokensListener();
  }, [tokensListener]);

  const context: SessionContextState = {
    session,
    setSession,
    removeSession,
  };

  return <SessionContext.Provider value={context}>{children}</SessionContext.Provider>;
};
