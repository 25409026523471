import React from 'react';
import { Form, Select, Checkbox } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';

const typeOptions = [
  { value: 'white', label: 'White' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'gray', label: 'Gray' },
];

export default ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, 'g']}
        initialValue={'4'}
        label="Distanța dintre elemente"
        style={formItemStyle}
      >
        <Select>
          <Select.Option value="0">Zero</Select.Option>
          <Select.Option value="1">Cel mai mic</Select.Option>
          <Select.Option value="2">Foarte mic</Select.Option>
          <Select.Option value="3">Mic</Select.Option>
          <Select.Option value="4">Mediu</Select.Option>
          <Select.Option value="5">Mare</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'dataType']}
        initialValue={'white'}
        label="Culoare fundal"
        style={formItemStyle}
      >
        <Select>
          {typeOptions.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'border']}
        valuePropName="checked"
        initialValue={true}
        label="Frontiera"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  collapsed: !item.border,
                },
              },
            });
          }}
        />
      </Form.Item>
    </>
  );
};
