import React, { useEffect, useContext, Suspense } from 'react';
import { Spin } from 'antd';
import PrivateRoutes from 'lib/router/PrivateRoutes';
import api from 'lib/api';
import { SessionContext } from '../context/utils';
import { getTokens } from 'lib/api/utils';
import profile from 'lib/api/profile';

/**
 * This is `Fallback` component which is displyed while
 * `PrivateRoutes` are lazy loaded or validated for access.
 */
const Fallback: React.FC = () => (
  <div className="app-fallback">
    <Spin tip="Loading..." />
  </div>
);

/**
 * This component protects private routes from unauthticated users.
 *
 * With an server request for cookie valitation, SessionGuard will
 * verify user's session and store in SessionContext's AccountAccess.
 *
 * If AccountAccess proves to be a valid one, private routers are rendered
 * in the app's view, otherwise, it will redirect user to login page.
 */
const SessionGuard: React.FC = () => {
  const { session, setSession, removeSession } = useContext(SessionContext);

  useEffect(() => {
    const tokens = getTokens();

    if (tokens) {
      api.setSessionTokens(tokens);
    }

    const verifyToken = async (): Promise<void> => {
      Promise.all([getTokens(), profile.getProfile()])
        .then(([tokens, user]) => {
          if (tokens && user) {
            setSession(tokens, user);
          } else {
            removeSession();
          }
        })
        .catch(() => removeSession());
    };

    verifyToken();
  }, [setSession, removeSession]);

  if (!session.isReady) {
    return <Fallback />;
  }

  return (
    <Suspense fallback={<Fallback />}>
      <PrivateRoutes />
    </Suspense>
  );
};

export default SessionGuard;
