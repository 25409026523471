import React, { useState, useCallback } from 'react';
import { Form, Input, Tabs, Row, Col, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';
import { Payments } from '../Custom';

export default ({ item, lang, setFieldsValue }: Properties) => {
  const [activeKey, setActiveKey] = useState<string>('0');

  const onChangeTabs = useCallback(
    (key, action) => {
      switch (action) {
        case 'add':
          setFieldsValue({
            [item.id]: {
              [lang]: {
                tabs: [
                  ...(item?.tabs || []),
                  {
                    title: `Metode #${(item?.tabs?.length || 0) + 1}`,
                    action: { link: undefined, text: '' },
                    image: undefined,
                    description: [],
                  },
                ],
              },
            },
          });
          break;
        case 'remove':
          setFieldsValue({
            [item.id]: {
              [lang]: {
                tabs: [...(item?.tabs || [])].filter((_, i) => i.toString() !== key),
              },
            },
          });
          break;
      }
    },
    [item, lang, setFieldsValue],
  );

  return (
    <>
      <Form.Item
        label="Titlu"
        name={[item.id, lang, 'title']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți titlul`} />
      </Form.Item>

      <Tabs
        type="editable-card"
        addIcon={
          <Row gutter={[8, 8]}>
            <Col>
              <PlusOutlined />
            </Col>
            <Col>Adaugă</Col>
          </Row>
        }
        activeKey={activeKey}
        onChange={setActiveKey}
        onEdit={onChangeTabs}
      >
        {[...(item.tabs || [])]?.map((tabItem: Properties, i: number) => (
          <Tabs.TabPane forceRender key={i.toString()} tab={tabItem?.title || ''} closable>
            <Form.Item
              label="Titlu"
              name={[item.id, lang, 'tabs', i, 'title']}
              rules={[
                {
                  required: true,
                  message: 'Titlul este necesar',
                },
              ]}
              style={formItemStyle}
            >
              <Input placeholder={`Introduceți titlul`} />
            </Form.Item>
            <Form.Item
              label={
                <Button
                  type="primary"
                  className="full-width"
                  onClick={() =>
                    setFieldsValue({
                      [item.id]: {
                        [lang]: {
                          tabs: [...(item?.tabs || [])].map((tab, index) => {
                            if (index === i) {
                              tab.items = [...(tab?.items || []), { title: '' }];
                            }

                            return tab;
                          }),
                        },
                      },
                    })
                  }
                >
                  Adaugă
                </Button>
              }
              name={[item.id, lang, 'tabs', i, 'items']}
              //initialValue={tabItem?.items}
              style={formItemStyle}
            >
              <Payments lang={lang} />
            </Form.Item>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};
