import React from 'react';
import { Form, Select, Checkbox } from 'antd';
import { Properties } from 'types';

import { formItemStyle } from '../helpers';
import { Link } from '../Custom';

enum CalculatorType {
  DEPOSIT = 'deposit',
  CREDIT = 'credit',
}

export default ({ item, lang, setFieldsValue }: Properties) => {
  return (
    <>
      <Form.Item
        name={[item.id, lang, 'full_calculator']}
        valuePropName="checked"
        label="Calculator complet"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  full_calculator: !item.full_calculator,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Tip"
        name={[item.id, lang, 'dataType']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Select disabled={!!item.full_calculator}>
          <Select.Option value={CalculatorType.DEPOSIT}>Deposit</Select.Option>
          <Select.Option value={CalculatorType.CREDIT}>Credit</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'action']}
        initialValue={item?.action}
        label="Acțiune"
        style={formItemStyle}
      >
        <Link lang={lang} />
      </Form.Item>
    </>
  );
};
