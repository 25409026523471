import api from '.';
import { stringifyUrl } from 'utils';

export interface Message {
  id?: number;
  captcha?: string;
  timestamp: string;
  edited_timestamp: string;
  fullname: string;
  phoneNumber: string;
  email?: string;
  description?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
}

export default {
  getMessages: async (params: any) => {
    const { data } = await api.request.get(stringifyUrl('support/', params, {}));

    return data;
  },

  clearCache: async () => {
    const { data } = await api.request.delete('/v2/menu/clear-cache/');

    return data;
  },
};
