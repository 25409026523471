import * as React from 'react';
import { Form, Row, Col, Input, Modal, notification } from 'antd';
import deposit, { NomenclatureDepositRate } from 'lib/api/deposit';
import { ValidationError } from 'errors';

interface Props {
  currencyId?: number;
  rateId?: number;
  onSaved: () => void;
}

const DepositRateForm: React.FC<Props> = ({ onSaved, currencyId, rateId }) => {
  const [form] = Form.useForm();

  const fetchCurrencyRate = React.useCallback(async () => {
    if (Number(rateId)) {
      try {
        deposit.getDepositRatesById(Number(rateId)).then((rate) => {
          form.setFieldsValue(rate);
        });
      } catch (e) {
        notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
      }
    }
  }, [rateId]);

  React.useEffect(() => {
    fetchCurrencyRate();
  }, [fetchCurrencyRate]);

  const handleSubmit = async (values: NomenclatureDepositRate): Promise<void> => {
    const [error] = await deposit.upsertDepositsRates({
      ...values,
      ...(currencyId && { currency: currencyId }),
      ...(rateId && { id: rateId }),
    });
    if (!error) {
      onSaved();
    } else {
      if (error instanceof ValidationError) {
        form.setFields(error.fields);
      } else {
        Modal.error({
          title: 'Procesul a eșuat.',
          content: error,
        });
      }
    }
  };

  return (
    <Form id="DepositRateForm" form={form} onFinish={handleSubmit}>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={24}>
          <Form.Item
            label={
              <>
                <span className="deposit__asterisk">&#42;</span>Rata
              </>
            }
            name="rate"
          >
            <Input placeholder="Introduceți rata" type="number" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={
              <>
                <span className="deposit__asterisk">&#42;</span>Minimum
              </>
            }
            name="minimum"
          >
            <Input placeholder="Introduceți minimum" type="number" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={
              <>
                <span className="deposit__asterisk">&#42;</span>Maximum
              </>
            }
            name="maximum"
          >
            <Input placeholder="Introduceți maximum" type="number" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={
              <>
                <span className="deposit__asterisk">&#42;</span>Term
              </>
            }
            name="term"
          >
            <Input placeholder="Introduceți term" type="number" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DepositRateForm;
