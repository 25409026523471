import React, { FC, useState, useEffect } from 'react';
import { Tabs, Spin, Icon } from 'antd';
import Translations from 'components/i18n/Translations';
import Logs from 'components/Logs';
import api, { Ii18n } from 'lib/api/i18n';
import { Branches } from 'resources';

const { TabPane } = Tabs;

const I18n: FC = () => {
  const [loading, setLoading] = useState(true);
  const [namespaces, setNamespaces] = useState<Ii18n[]>([]);
  const [namespace, selectNamespace] = useState<string>();

  const fetchNamespaces = async (): Promise<void> => {
    const data = await api.getAll();

    setNamespaces(data.results);

    // set first namespace as selected
    if (data.results.length) {
      selectNamespace(String(data.results[0].id));
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchNamespaces();
  }, []);

  return (
    <Spin spinning={loading}>
      <Tabs
        className="custom-tabs custom-tabs--hide-content"
        animated={false}
        onChange={selectNamespace}
        activeKey={namespace}
      >
        <TabPane tab={<Icon component={Branches} />} key="history" />
        {namespaces.map((ns: Ii18n) => (
          <TabPane tab={ns.text_id} key={`${ns.id}`} />
        ))}
      </Tabs>

      {namespace && namespace !== 'history' && <Translations namespace={namespace} />}
      {namespace === 'history' && <Logs api="i18n" route="i18n" />}
    </Spin>
  );
};

export default I18n;
