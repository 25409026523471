import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Modal, notification, Row, Space, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Actions } from 'components/Actions';
import { CreditTypesListForm } from 'pages/Credits';
import { ArrayResult } from 'types';
import credits, { NomenclatureCreditTypes } from 'lib/api/credits';

const CreditTypesList: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const history = useHistory();

  const [searchValue, setSearchValue] = React.useState('');
  const [data, setData] = React.useState<ArrayResult<any>>();
  const [selectedItem, setSelectedItem] = React.useState<NomenclatureCreditTypes>();
  const [openForm, setOpenForm] = React.useState(false);

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;

  const fetchCreditTypes = React.useCallback(async () => {
    try {
      setData(await credits.getCreditTypes(Number(code), { ...params, search, page }));
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [search, page]);

  React.useEffect(() => {
    fetchCreditTypes();
  }, [fetchCreditTypes]);

  const handleRemove = (id: number) => {
    Modal.confirm({
      title: 'Doriți să ștergeți acestă optiune?',
      onOk: async () => {
        try {
          await credits.deleteCreditTypes(Number(code), id);
          fetchCreditTypes();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const onSearchHandler = (): void => {
    history.push({
      pathname: `/credits/types/${code}`,
      search: '?' + new URLSearchParams({ search: searchValue }).toString(),
    });
  };

  const handleCreate = () => {
    setOpenForm(true);
  };

  const handleEdit = (id: number) => {
    setSelectedItem(data?.results.find((item) => item.id === id));
    setOpenForm(true);
  };

  const handleOnSave = () => {
    handleCloseForm();
    fetchCreditTypes();
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedItem(undefined);
  };

  const columns: ColumnProps<NomenclatureCreditTypes>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      dataIndex: 'title',
    },
    {
      title: 'Tip client',
      dataIndex: 'is_loial',
      render: (isLoial) => (isLoial ? 'Loial' : 'Standart'),
    },
    {
      title: 'Data creării',
      render: ({ timestamp }) => `${moment(timestamp).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Acțiuni',
      width: 400,
      align: 'right',
      render: (item) => {
        return <Actions id={item.id} onEdit={handleEdit} onRemove={() => handleRemove(item.id)} />;
      },
    },
  ];

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]} style={{ marginBottom: 10 }}>
        <Col>
          <Space>
            <Button
              style={{ marginRight: 10 }}
              icon={<ArrowLeftOutlined />}
              size="large"
              onClick={() => history.push('/credits')}
            >
              Inapoi
            </Button>

            <Search
              enterButton="Caută"
              placeholder="Caută credit type după titlu"
              size="large"
              value={searchValue}
              onSearch={onSearchHandler}
              onChange={({ target: { value } }) => setSearchValue(value)}
            />
          </Space>
        </Col>

        <Col>
          <Button icon={<PlusOutlined />} type="primary" size="large" onClick={handleCreate}>
            Creează custom credit type
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data ? data.results : []}
            rowKey="id"
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize: 10,
              total: data ? data.count : 0,
            }}
            onChange={({ current }, filters: any) => {
              history.push(
                `?${new URLSearchParams({
                  ...params,
                  ...filters,
                  page: String(current),
                }).toString()}`,
              );
            }}
          />
        </Col>
      </Row>
      {openForm && (
        <Modal
          title={selectedItem ? 'Edit credit type' : 'Add credit type'}
          visible={openForm}
          okButtonProps={{ htmlType: 'submit', form: 'CreditTypesForm' }}
          onCancel={handleCloseForm}
        >
          <CreditTypesListForm code={code} initialValue={selectedItem} onSaved={handleOnSave} />
        </Modal>
      )}
    </>
  );
};

export default CreditTypesList;
