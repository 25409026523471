import React from 'react';
import { Form, Select } from 'antd';
import { Properties, FAQType } from 'types';

import { formItemStyle } from '../helpers';

export enum FAQDisplayType {
  SLIDER = 'slider',
  STATIC = 'static',
}

export default ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        label="Metoda de afișare"
        name={[item.id, lang, 'dataType']}
        rules={[
          {
            required: true,
            message: 'Metoda de afișare este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Select>
          <Select.Option value={FAQDisplayType.STATIC}>Static</Select.Option>
          <Select.Option value={FAQDisplayType.SLIDER}>Slider</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Tip"
        name={[item.id, lang, 'faqType']}
        rules={[
          {
            required: true,
            message: 'Tipul este necesar',
          },
        ]}
      >
        <Select>
          {Object.keys(FAQType).map((key) => (
            <Select.Option key={key} value={key}>
              {FAQType[key]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
