import React from 'react';
import { Form, Input } from 'antd';
import { Properties } from 'types';

import { IconSelect, Link } from '../Custom';
import { formItemStyle } from '../helpers';

export default ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item label="Icon" name={[item.id, lang, 'icon']} style={formItemStyle}>
        <IconSelect />
      </Form.Item>
      <Form.Item
        label="Titlu"
        name={[item.id, lang, 'title']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți titlul`} />
      </Form.Item>
      <Form.Item
        label="Subtitlu"
        name={[item.id, lang, 'subtitle']}
        rules={[
          {
            required: true,
            message: 'Subtitlu este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder="Introduceți subtitlu" />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'action']}
        initialValue={item?.action}
        label="Acțiune"
        style={formItemStyle}
      >
        <Link lang={lang} />
      </Form.Item>
    </>
  );
};
