import React from 'react';
import { Form, Input } from 'antd';
import { ArticleEditor } from 'components/Articles';
import { Properties } from 'types';

import { Link } from '../Custom';
import { formItemStyle } from '../helpers';

export default ({ item, lang }: Properties) => {
  return (
    <>
      <Form.Item
        label="Titlu"
        name={[item.id, lang, 'title']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder="Introduceți titlul" />
      </Form.Item>
      <Form.Item label="Subtitlu" name={[item.id, lang, 'subtitle']} style={formItemStyle}>
        <ArticleEditor height={60} />
      </Form.Item>
      <Form.Item label="Descriere" name={[item.id, lang, 'description']} style={formItemStyle}>
        <ArticleEditor height={60} />
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'action']}
        initialValue={item?.action}
        label="Acțiune"
        style={formItemStyle}
      >
        <Link lang={lang} />
      </Form.Item>
    </>
  );
};
