import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Form, Button, Input, message, Select, Row, Col, Modal, Checkbox } from 'antd';
import credits, { NomenclaturesCredits, PaymentType } from 'lib/api/credits';
import { ValidationError } from 'errors';

enum Operation {
  CREATE = 'create',
  EDIT = 'edit',
}

const CreditForm: React.FC = () => {
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: Operation }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (id && tab === Operation.EDIT) {
      try {
        credits.getCreditById(Number(id)).then((credit) => {
          form.setFieldsValue(credit);
        });
      } catch (e) {
        const { message: err = 'Error. The article could not be saved!' } = e;

        message.error(err);
      }
    }
  }, [id, tab]);

  const handleSubmit = async ({
    payment_types,
    ...values
  }: NomenclaturesCredits): Promise<void> => {
    setLoading(true);
    const payment_type = Array.isArray(payment_types) ? payment_types : [payment_types];

    const [error] = await credits.upsertCredits({
      ...values,
      payment_types: payment_type,
      ...(id && { id: Number(id) }),
    });

    if (!error) {
      if (!id) {
        Modal.success({ title: 'Creditul a fost salvat cu succes' });
      } else {
        Modal.success({ title: 'Creditul a fost editat cu succes' });
      }
      history.push(`/credits`);
    } else {
      if (error instanceof ValidationError) {
        form.setFields(error.fields);
      } else {
        Modal.error({
          title: 'Procesul a eșuat.',
          content: error,
        });
      }
    }
    setLoading(false);
  };

  return (
    <Card>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Col span={24} xl={12}>
            <Form.Item
              name="title"
              label="Titlu"
              rules={[{ required: true, message: 'Titlu este necesar!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} xl={6}>
            <Form.Item
              name="payment_types"
              label="Tipul de plată"
              rules={[
                {
                  required: true,
                  message: 'Tipul de plată este necesar!',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Tipul de plată"
                style={{ width: '100%' }}
              >
                <Select.Option value={PaymentType.ANUITATE}>{PaymentType.ANUITATE}</Select.Option>
                <Select.Option value={PaymentType.STANDARD}>{PaymentType.STANDARD}</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} xl={6}>
            <Form.Item
              name="min_term"
              label="Numărul minim de luni"
              rules={[
                {
                  required: true,
                  message: 'Numărul minim de luni este necesar!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={24} xl={6}>
            <Form.Item
              name="max_term"
              label="Numărul maxim de luni"
              rules={[
                {
                  required: true,
                  message: 'Numărul maxim de luni este necesar!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={24} xl={6}>
            <Form.Item
              name="min_amount"
              label="Suma minimala a creditului"
              rules={[
                {
                  required: true,
                  message: 'Suma minimala a creditului este necesară!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={24} xl={6}>
            <Form.Item
              name="max_amount"
              label="Suma maximala a creditului"
              rules={[
                {
                  required: true,
                  message: 'Suma maximala a creditului este necesară!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={24} xl={6}>
            <Form.Item
              name="is_term_depending"
              label="Dependent de termeni"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          style={{
            width: '100%',
            textAlign: 'right',
          }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvează
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CreditForm;
