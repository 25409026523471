import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { Form as IForm, Row as IRow, Col } from 'antd';
import cn from 'classnames';
import { Properties } from 'types';

import { GenericFields, Text, Checkbox, Email, Number, Phone, Select, DatePicker } from '../Fields';
import { formItemLayout } from '../helpers';
import { BuilderContext } from 'context/Builder';
import { SelectTypeOptions } from 'lib/api/forms';

interface ItemFormProps {
  initialValue: Properties[];
  item: Properties;
  category?: string;

  onSaved: (values: Properties) => void;
}

const ItemForm: React.FC<ItemFormProps> = ({ category, item, initialValue, onSaved }) => {
  const { lang } = useContext(BuilderContext);
  const [form] = IForm.useForm();
  const [params, setParams] = useState<Properties>({});

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
    }
  }, [item]);

  const [, cancel] = useDebounce(
    () => {
      if (Object.keys(params).length) {
        onSaved(
          [...initialValue].map((val) => {
            if (val.id === item.id) {
              val = {
                ...val,
                ...(!params[0].name[0].includes('i18n') && {
                  [params[0].name[0]]:
                    params[0].name.length > 1
                      ? {
                          ...val[params[0].name[0]],
                          [params[0].name[1]]:
                            params[0].name.length > 2
                              ? {
                                  ...val[params[0].name[0]][params[0].name[1]],
                                  [params[0].name[2]]: params[0].value,
                                }
                              : params[0].value,
                        }
                      : params[0].value,
                }),
                i18n: {
                  ...val.i18n,
                  [lang]: {
                    ...(val.i18n && val.i18n[lang]),
                    ...(params[0].name[0].includes('i18n') && {
                      [params[0].name.pop()]: params[0].value,
                    }),
                  },
                },
              };
            }

            return val;
          }),
        );
        setParams({});
      }
    },
    350,
    [params],
  );

  const onChange = useCallback(
    (params) => {
      cancel();
      setParams(params);
    },
    [cancel],
  );

  const props = useMemo(
    () => ({
      lang,
      item,
      setFieldsValue: onChange,
      onSaved,
    }),
    [item, lang, onChange, onSaved],
  );

  const hasOptions = useMemo(
    () =>
      item &&
      [
        'moduleOrder',
        'bannerHome',
        'bannerDownload',
        'templateInfo',
        'templateText',
        'componentCollapse',
        'templateConfirmation',
      ].includes(item.type),
    [item],
  );

  return (
    <div className={cn({ 'hide-options': !hasOptions })}>
      <IForm form={form} {...formItemLayout} colon={false} onFieldsChange={onChange}>
        <GenericFields
          initialValue={initialValue
            .filter(
              ({ category: x, options, type }) =>
                type === 'select' &&
                (!category || x === category) &&
                !options.optionsType?.includes(SelectTypeOptions.API),
            )
            .filter(({ label, placeholder, name }) => label || placeholder || name)
            .map(({ label, name, placeholder, options }) => ({
              value: name,
              code: options.code,
              label: label || placeholder || name,
            }))}
          {...props}
        />

        <IRow gutter={[16, 16]} justify="space-between">
          <Col span={24}>
            {item &&
              (() => {
                switch (item?.type) {
                  case 'componentText':
                    return <Text {...props} />;
                  case 'text':
                    return <Text {...props} />;
                  case 'email':
                    return <Email {...props} />;
                  case 'checkbox':
                    return <Checkbox {...props} />;
                  case 'number':
                    return <Number {...props} />;
                  case 'tel':
                    return <Phone {...props} />;
                  case 'select':
                    return <Select {...props} />;
                  case 'date':
                    return <DatePicker {...props} />;
                  default:
                    return null;
                }
              })()}
          </Col>
        </IRow>
      </IForm>
    </div>
  );
};

export default ItemForm;
