import * as React from 'react';
import { Form, Input, Select, Radio } from 'antd';
import { formItemStyle } from '../helpers';
import {
  SelectTypeOptions,
  SelectType,
  ApiNomenclaturesType,
  DisplayTypeMode,
} from 'lib/api/forms';
import { BuilderContext } from 'context/Builder';
import { Properties } from 'types';

export default ({ item, lang }: Properties) => {
  const { dataClassifiers } = React.useContext(BuilderContext);

  return (
    <>
      <Form.Item
        label="Nume"
        name="name"
        rules={[
          {
            required: true,
            message: 'Nume este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Input placeholder={`Introduceți Nume`} />
      </Form.Item>

      <Form.Item label="Placeholder" name={['i18n', lang, 'placeholder']} style={formItemStyle}>
        <Input placeholder={`Introduceți placeholder`} />
      </Form.Item>

      <Form.Item label="Label" name={['i18n', lang, 'label']} style={formItemStyle}>
        <Input placeholder={`Introduceți label`} />
      </Form.Item>
      <Form.Item label="Suffix" name={['i18n', lang, 'suffix']} style={formItemStyle}>
        <Input
          disabled={item?.options?.componentType !== SelectType.SELECT}
          placeholder={`Introduceți suffix`}
        />
      </Form.Item>

      <Form.Item
        label="Tip"
        name={['options', 'componentType']}
        initialValue={item?.options?.componentType || SelectType.SELECT}
        style={formItemStyle}
      >
        <Radio.Group>
          <Radio value={SelectType.SELECT}>Select</Radio>
          <Radio value={SelectType.CHIPS}>Chips</Radio>
          <Radio value={SelectType.RADIO}>Radio</Radio>
          <Radio value={SelectType.CHECKBOX}>Checkbox</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Tip de opțiuni"
        name={['options', 'optionsType']}
        initialValue={item?.options?.optionsType || SelectTypeOptions.API}
        style={formItemStyle}
      >
        <Radio.Group>
          <Radio value={SelectTypeOptions.API}>Api</Radio>
          <Radio value={SelectTypeOptions.CUSTOM}>Custom</Radio>
        </Radio.Group>
      </Form.Item>

      {[SelectType.CHIPS, SelectType.RADIO]?.includes(item?.options?.componentType) && (
        <Form.Item
          label="Tip de afișare"
          name={['options', 'displayType']}
          initialValue={item?.options?.displayType || DisplayTypeMode.HORIZONTAL}
          style={formItemStyle}
        >
          <Radio.Group>
            <Radio value={DisplayTypeMode.HORIZONTAL}>Orizontal</Radio>
            <Radio value={DisplayTypeMode.VERTICAL}>Vertical</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {(item?.options?.optionsType || '')?.includes(SelectTypeOptions.API) ? (
        <Form.Item
          label="Opțiuni api"
          name={['options', 'code']}
          initialValue={item?.options?.code}
          rules={[
            {
              required: true,
              message: 'Opțiunile sunt necesare',
            },
          ]}
          style={formItemStyle}
        >
          <Select>
            <Select.Option value={ApiNomenclaturesType.SUBSIDIARIES}>Sucursala</Select.Option>
            <Select.Option value={ApiNomenclaturesType.CURRENCIES}>Valuta</Select.Option>
            <Select.Option value={ApiNomenclaturesType.PLACE}>
              Țara, Regiunea, Localitatea
            </Select.Option>
            <Select.Option value={ApiNomenclaturesType.COUNTRY}>Cetatenia</Select.Option>
            <Select.Option value={ApiNomenclaturesType.MARRIED}>Căsătorit(ă)</Select.Option>
            <Select.Option value={ApiNomenclaturesType.LOAN}>Suma creditului</Select.Option>
            <Select.Option value={ApiNomenclaturesType.DURATION}>Durata</Select.Option>
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          label="Opțiuni custom"
          name={['options', 'code']}
          initialValue={item?.options?.code}
          rules={[
            {
              required: true,
              message: 'Opțiunile sunt necesare',
            },
          ]}
          style={formItemStyle}
        >
          <Select>
            {dataClassifiers?.results.map((item) => (
              <Select.Option key={item.code} value={item.code}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
};
