import api from '.';
import { ArticleState, Content, Locale } from './pages';

export interface Vacancy {
  id: number;
  publish_date: string;
  i18n: { [key in Locale]?: Content };
  state: ArticleState;
  order: number;
}

export default {
  all: async (params: any = {}) => {
    const query = new URLSearchParams({ page_size: 10, ...params });

    [...query.entries()].forEach(([name, value]) => {
      if (!value || value === 'undefined') {
        query.delete(name);
      }
    });

    const { data } = await api.request.get(`/v2/job/?${query.toString()}`);

    return data;
  },
  byId: async (id: number) => {
    const { data } = await api.request.get(`/v2/job/${id}/`);

    return data;
  },
  upsert: async (vacancies: Vacancy) => {
    const id = vacancies.id ? `${vacancies.id}/` : '';
    const method = id ? api.request.patch : api.request.post;

    const { data } = await method(`/v2/job/${id}`, vacancies);

    return data;
  },
  remove: async (id: number) => {
    const { data } = await api.request.delete(`/v2/job/${id}/`);

    return data;
  },
};
