import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button, Input, Row, Col, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import api from '../lib/api/support';

const Support: React.FC = () => {
  const [data, setData] = useState<any>();
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const { location } = history;

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.email__icontains || '';
  const ordering = params.ordering || '-timestamp';

  const onSearch = (value: string): void => {
    const urlParams = new URLSearchParams();
    if (value) {
      urlParams.set('email__icontains', value);
    }

    history.push(`?${urlParams.toString()}`);
  };

  const fetchMessages = async (): Promise<void> => {
    try {
      setData(
        await api.getMessages({ ...params, page_size: 10, ordering: ordering || '-timestamp' }),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [page, search]);

  const columns: ColumnProps<any>[] = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Nume',
      dataIndex: 'fullname',
    },
    {
      title: 'Telefon',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Descriere',
      dataIndex: 'description',
    },
    {
      title: 'Acțiuni',
      width: 120,
      align: 'center',
      render: ({ email }) => (
        <a href={`mailto:${email}`}>
          <Button type="dashed">Răspunde</Button>
        </a>
      ),
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col xs={24} md={12} lg={8}>
          <Input.Search
            placeholder="Caută utilizator după e-mail"
            enterButton="Caută"
            size="large"
            value={searchValue}
            onSearch={onSearch}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Col>
      </Row>

      <Table
        dataSource={data ? data.results : []}
        columns={columns}
        rowKey="id"
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
          onChange: (page) => {
            history.push(
              `?${new URLSearchParams({
                ...params,
                page: String(page),
              }).toString()}`,
            );
          },
        }}
      />
    </>
  );
};

export default Support;
