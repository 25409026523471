import api from '.';
import { stringifyUrl } from 'utils/query';
import { NonFieldsError, FieldsValidationError } from './utils';
import { EnvApi, Properties } from 'types';

export interface NomenclaturesClassifiers {
  id?: number;
  title?: string;
  timestamp?: string;
  edited_timestamp?: string;
  description?: string;
  slug?: string;
  data?: Properties;
  code: string;
}

export interface NomenclatureClassifierEntity {
  id: number;
  classifier: NomenclaturesClassifiers;
  timestamp: string;
  edited_timestamp: string;
  code: string;
  title: string;
  description: string;
  slug: string;
  data: Properties;
}

export default {
  getClassifiers: async (params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(`nomenclatures/classifiers`, params, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },

  upsertClassifiers: async (classifier: NomenclaturesClassifiers) => {
    let error, data;

    try {
      const { id, code, ...values } = classifier;
      if (id) {
        ({ data } = await api.request.patch(
          stringifyUrl(`nomenclatures/classifiers/${code}`, {}, { api: EnvApi.API_MAIN_URL }),
          values,
        ));
      } else {
        ({ data } = await api.request.post(
          stringifyUrl(`nomenclatures/classifiers`, {}, { api: EnvApi.API_MAIN_URL }),
          classifier,
        ));
      }
    } catch (e: any) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, classifier);
      } else {
        error = message;
      }
    }

    return [error, data];
  },

  getClassifier: async (code: string) => {
    const { data } = await api.request.get(
      stringifyUrl(
        `nomenclatures/classifiers${code}`,
        {},
        {
          api: EnvApi.API_MAIN_URL,
        },
      ),
    );

    return data;
  },

  removeClassifiers: async (code: string) => {
    await api.request.delete(
      stringifyUrl(`nomenclatures/classifiers/${code}`, {}, { api: EnvApi.API_MAIN_URL }),
    );
  },

  getEntity: async (code: string, params: any = {}) => {
    const { data } = await api.request.get(
      stringifyUrl(`nomenclatures/classifier/${code}/entities`, params, {
        api: EnvApi.API_MAIN_URL,
      }),
    );

    return data;
  },
  upsertEntity: async (code: string, entity: NomenclatureClassifierEntity) => {
    let error, data;

    try {
      const { id, ...values } = entity;

      if (id) {
        ({ data } = await api.request.patch(
          stringifyUrl(
            `nomenclatures/classifier/${code}/entities/${id}`,
            {},
            { api: EnvApi.API_MAIN_URL },
          ),
          values,
        ));
      } else {
        ({ data } = await api.request.post(
          stringifyUrl(
            `nomenclatures/classifier/${code}/entities`,
            {},
            { api: EnvApi.API_MAIN_URL },
          ),
          entity,
        ));
      }
    } catch (e: any) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, entity);
      } else {
        error = message;
      }
    }

    return [error, data];
  },
  removeEntity: async (code: string, id: number) => {
    await api.request.delete(
      stringifyUrl(
        `nomenclatures/classifier/${code}/entities/${id}`,
        {},
        { api: EnvApi.API_MAIN_URL },
      ),
    );
  },
};
