import download from './download.png';
import deposit from './deposit.png';
import contact from './contact.png';
import credit from './credit.png';
import paymentCard from './paymentCard.png';
import map from './map.png';
import slider from './slider.png';
import service from './service.png';
import security from './security.png';
import useful from './useful.png';
import subsidiariesATM from './subsidiariesATM.png';
import news from './news.png';
import moduleMap from './moduleMap.png';
import faq from './faq.png';
import exchange from './exchange.png';
import converter from './converter.png';
import contactUs from './contactUs.png';
import calculator from './calculator.png';
import banners from './banners.png';
import section from './section.png';
import form from './form.png';
import special from './special.png';
import componentSpace from './componentSpace.png';
import componentContainer from './componentContainer.png';
import paymentMethods from './paymentMethods.png';
import DEFAULT from './DEFAULT.png';
import BANNER from './BANNER.png';
import BANNER_INDENT from './BANNER_INDENT.png';
import FULLSCREEN from './FULLSCREEN.png';
import complaintForm from './complaintForm.png';
import sitemap from './sitemap.png';
import information from './information.png';

export default {
  download,
  deposit,
  contact,
  credit,
  paymentCard,
  map,
  slider,
  service,
  security,
  useful,
  subsidiariesATM,
  news,
  moduleMap,
  faq,
  exchange,
  converter,
  contactUs,
  calculator,
  banners,
  section,
  form,
  special,
  componentSpace,
  componentContainer,
  paymentMethods,
  DEFAULT,
  BANNER,
  BANNER_INDENT,
  FULLSCREEN,
  complaintForm,
  sitemap,
  information,
};
