import React, { FC, useState, useCallback, useEffect } from 'react';
import i18n, { Ii18n } from 'lib/api/i18n';
import { Empty, Spin } from 'antd';
import { languages } from 'lib/utils';
import { List } from 'components/Backgrounds';

const keyword = 'backgrounds';

const Backgrounds: FC = () => {
  const [loading, setLoading] = useState(true);
  const [dictionary, setDictionary] = useState<Ii18n>();

  const preSetDictionary = (dictionar?: Ii18n): void => {
    if (dictionar) {
      languages.forEach((language) => {
        dictionar.i18n[language] = JSON.parse(dictionar.i18n[language]);
      });
    }

    setDictionary(dictionar);
  };

  const fetchAll = useCallback(async () => {
    const { results } = await i18n.getAll();

    const dictionar = results.find((item: any) => item.text_id === keyword);

    preSetDictionary(dictionar);

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  if (!loading && !dictionary) {
    return <Empty />;
  }

  if (!dictionary) {
    return <Spin spinning />;
  }

  return <List update={preSetDictionary} dictionary={dictionary} />;
};

export default Backgrounds;
