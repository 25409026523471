import { Properties } from 'types';

export const loop = (data, id, callback) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].id === parseInt(id as string)) {
      return callback(data[i], i, data);
    }
    if (data[i].children) {
      loop(data[i].children, id, callback);
    }
  }
};

export const loopChildren = (
  data: Properties[],
  id: number,
  callback: (item: Properties, index: number, arr: Properties) => Properties,
) =>
  data?.map((item, index, arr) => {
    if (item?.id === id) {
      item = callback(item, index, arr);
    }
    if (item?.children) {
      item.children = loopChildren(item.children, id, callback);
    }

    return item;
  });

export const loopAll = (data, callback) => {
  for (let i = 0; i < data.length; i++) {
    callback(data[i], i, data);

    if (data[i].children) {
      loopAll(data[i].children, callback);
    }
  }
};

export const normalizeTree = (
  data: Properties[],
  callback?: (item: Properties, parentId?: number, i?: number) => Properties,
  parentId?: number,
): Properties[] =>
  data?.map((item, i) => {
    if (item?.children_objects?.length) {
      item.children = normalizeTree(item.children_objects, callback, item.id);
    } else if (item?.children?.length) {
      item.children = normalizeTree(item.children || [], callback, item.id);
    }

    if (item.article && typeof item.article !== 'number') {
      item = {
        ...item.article,
        data: item.data,
        ...(item?.children?.length && { children: item.children }),
      };
    }

    return callback ? callback(item, parentId, i) : item;
  });
