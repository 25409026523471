import React, { FC, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Input, Button, Card, Modal, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from 'lib/api';
import { loginPath } from 'lib/router';
import { SessionContext } from 'context/utils';
import { Properties } from 'types';
import { ValidationError } from 'errors';

export const SignIn: FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();
  const token = query.get('token');
  const { removeSession } = useContext(SessionContext);

  useEffect(() => {
    if (!token) {
      return history.push('/login');
    } else {
      removeSession();
      api.removeSessionTokens();
    }
  }, []);

  const handleSubmit = async (values: Properties): Promise<void> => {
    const { username, password, confirm_password, first_name, last_name } = values;

    const [tokens, error] = await api.auth.register(
      username,
      password,
      confirm_password,
      first_name,
      last_name,
      token,
    );

    if (error) {
      if (error instanceof ValidationError) {
        form.setFields(error.fields);
      } else {
        Modal.error({
          title: error.detail,
        });
      }
    } else if (tokens) {
      api.setSessionTokens(tokens);
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <Card title="Energbank - Panou de administrare">
        <Form
          form={form}
          onFinish={handleSubmit}
          autoComplete="off"
          style={{ width: 500, height: 'fit-content' }}
          layout="vertical"
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="username"
                rules={[
                  { required: true, message: 'E-mailul este obligatoriu!' },
                  {
                    type: 'email',
                    message: 'Vă rugăm să introduceți un email valid',
                  },
                ]}
                extra="Introduceți adresa de e-mail la care ați primit invitația."
              >
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label="Prenume"
                rules={[{ required: true, message: 'Prenumele este obligatoriu!' }]}
              >
                <Input placeholder="Prenume" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label="Nume"
                rules={[{ required: true, message: 'Numele este obligatoriu!' }]}
              >
                <Input placeholder="Nume" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Parola"
                name="password"
                rules={[{ required: true, message: 'Introduceți parola' }]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Parola"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Confirmă parola"
                name="confirm_password"
                rules={[{ required: true, message: 'Introduceți confirmarea parolei' }]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Confirmă parola"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col>
              <Button onClick={() => history.push(loginPath)}>Anulare</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Inregistrare
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export default SignIn;
