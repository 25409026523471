import api from '.';
import { stringifyUrl } from 'utils';
import { Languages } from 'lib/utils';
import { NonFieldsError, FieldsValidationError } from './utils';

export interface Banner {
  id: number;
  link: string;
  image: string;
  i18n: { [key in Languages]: BannerProps | string };
  type: BannerType;
  order: number | null;
}

export interface BannerProps {
  title?: string;
  content?: string;
}

export enum BannerType {
  INDIVIDUALS = '1',
  BUSINESS = '2',
  DISCLOSURE_INFORMATION = '3',
}

export default {
  get: async (params: any = {}) => {
    const { data } = await api.request.get(stringifyUrl(`banner/`, params, {}));

    return data;
  },
  upsert: async (banner: Banner) => {
    let error, data;

    try {
      const { id, ...values } = banner;

      if (id) {
        ({ data } = await api.request.patch(stringifyUrl(`banner/${id}/`, {}, {}), values));
      } else {
        ({ data } = await api.request.post(stringifyUrl(`banner/`, {}, {}), banner));
      }
    } catch (e: any) {
      const { message, response = {} } = e;

      if (response.status === 400) {
        const { data } = response;
        error = Array.isArray(data.non_field_errors)
          ? new NonFieldsError(data.non_field_errors[0])
          : new FieldsValidationError(data, banner);
      } else {
        error = message;
      }
    }

    return [error, data];
  },
  remove: async (id: number) => {
    await api.request.delete(stringifyUrl(`banner/${id}/`, {}, {}));
  },
};
