import React, { FC, useState, useEffect, useCallback } from 'react';
import api, { Banner, BannerType } from 'lib/api/banners';
import { BannerForm } from 'components/Banners';
import { List, Card, Modal, Button, Row, Col } from 'antd';
import { onErrorImage } from 'utils';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';

const defaultState = { item: null, visible: false };
const Banners: FC = () => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [selected, selectBanner] = useState<{
    item: Banner | null;
    visible: boolean;
  }>(defaultState);

  const [type, setType] = useState<BannerType>(BannerType.INDIVIDUALS);

  const fetchData = async (): Promise<void> => {
    const data = await api.get({ type });

    const orderedBanners = data.results.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0,
    );

    setBanners(orderedBanners);
  };

  useEffect(() => {
    fetchData();
  }, [type]);

  const toggleModal = useCallback(() => {
    selectBanner(defaultState);
  }, []);

  const onSave = useCallback(
    (banner: Banner) => {
      const _banners = [...banners];
      const bannerIndex = banners.findIndex((b) => b.id === banner.id);

      if (Object.is(bannerIndex, -1)) {
        _banners.unshift(banner);
      } else {
        _banners[bannerIndex] = banner;
      }

      setBanners(_banners);
      toggleModal();
      fetchData();
    },
    [banners, toggleModal],
  );

  const onRemove = useCallback(
    (banner: Banner | null) => {
      if (!banner) {
        return;
      }

      try {
        Modal.confirm({
          title: 'Doriți să ștergeți definitiv acest banner?',
          okText: 'Șterge',
          cancelText: 'Anulează',
          onOk: async () => {
            await api.remove(banner.id);
            setBanners(banners.slice().filter((b) => b.id !== banner.id));
            toggleModal();
          },
        });
      } catch (e) {
        Modal.error({ title: e.message });
      }
    },
    [banners, toggleModal],
  );

  function changeOrder(item, dir) {
    const order = [...banners];

    if (dir === 'right') {
      if (banners.indexOf(item) === banners.length - 1) {
        return;
      }
      [order[banners.indexOf(item)], order[banners.indexOf(item) + 1]] = [
        order[banners.indexOf(item) + 1],
        order[banners.indexOf(item)],
        ...order,
      ];
    }

    if (dir === 'left') {
      if (banners.indexOf(item) === 0) {
        return;
      }
      [order[banners.indexOf(item)], order[banners.indexOf(item) - 1]] = [
        order[banners.indexOf(item) - 1],
        order[banners.indexOf(item)],
        ...order,
      ];
    }
    order.map((item, index) => {
      api.upsert({ ...item, order: index });
      return item;
    });
    setBanners(order);
  }

  const toggleType = (el) => {
    setType(el);
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Row gutter={[16, 16]}>
            <Col>
              <Button
                size="large"
                type={type === BannerType.INDIVIDUALS ? 'primary' : undefined}
                onClick={() => setType(BannerType.INDIVIDUALS)}
              >
                Persoane fizice
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                type={type === BannerType.BUSINESS ? 'primary' : undefined}
                onClick={() => setType(BannerType.BUSINESS)}
              >
                Persoane juridice
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                type={type === BannerType.DISCLOSURE_INFORMATION ? 'primary' : undefined}
                onClick={() => setType(BannerType.DISCLOSURE_INFORMATION)}
              >
                Dezvăluirea informaţiei
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={() => selectBanner({ item: null, visible: true })}
          >
            Adaugă banner
          </Button>
        </Col>
      </Row>

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          xxl: 4,
        }}
        dataSource={banners}
        renderItem={(item, index) => {
          let i18n;
          try {
            i18n = JSON.parse(item.i18n.ro as string);
          } catch (e) {
            i18n = { title: item.i18n.ro, content: item.i18n.ro };
          }

          return (
            <List.Item key={item.id}>
              <Card
                onClick={() => selectBanner({ item, visible: true })}
                hoverable
                title={
                  <Row justify="space-between" align="middle">
                    {i18n.title}
                    <Row justify="space-between" className="arrows">
                      <Button disabled={index === 0} size="large" className="button-dir">
                        <LeftCircleFilled
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            changeOrder(item, 'left');
                          }}
                        />
                      </Button>
                      <Button
                        disabled={index === banners.length - 1}
                        size="large"
                        className="button-dir"
                      >
                        <RightCircleFilled
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            changeOrder(item, 'right');
                          }}
                        />
                      </Button>
                    </Row>
                  </Row>
                }
                cover={
                  <div
                    style={{
                      overflow: 'hidden',
                      height: '200px',
                      width: '100%',
                    }}
                  >
                    <img
                      alt={i18n.title}
                      src={item.image}
                      style={{ height: '100%', width: '100%' }}
                      onError={onErrorImage}
                    />
                  </div>
                }
              >
                {i18n.content ? i18n.content : 'Card content'}
              </Card>
            </List.Item>
          );
        }}
      />

      <Modal
        title={`${selected.item ? 'Editează' : 'Adaugă'} banner`}
        onCancel={toggleModal}
        visible={selected.visible}
        width={800}
        destroyOnClose
        footer={
          <>
            {selected.item && (
              <Button className="float-left" onClick={() => onRemove(selected.item)}>
                Șterge
              </Button>
            )}

            <Button onClick={toggleModal}>Anulează</Button>

            <Button type="primary" htmlType="submit" form="BannerForm">
              Salvează
            </Button>
          </>
        }
      >
        <BannerForm data={selected.item} toggleType={toggleType} onSuccess={onSave} />
      </Modal>
    </>
  );
};

export default Banners;
