import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { ColumnWidthOutlined } from '@ant-design/icons';

const options = {
  12: '100',
  8: '75',
  6: '50',
  4: '33',
  3: '25',
};

interface RowSelectProps {
  value?: number;
  onChange?: (value: number) => void;
}

const RowSelect: React.FC<RowSelectProps> = (props) => {
  const [state, setState] = useState(props?.value || 12);

  useEffect(() => {
    if (props?.value && props.value !== state) {
      setState(props.value);
    }
  }, [props]);

  const onChange = (value) => {
    setState(value);

    if (props?.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Dropdown
      overlayClassName="col-select"
      overlay={
        <Menu>
          {Object.keys(options).map((item) => (
            <Menu.Item
              key={item}
              className={state === parseInt(item) ? 'selected' : undefined}
              onClick={() => onChange(item)}
            >
              {options[item]} %
            </Menu.Item>
          ))}
        </Menu>
      }
      placement="bottomLeft"
    >
      <Button size="small">
        <ColumnWidthOutlined /> {options[state]} %
      </Button>
    </Dropdown>
  );
};

export default RowSelect;
