import React, { FC, forwardRef, useEffect, useCallback, useState } from 'react';
import { Upload, Modal, message, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import env from '@beam-australia/react-env';
import { RcFile } from 'antd/lib/upload/interface';
import api from 'lib/api';
import { GalleryItem } from 'lib/api/pages';

import DescriptionForm from './DescriptionForm';
import SortableItems from './SortableItems';
import GalleryContext, { ActiveType, GalleryFile } from './context';

const API_BASE_URL = env('API_BASE_URL');
const maxImageSizeInMb = 4;

interface GalleryWallProps {
  value?: GalleryItem[];
  onChange?: (content: GalleryItem[]) => void;
}

const GalleryWall: FC<GalleryWallProps> = forwardRef(({ value = [], onChange }, ref: any) => {
  const [active, setActive] = useState<ActiveType>({ index: -1 });
  const [fileList, setFileList] = useState<GalleryFile[]>(() => {
    const initialList: GalleryFile[] = [];

    value.forEach(({ url, caption }, index) =>
      initialList.push({
        url,
        caption,
        uid: `${-index}`,
        size: 1,
        name: url,
        status: 'done',
        type: 'image',
      }),
    );

    return initialList;
  });

  useEffect(() => {
    const images: GalleryItem[] = [];

    fileList.forEach(({ url, caption }) => {
      if (url) {
        images.push({ url, caption });
      }
    });

    if (onChange) {
      onChange(images);
    }
  }, [fileList]);

  const handleCancel = (): void => {
    setActive({ index: -1 });
  };

  const handleRemove = (fileUrl: string): void => {
    const files = [...fileList].filter(({ url }) => url !== fileUrl);

    setFileList(files);
  };

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isMbLt = file.size / 1024 / 1024 <= maxImageSizeInMb;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    let errorMsg = null;

    if (!isJpgOrPng) {
      errorMsg = 'Puteți încărca doar imagini în formatul JPG/PNG!';
    }

    if (!isMbLt) {
      errorMsg = `Imaginea nu trebuie să fie mai mare decât ${maxImageSizeInMb} MB!`;
    }

    if (errorMsg !== null) {
      message.error(errorMsg);
      return false;
    }

    return handleUpload(file).then((file) => setFileList((list) => [...list, file]));
  }, []);

  return (
    <div ref={ref} style={{ marginTop: 10 }}>
      <GalleryContext.Provider
        value={{
          fileList,
          setFileList,
          handleRemove,
          handleCancel,
          setActive,
          active,
        }}
      >
        <SortableItems />

        <Modal visible={active.type === 'preview'} onCancel={handleCancel} footer={null}>
          <img alt="preview" style={{ width: '100%' }} src={fileList[active.index]?.url} />
        </Modal>

        <Modal
          visible={active.type === 'description'}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose
        >
          <DescriptionForm />
        </Modal>
      </GalleryContext.Provider>

      <Upload multiple beforeUpload={handleBeforeUpload}>
        <Button icon={<PlusOutlined />} type="dashed">
          Adaugă poze
        </Button>
      </Upload>
    </div>
  );
});

export default GalleryWall;

const handleUpload = async (file: RcFile): Promise<GalleryFile> => {
  const data = await api.pages.uploadFile(file);
  const { id, upload, uploaded } = data;

  const newFile: GalleryFile = {
    uid: `${id}`,
    size: 1,
    name: upload,
    status: uploaded ? 'done' : 'error',
    url: `${API_BASE_URL}/v1${upload}`,
    type: 'image',
  };

  return newFile;
};
