import React, { FC, useEffect, useState, useCallback } from 'react';
import pages, { Article as IArticle, StateT } from 'lib/api/pages';
import { ArrayResult } from 'types';
import Search from 'antd/lib/input/Search';
import { ArticleActions, ReOrder } from 'components/Articles';
import { Table, Button, Modal, Row, Col, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { defaultLocale } from 'lib/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { Tag } from 'lib/api/tags';

import { filters } from '.';

const ArticlePage: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState<ArrayResult<IArticle>>();

  const paramsEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramsEntries);

  const page = Number(params.page) || 1;
  const search = params.search || undefined;
  const tags = params.tags;

  const fetchArticles = useCallback(async () => {
    try {
      setData(
        await pages.all({ name_content_icontains: search, page, tags }).then((response) => ({
          ...response,
          results: response.results.map((result) => {
            result.children = result.children_objects?.length
              ? result.children_objects.sort((prev, curr) =>
                  prev.order > curr.order ? 1 : curr.order > prev.order ? -1 : 0,
                )
              : null;
            delete result.children_objects;

            return result;
          }),
        })),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [search, page, tags]);

  const onRemove = async ({ id }: IArticle): Promise<void> => {
    Modal.confirm({
      title: 'Doriți să ștergeți acest articol?',
      onOk: async () => {
        try {
          await pages.remove(id);
          fetchArticles();
        } catch (e) {
          notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
        }
      },
    });
  };

  const onSearchHandler = (): void => {
    history.push({
      pathname: '/pages',
      search: '?' + new URLSearchParams({ search: searchValue, tags }).toString(),
    });
  };

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const columns: ColumnProps<IArticle>[] = [
    {
      title: '',
      width: 90,
      render: ({ id, children }) =>
        children?.length ? <ReOrder id={id} onSaved={fetchArticles} /> : null,
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Titlu',
      render: ({ i18n }) => {
        const { title = '-' } = i18n[defaultLocale];

        return title;
      },
    },
    {
      width: 175,
      title: 'Data publicarii',
      render: ({ publish_date }) => `${moment(publish_date).format('DD/MM/YYYY, HH:mm')}`,
    },
    {
      title: 'Imagini',
      render: ({ images }) => images?.length || '-',
    },
    {
      title: 'Status',
      dataIndex: 'state',
      render: (record) => StateT[record as keyof typeof StateT],
    },
    {
      width: '10%',
      title: 'Tag',
      dataIndex: 'tags',
      render: (tags) =>
        Array.isArray(tags) && tags.map((tag: Tag) => tag.i18n[defaultLocale]).join(', '),
      filters,
      defaultFilteredValue: tags ? tags.split(',') : [],
    },
    {
      title: 'Acțiuni',
      width: 100,
      align: 'right',
      render: (article) => {
        return <ArticleActions article={article} onRemove={onRemove} />;
      },
    },
  ];

  return (
    <>
      <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Search
            enterButton="Caută"
            placeholder="Caută articol după titlu"
            size="large"
            value={searchValue}
            onSearch={onSearchHandler}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Col>
        <Col>
          <Button type="primary" size="large" onClick={() => history.push('/pages/create')}>
            Creează articol
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data ? data.results : []}
        rowKey="id"
        childrenColumnName=""
        pagination={{
          hideOnSinglePage: true,
          current: page,
          pageSize: 10,
          total: data ? data.count : 0,
        }}
        onChange={({ current }, filters: any) => {
          history.push(
            `?${new URLSearchParams({
              ...params,
              ...filters,
              page: String(current),
            }).toString()}`,
          );
        }}
      />
    </>
  );
};

export default ArticlePage;
