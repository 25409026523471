import React from 'react';
import { useSetState } from 'react-use';
import { Form, Checkbox, Button, Tooltip, Select, Row, Col, notification } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import classifiers, { NomenclatureClassifierEntity } from 'lib/api/classifiers';

import { RowSelect } from '../Custom';
import { formItemStyle } from '../helpers';

export default ({ initialValue, item, setFieldsValue }) => {
  const [options, setOptions] = React.useState<NomenclatureClassifierEntity[]>([]);
  const [dependency, setDependency] = useSetState({ name: '', code: '', option: '' });

  const fetchClassifiers = React.useCallback(async () => {
    try {
      setOptions(
        await classifiers
          .getEntity(dependency.code)
          .then(({ results }) => results.map(({ code, title }) => ({ value: code, label: title }))),
      );
    } catch (e) {
      notification.error({ message: 'Ceva nu a mers bine.', description: e?.message });
    }
  }, [dependency.code]);

  React.useEffect(() => {
    if (dependency.code) {
      fetchClassifiers();
    }
  }, [dependency.code]);

  React.useEffect(() => {
    if (item?.dependencies?.field) {
      setDependency(item.dependencies.field);
    }
  }, [item]);

  React.useEffect(() => {
    if (dependency.name || dependency.option) {
      setFieldsValue([
        {
          name: ['dependencies', 'field'],
          value: dependency,
        },
      ]);
    }
  }, [dependency]);

  return (
    <>
      <Form.Item name={['options', 'id']} className="hide" style={formItemStyle}>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={['options', 'type']} className="hide" style={formItemStyle}>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={['options', 'order']} className="hide" style={formItemStyle}>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={['options', 'col']} initialValue={12} className="drop-tree__node-edit__col">
        <RowSelect />
      </Form.Item>
      <Form.Item
        name={['options', 'required']}
        valuePropName="checked"
        label="Required"
        className="drop-tree__node-edit__required"
        labelCol={{ xs: 12 }}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              options: {
                required: !item.options.required,
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={['dependencies', 'field']}
        valuePropName="checked"
        className="drop-tree__node-edit__dependency"
        labelCol={{ xs: 12 }}
      >
        <Tooltip
          overlay={
            <Row gutter={[8, 8]}>
              <Col span={24}>Depinde de:</Col>
              <Col span={24}>
                <Select
                  options={initialValue}
                  value={dependency.name}
                  onChange={(name) =>
                    setDependency({
                      name,
                      code: initialValue.find((val) => val.value === name)?.code,
                    })
                  }
                  style={{ minWidth: 220 }}
                />
              </Col>
              <Col span={24}>Arată dacă:</Col>
              <Col span={24}>
                <Select
                  options={options}
                  value={dependency.option}
                  onChange={(option) => setDependency({ option })}
                />
              </Col>
            </Row>
          }
          overlayClassName="p-0"
          placement="left"
        >
          <Button
            icon={<LinkOutlined />}
            danger={!!dependency.name && !dependency.option}
            type={dependency.name && dependency.option ? 'link' : 'text'}
          />
        </Tooltip>
      </Form.Item>
    </>
  );
};
